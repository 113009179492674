import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "hooks";
import { SolutionRelatedTable } from "../tables";
import { Routes } from "routes";
import {
  BusinessUnitService,
  CardService,
  CardStatusService,
  GroupService,
  QueueService,
  SolutionService,
  TechnologyService,
} from "services";
import { checkEmptyField, handleError } from "utils";

const businessUnitService = new BusinessUnitService();
const cardService = new CardService();
const cardStatusService = new CardStatusService();
const groupService = new GroupService();
const queueService = new QueueService();
const solutionService = new SolutionService();
const technologyService = new TechnologyService();

const fetchTechnologies = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await technologyService.getAllByWorkspace(workspaceId);
  }
};

const fetchBusinessUnits = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await businessUnitService.getAllByWorkspace(workspaceId);
  }
};

const fetchGroups = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await groupService.getAllByWorkspace(workspaceId);
  }
};

const fetchQueues = async ({ queryKey }) => {
  const [_, solutionId] = queryKey;
  if (solutionId) {
    return await queueService.getAllBySolutionId(solutionId);
  }
};
const cardStatusOptions = ["Development", "Testing", "Hypercare", "Production"];
const complexityOptions = ["Small", "Medium", "High", "Very High"];
const defaultCardStatus = cardStatusOptions[0];
const defaultComplexity = complexityOptions[1];
const defaultKeyBenefit = "Time Saved";

export const SolutionTab = (props) => {
  const { type, solutionId, workspaceId, onSolutionCreated, setTab, setQueue } =
    props;
  const history = useHistory();
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );
  // notifiers
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields to edit
  const [solutionName, setSolutionName] = useState("");
  const [solutionDescription, setSolutionDescription] = useState("");
  const [group, setGroup] = useState("");
  const [effort, setEffort] = useState("");
  const [complexity, setComplexity] = useState(defaultComplexity);
  const [keyBenefit, setKeyBenefit] = useState(defaultKeyBenefit);
  const [estBuildCost, setEstBuildCost] = useState("");
  const [estCost, setEstCost] = useState("");
  const [cardStatus, setCardStatus] = useState(defaultCardStatus);
  const [dateProductionlised, setDateProductionlised] = useState(null);
  const [plannedDateProductionlised, setPlannedDateProductionlised] =
    useState(null);
  const [decommissionDate, setDecommissionDate] = useState(null);
  const [primaryTechnology, setPrimaryTechnology] = useState("");
  const [estBenefit, setEstBenefit] = useState("");
  const [actBenefit, setActBenefit] = useState("");
  //booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
  const [updateSelectItem, setUpdateSelectItem] = useState(false);
  const [solutionJustCreated, setSolutionJustCreated] = useState(false);
  const [updateCardStatusItem, setUpdateCardStatusItem] = useState(false);
  const queueChanges = useTypedSelector((state) => state.queue.queueChanges);
  // ids
  const [businessUnit, setBusinessUnit] = useState("");
  const [cardId, setCardId] = useState("");
  const [cardStatusId, setCardStatusId] = useState("");

  const statusColor = isSaveTypeSuccessful ? "green" : "red";

  const technologiesQuery = useQuery(
    ["solutionTechnologies", workspaceId],
    fetchTechnologies,
    { onError: (e) => handleError(e, history) }
  );

  const businessUnitsQuery = useQuery(
    ["solutionBusinessUnits", workspaceId],
    fetchBusinessUnits,
    { onError: (e) => handleError(e, history) }
  );

  const groupsQuery = useQuery(["solutionGroups", workspaceId], fetchGroups, {
    onError: (e) => handleError(e, history),
  });

  const queuesQuery = useQuery(["solutionQueues", solutionId], fetchQueues, {
    onError: (e) => handleError(e, history),
  });

  const isSavedSuccessfully = () => {
    setStatus("Changes saved successfully.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
    setIsStatusTypeSuccessful(false);
  };

  const failedDuringDeleting = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when deleting");
    setIsStatusTypeSuccessful(false);
  };

  const deleteSolution = async () => {
    try {
      return await solutionService.delete(solutionId);
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (solutionId) {
      const solutionResponse = await deleteSolution();
      if (solutionResponse) {
        setStatus("Deletion successful.");
        setIsStatusTypeSuccessful(true);
        history.push(
          `/${activeWorkspaceName}${Routes.DashboardSolutions.path}`
        );
      }
    }
  };

  const checkFields = () => {
    const estCostRes = checkEmptyField(estCost, "Est. Cost (p.a.)", setError);
    const estBuildCosRes = checkEmptyField(
      estBuildCost,
      "Est. Build Cost",
      setError
    );
    const keyBenefitRes = checkEmptyField(
      keyBenefit,
      "Key Benefit",
      setError,
      "select"
    );
    const effortRes = checkEmptyField(effort, "Build Effort (Days)", setError);
    const solutionDescriptionRes = checkEmptyField(
      solutionDescription,
      "Solution Description",
      setError
    );
    const solutionNameRes = checkEmptyField(
      solutionName,
      "Solution Name",
      setError
    );
    const responses = [
      estBuildCosRes,
      effortRes,
      estCostRes,
      keyBenefitRes,
      solutionDescriptionRes,
      solutionNameRes,
    ];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const save = async () => {
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      const idOfCard = await createCard();
      if (idOfCard) {
        const cardStatusObject = await createCardStatus(idOfCard);
        if (cardStatusObject) {
          const idOfSolution = await createSolution(idOfCard);
          if (idOfSolution) {
            onSolutionCreated(idOfSolution);
            isSavedSuccessfully();
            setSolutionJustCreated(true);
            return;
          }
        }
      }
    }
  };

  const update = async () => {
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      await updateCard();
      if (updateCardStatusItem) {
        await updateCardStatus();
        const cardStatusObject = await createCardStatus(cardId);
        if (cardStatusObject) {
          setCardStatusId(cardStatusObject.card_status_id);
          setCardStatus(cardStatusObject.status_name);
          setUpdateCardStatusItem(false);
        }
      }
      const solutionResponse = await updateSolution();
      setUpdateSelectItem(false);
      if (solutionResponse) {
        isSavedSuccessfully();
        return;
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (type === "new") {
      await save();
    }
    if (type === "config" || solutionJustCreated) {
      await update();
    }
  };

  const createSolution = async (cardId) => {
    try {
      const data = {
        solutionName: solutionName,
        solutionDescription: solutionDescription,
        commissionDate: dateProductionlised,
        plannedCommissionDate: plannedDateProductionlised,
        decommissionDate: decommissionDate,
        cardId: cardId,
        techId: primaryTechnology,
        workspaceId: workspaceId,
      };
      return await solutionService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateSolution = async () => {
    try {
      const data = {
        solution_name: solutionName,
        solution_description: solutionDescription,
        commission_date: dateProductionlised,
        planned_commission_date: plannedDateProductionlised,
        decommission_date: decommissionDate,
        card_id: cardId,
        tech_id: primaryTechnology,
      };
      return await solutionService.update(solutionId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createCardStatus = async (cardId) => {
    try {
      const data = {
        statusName: cardStatus,
        cardId: cardId,
        workspaceId: workspaceId,
      };
      return await cardStatusService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createCard = async () => {
    try {
      const data = {
        keyBenefit: keyBenefit,
        complexity: complexity,
        effort: effort,
        estCost: estBuildCost,
        estAnnualCost: estCost,
        businessUnitId: businessUnit,
        groupId: group,
        workspaceId: workspaceId,
      };
      return await cardService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateCard = async () => {
    try {
      const data = {
        key_benefit: keyBenefit,
        complexity: complexity,
        effort: parseInt(effort, 10),
        est_cost: parseInt(estBuildCost, 10),
        est_annual_cost: parseInt(estCost, 10),
        business_unit_id: businessUnit,
        group_id: group,
      };
      return await cardService.update(cardId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };
  const updateCardStatus = async () => {
    try {
      const data = {
        time_expired: new Date(),
      };
      return await cardStatusService.update(cardStatusId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };
  const fetchSolutionById = async () => {
    try {
      if (solutionId) {
        const response = await solutionService.get(solutionId);
        if (response) {
          setCardId(response.card_id);
          setSolutionName(response.solution_name);
          setSolutionDescription(response.solution_description);
          setEstBenefit(response.est_annual_benefit);
          setActBenefit(response.actual_total_benefit);
          setPrimaryTechnology(response.tech_id);
          const productionlised = response.commission_date
            ? moment(new Date(response.commission_date)).format("YYYY-MM-DD")
            : "";
          setDateProductionlised(productionlised);
          const plannedProductionlised = response.planned_commission_date
            ? moment(new Date(response.planned_commission_date)).format(
                "YYYY-MM-DD"
              )
            : "";
          setPlannedDateProductionlised(plannedProductionlised);
          const decommission = response.decommission_date
            ? moment(new Date(response.decommission_date)).format("YYYY-MM-DD")
            : "";
          setDecommissionDate(decommission);
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchCardById = async () => {
    try {
      const response = await cardService.get(cardId);
      if (response) {
        setBusinessUnit(response.business_unit_id);
        setKeyBenefit(response.key_benefit);
        setEffort(response.effort);
        setEstBuildCost(response.est_cost);
        setEstCost(response.est_annual_cost);
        setComplexity(response.complexity);
        setGroup(response.group_id);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchCardStatus = async () => {
    try {
      const response =
        await cardStatusService.getLastCreatedItemByCardAndWorkspace(
          cardId,
          workspaceId
        );
      if (response) {
        setCardStatusId(response.card_status_id);
        setCardStatus(response.status_name);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleChangeActiveQueueId = (e) => {
    setQueue(e);
    setTab("queues");
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    setDeleteBtnIsDisabled(true);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (solutionId) {
      if (!updateSelectItem) {
        fetchSolutionById();
      }
      setDeleteBtnIsDisabled(false);
    }
    if (cardId) {
      if (!updateSelectItem) {
        fetchCardById();
        fetchCardStatus();
      }
    }
    if (queueChanges) {
      queuesQuery.refetch();
      fetchSolutionById();
    }
  }, [
    error,
    cardId,
    solutionId,
    cardStatusId,
    solutionJustCreated,
    queueChanges,
  ]);

  return (
    <>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="solutionName">
                      <Form.Label>Solution Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={solutionName || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setSolutionName(e.target.value);
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                    <Form.Group id="businessUnit">
                      <Form.Label>Business Unit</Form.Label>
                      <Form.Control
                        as="select"
                        value={businessUnit || ""}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setBusinessUnit(e.target.value);
                          setUpdateSelectItem(true);
                        }}>
                        <option value=""></option>
                        {businessUnitsQuery.data
                          ? businessUnitsQuery.data.map(
                              (businessUnitItem, i) => {
                                return (
                                  <option
                                    key={`businessUnit-${i}`}
                                    value={businessUnitItem.business_unit_id}>
                                    {businessUnitItem.business_unit_name}
                                  </option>
                                );
                              }
                            )
                          : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={solutionDescription || ""}
                        rows={4}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setSolutionDescription(e.target.value);
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="group">
                      <Form.Label>Group</Form.Label>
                      <Form.Control
                        as="select"
                        value={group || ""}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setGroup(e.target.value);
                          setUpdateSelectItem(true);
                        }}>
                        <option value=""></option>
                        {groupsQuery.data
                          ? groupsQuery.data.map((group, i) => {
                              return (
                                <option
                                  key={`group-${i}`}
                                  value={group.group_id}>
                                  {group.group_name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="effort">
                      <Form.Label>Build Effort (Days)</Form.Label>
                      <Form.Control
                        type="number"
                        value={effort || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          if (!isNaN(Number(e.target.value))) {
                            setEffort(Number(e.target.value));
                          }
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="complexity">
                      <Form.Label>Complexity</Form.Label>
                      <Form.Control
                        as="select"
                        value={complexity || defaultComplexity}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setComplexity(e.target.value);
                          setUpdateSelectItem(true);
                        }}>
                        {complexityOptions.map((item, i) => {
                          return (
                            <option key={`complexity-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="keyBenefit">
                      <Form.Label>Key Benefit</Form.Label>
                      <Form.Control
                        as="select"
                        value={keyBenefit || ""}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setKeyBenefit(e.target.value);
                          setUpdateSelectItem(true);
                        }}>
                        <option value=""></option>
                        <option value="Time Saved">Time Saved</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="estBuildCost">
                      <Form.Label>Est. Build Cost</Form.Label>
                      <Form.Control
                        type="text"
                        value={`$${estBuildCost}`}
                        placeholder="$"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setEstBuildCost(e.target.value.replace("$", ""));
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="estCost">
                      <Form.Label>Est. Cost (p.a.)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="$"
                        value={`$${estCost}`}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setEstCost(e.target.value.replace("$", ""));
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="cardStatus">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={cardStatus || defaultCardStatus}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setCardStatus(e.target.value);
                          setUpdateSelectItem(true);
                          setUpdateCardStatusItem(true);
                        }}>
                        {cardStatusOptions.map((item, i) => {
                          return (
                            <option key={`cardStatus-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="primaryTechnology">
                      <Form.Label>Primary Technology</Form.Label>
                      <Form.Control
                        as="select"
                        value={primaryTechnology || ""}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setPrimaryTechnology(e.target.value);
                          setUpdateSelectItem(true);
                        }}>
                        <option value=""></option>
                        {technologiesQuery.data
                          ? technologiesQuery.data.map((technology, i) => {
                              return (
                                <option
                                  key={`primaryTechnology-${i}`}
                                  value={technology.tech_id}>
                                  {technology.tech_name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="dateProductionlised">
                      <Form.Label>Date Productionlised</Form.Label>
                      <Form.Control
                        type="date"
                        value={dateProductionlised || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setDateProductionlised(e.target.value);
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="plannedDateProductionlised">
                      <Form.Label>Planned Date Productionlised</Form.Label>
                      <Form.Control
                        type="date"
                        value={plannedDateProductionlised || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setPlannedDateProductionlised(e.target.value);
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="decommissionDate">
                      <Form.Label>Decommission Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={decommissionDate || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setDecommissionDate(e.target.value);
                          setUpdateSelectItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {solutionId ? (
                  <SolutionRelatedTable
                    type="queue"
                    queues={queuesQuery.data ?? []}
                    changeActiveId={handleChangeActiveQueueId}
                    createNew={(e) => setTab("queues")}
                  />
                ) : null}
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="estBenefit">
                      <Form.Label>Est. Benefit</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        value={estBenefit ? `$${estBenefit}` : "$0.00"}
                        placeholder="$ XXX,XXX.XX"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="actBenefit">
                      <Form.Label>Act. Benefit</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        value={actBenefit ? `$${actBenefit}` : "$0.00"}
                        placeholder="$ XXX,XXX.XX"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button
                  disabled={saveBtnIsDisabled}
                  variant="primary"
                  type="submit"
                  onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col md={3}>
                <h6 style={{ color: statusColor }}>{saveStatus}</h6>
              </Col>
              <Col md={2}>
                <Button
                  disabled={deleteBtnIsDisabled}
                  variant="primary"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
