import ActionTypes from "./ActionTypes";

export const loginRequestSuccessAction = () => ({
    type: ActionTypes.LOGIN_SUCCESS,
});
  
export const loginRequestFailureAction = () => ({
    type: ActionTypes.LOGIN_FAILURE,
});

export const logoutSuccessAction = () => ({
    type: ActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailureAction = () => ({
    type: ActionTypes.LOGOUT_FAILURE,
});
