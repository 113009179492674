import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class TechnologyService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for getting all technology items from database by workspace id.
   * @param workspaceId
   * @returns list of solutions or rises Error
   */
  async getAllByWorkspace(workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/technologies/all/filter_by?&workspace_id=${workspaceId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for creating technology item in database.
   * @param params
   * @returns technology id or rises Error
   */
  async create(params) {
    try {
      const urlWithParams = "/technologies/new?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data.tech_id;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting technology item from database.
   * @param technologyId
   * @returns technology db item or rises Error
   */
  async get(technologyId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/technologies/${technologyId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for deleting technology item from database.
   * @param technologyId
   * @returns "OK" message or rises Error
   */
  async delete(technologyId) {
    try {
      await this.BASE_SERVICE.delete({
        url: `/technologies/${technologyId}`,
      });
      return "OK";
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for updating technology item in database.
   * @param technologyId
   * @param data
   * @returns "OK" message or rises Error
   */
  async update(technologyId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/technologies/${technologyId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting technology items from database by workspace id and pagination settings.
   * @param workspaceId
   * @param page
   * @param perPage
   * @returns pagination data with technology items or rises Error
   */
  async paginateByWorkspace(workspaceId, page, perPage) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/technologies/filter_with_pagination?&workspace_id=${workspaceId}&page=${page}&per_page=${perPage}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }
}
