import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { CSSTransition } from "react-transition-group";
import {
  ChartBarIcon,
  CogIcon,
  TableIcon,
  XIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Image, Button, Navbar, Col } from "react-bootstrap";
import { CollapsableNavItem } from "./CollapsableNavItem";
import { NavItem } from "./NavItem";
import ReactHero from "assets/img/ca-labs-favicon.png";
import { useAction, useTypedSelector } from "hooks";
import { Routes } from "routes";
import {
  AuthService,
  UserService,
  WorkspaceAssignmentService,
  WorkspaceService,
} from "services";
import {
  cleanStorage,
  handleError,
  removeGapAndConvertToLowerCase,
} from "utils";

const apiAuthService = new AuthService();
const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();
const workspaceService = new WorkspaceService();

const getUser = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await userService.get(userId);
  }
};

export const Sidebar = (props = {}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();
  const [isMobile, setIsMobile] = useState(false);
  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };
  const userId = useTypedSelector((state) => state.user.userId);
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const uploadWorkspaces = useTypedSelector(
    (state) => state.workspace.uploadWorkspaces
  );
  const workspaces = useTypedSelector(
    (state) => state.workspace.userWorkspaces
  );
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );
  const currentWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  const isDashboardsDisabled = useTypedSelector(
    (state) => state.workspace.disableDashboards
  );

  const {
    logoutSuccessAction,
    logoutFailureAction,
    uploadWorkspacesAction,
    loadUserWorkspacesAction,
    updateActiveWorkspaceNameAction,
    updateActiveWorkspaceIdAction,
    resetWorkspaceAssignmentAction,
    updateUserIdAction,
  } = useAction();

  const userQuery = useQuery(["userSidebarData", userId], getUser, {
    onError: (e) => handleError(e, history),
  });
  const userName = userQuery.data
    ? `${userQuery.data.first_name} ${userQuery.data.last_name}`
    : "";
  const profilePhoto = userQuery.data ? userQuery.data.profile_photo_url : "";

  const getActiveWorkspace = async ({ queryKey }) => {
    const [_, activeWorkspaceId] = queryKey;
    if (activeWorkspaceId) {
      const workspace = await workspaceService.get(activeWorkspaceId);
      updateActiveWorkspaceNameAction(
        removeGapAndConvertToLowerCase(workspace.workspace_name)
      );
      return workspace;
    }
  };

  const workspaceQuery = useQuery(
    ["workspaceSidebarData", activeWorkspaceId],
    getActiveWorkspace,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const activeWorkspaceIconPath = workspaceQuery.data
    ? workspaceQuery.data.workspace_img_path
    : ReactHero;

  const getUserWorkspaces = async ({ queryKey }) => {
    const [_, uploadWorkspaces, userId] = queryKey;
    if (uploadWorkspaces && userId) {
      let userWorkspaces = [];
      const workspaceAssignments =
        await workspaceAssignmentService.getAllByUser(userId);
      if (workspaceAssignments.length > 0) {
        for (const item of workspaceAssignments) {
          const workspaceItem = await workspaceService.get(item.workspace_id);
          userWorkspaces.push(workspaceItem);
        }
        loadUserWorkspacesAction(userWorkspaces);
        uploadWorkspacesAction(false);
      }
      return userWorkspaces;
    }
  };

  useQuery(
    ["userWorkspacesSidebarData", uploadWorkspaces, userId],
    getUserWorkspaces,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const dashboardNavItems = [
    {
      title: "Overview",
      link: `/${activeWorkspaceName}`,
    },
    {
      title: "Detailed",
      link: `/${activeWorkspaceName}${Routes.DashboardDetailed.path}`,
    },
    {
      title: "Backlog",
      link: `/${activeWorkspaceName}${Routes.DashboardBacklog.path}`,
    },
  ];

  const tablesNavItems = [
    {
      title: "Solutions",
      link: `/${activeWorkspaceName}${Routes.DashboardSolutions.path}`,
    },
    {
      title: "Transactions",
      link: `/${activeWorkspaceName}${Routes.TablesTransactions.path}`,
    },
    {
      title: "Sessions",
      link: `/${activeWorkspaceName}${Routes.Sessions.path}`,
    },
  ];

  const settingsNavItems = [
    {
      title: "Profile",
      link: `/${activeWorkspaceName}${Routes.SettingsProfile.path}`,
    },
    {
      title: "Users",
      link: `/${activeWorkspaceName}${Routes.Users.path}`,
    },
    {
      title: "Workspace",
      link: `/${activeWorkspaceName}/settings/workspace/config/${activeWorkspaceId}`,
    },
  ];

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await resetWorkspaceAssignmentAction(
        userId,
        "",
        currentWorkspaceAssignmentId,
        "logout"
      );
      const response = await apiAuthService.logout();
      if (response === "OK") {
        updateUserIdAction("");
        updateActiveWorkspaceNameAction("");
        updateActiveWorkspaceIdAction("");
        uploadWorkspacesAction(true);
        logoutSuccessAction();
        cleanStorage();
        history.push(Routes.SignIn.path);
      }
    } catch (e) {
      logoutFailureAction();
      handleError(e, history);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 767) {
      setIsMobile(false);
    }
    if (window.innerWidth < 767) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Navbar
        as={Col}
        xs={12}
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-secondary px-4 d-lg-none">
        <Navbar.Brand
          as={Link}
          to={`/${activeWorkspaceName}`}
          className="me-lg-5">
          <Image src={activeWorkspaceIconPath} className="navbar-brand-dark" />
          <span className="mobile-nav-text">
            {activeWorkspaceName.replace("_", " ").toUpperCase()}
          </span>
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          {...events}
          className={
            isMobile
              ? `${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`
              : `${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`
          }>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-lg-none justify-content-between pb-4">
              <div className="d-flex align-items-center">
                {profilePhoto ? (
                  <div className="avatar-lg me-4">
                    <Image
                      src={profilePhoto}
                      className="card-img-top rounded-circle border-white"
                    />
                  </div>
                ) : null}

                <div className="d-block">
                  <h5 className="mb-3">Hi, {userName}</h5>
                  <Button
                    variant="primary"
                    size="sm"
                    className="d-inline-flex align-items-center"
                    onClick={handleLogout}>
                    <LogoutIcon className="icon icon-xxs me-1" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}>
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>

            <Nav className="flex-column pt-3 pt-md-0">
              <CollapsableNavItem
                eventKey="workspace/"
                title={activeWorkspaceName.replace("_", " ").toUpperCase()}
                image={activeWorkspaceIconPath}
                menuIcon>
                {workspaces.map((workspace, i) => {
                  return (
                    <NavItem
                      key={`workspace-${i}`}
                      title={workspace.workspace_name}
                      image={workspace.workspace_img_path}
                      activeWorkspaceId={workspace.workspace_id}
                      disableDashboards={workspace.disable_dashboards}
                      show={show}
                      setShow={(e) => setShow(e)}
                    />
                  );
                })}

                <span>
                  <PlusIcon className="icon icon-sm ms-3" />
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={() => {
                      history.push(
                        `/${activeWorkspaceName}${Routes.CreateWorkspace.path}`
                      );
                    }}>
                    New Workspace
                  </Button>
                </span>
              </CollapsableNavItem>

              {!isDashboardsDisabled ? (
                <CollapsableNavItem
                  eventKey="/"
                  title="Dashboard"
                  icon={ChartBarIcon}>
                  {dashboardNavItems.map((item, i) => {
                    return (
                      <NavItem
                        key={`dashboard-item-${i}`}
                        title={item.title}
                        link={item.link}
                        show={show}
                        setShow={(e) => setShow(e)}
                      />
                    );
                  })}
                </CollapsableNavItem>
              ) : null}

              <CollapsableNavItem
                eventKey="tables/"
                title="Tables"
                icon={TableIcon}>
                {tablesNavItems.map((item, i) => {
                  return (
                    <NavItem
                      key={`table-item-${i}`}
                      title={item.title}
                      link={item.link}
                      show={show}
                      setShow={(e) => setShow(e)}
                    />
                  );
                })}
              </CollapsableNavItem>

              <CollapsableNavItem
                eventKey="settings/"
                title="Settings"
                icon={CogIcon}>
                {settingsNavItems.map((item, i) => {
                  return (
                    <NavItem
                      key={`settings-${i}`}
                      title={item.title}
                      link={item.link}
                      show={show}
                      setShow={(e) => setShow(e)}
                    />
                  );
                })}
              </CollapsableNavItem>
            </Nav>
            <NavItem
              title="Cognitive Automation Labs"
              link={`/${activeWorkspaceName}`}
              image={ReactHero}
              isLogo={true}
              show={show}
              setShow={(e) => setShow(e)}
            />
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
