import { useParams } from "react-router-dom";
import {
  EditWorkspace,
  WorkspaceHeader,
} from "pages/settings/workspace/components";

export const ConfigWorkspace = () => {
  const { id } = useParams();

  return (
    <>
      <WorkspaceHeader type="config" workspaceId={id}/>
      <EditWorkspace type="config" workspaceId={id} />
    </>
  );
};
