import React from "react";
import * as XLSX from "xlsx";
import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

export const BacklogDonut = (props) => {
  const { actualDonutData, chartTitle } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);
  var donutData = [
    {
      values: actualDonutData.values,
      labels: actualDonutData.labels,
      marker: {
        colors: [
          "rgb( 0 89 255)",
          "rgb(230, 230, 230)",
          "rgb(160, 209, 255)",
          "rgb(140, 133, 123)",
          "rgb(82, 138, 191)",
          "rgb(215, 11, 11)",
          "rgb(11, 133, 215)",
          "rgb(0, 0, 0)",
          "rgb(0, 0, 0)",
          "rgb(0, 0, 0)",
        ],
        symbol: "circle",
      },
      domain: { column: 0 },
      name: "",
      hoverinfo: "label+percent+name",
      hole: 0.6,
      type: "pie",
    },
  ];
  var layout = {
    // title: "Global Emissions 1990-2011",
    annotations: [
      {
        showarrow: false,
        text: "",
        x: 0.17,
        y: 0.5,
      },
    ],
    font: {
      size: 12,
      family: '"Inter", sans-serif',
    },
    showlegend: true,
    legend: {
      valign: 'top',
      title: {
        text: "   <b>" + chartTitle + "</br>",
        font: {
          size: 12,
          family: '"Inter", sans-serif',
        },
      },
    },
    margin: { t: 25, b: 25, l: 30, r: 0 },
    grid: { rows: 0, columns: 0 },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    height: 240,
    // width: 285,
  };

  var config = {
    showLink: false,
    displayModeBar: false,
    responsive: true,
  };

  return (
    <>
      <div className="rounded">
        <PlotlyComponent
          style={{ width: "100%" }}
          data={donutData}
          layout={layout}
          config={config}
        />
      </div>
    </>
  );
};
