import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ComponentsTab, QueuesTab, SolutionTab } from "./tabs";
import { useTypedSelector } from "hooks";

export const EditSolution = (props) => {
  const { type, solutionId } = props;
  const workspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const [activeTab, setActiveTab] = useState("solution");
  const [solution, setSolution] = useState(solutionId);
  const [queueId, setQueueId] = useState("");
  const [isTabDisabled, setIsTabDisabled] = useState(false);

  useEffect(() => {
    setIsTabDisabled(true);
    if (solution) {
      setIsTabDisabled(false);
    } 
  }, [solution, queueId]);

  return (
    <>
      <Tabs
        activeKey={activeTab}
        id="fill-tab-example"
        className="mb-3"
        onSelect={(e) => setActiveTab(e)}>
        <Tab eventKey="solution" title="Solution">
          <SolutionTab
            type={type}
            solutionId={solution}
            workspaceId={workspaceId}
            onSolutionCreated={(e) => setSolution(e)}
            setTab={(e) => setActiveTab(e)}
            setQueue={(e) => setQueueId(e)}
          />
        </Tab>
        <Tab eventKey="queues" title="Queues" disabled={isTabDisabled}>
          <QueuesTab
            type={type}
            solutionId={solution}
            workspaceId={workspaceId}
            queueId={queueId}
          />
        </Tab>
        <Tab eventKey="components" title="Components" disabled={isTabDisabled}>
          <ComponentsTab
            type={type}
            solutionId={solution}
            workspaceId={workspaceId}
          />
        </Tab>
      </Tabs>
    </>
  );
};
