import ActionTypes from "./ActionTypes";

export const updateActiveTechnologyIdAction = (payload) => ({
    type: ActionTypes.ACTIVE_TECHNOLOGY_ID,
    payload,
});

export const updateTechnologyTotalEstCostAction = (payload) => ({
    type: ActionTypes.TECHNOLOGY_TOTAL_EST_COST,
    payload,
});

export const resetTechnologyAction = () => ({
    type: ActionTypes.RESET_TECHNOLOGIES,
});
