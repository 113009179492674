import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Button, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { Routes } from "routes";
import { useTypedSelector } from "hooks";
import { SolutionsTableRow } from "./components";
import { Loading, TablePagination } from "pages/components";
import { SolutionService } from "services";
import { handleError } from "utils";

const solutionService = new SolutionService();

const columns = [
  { dateField: "index", text: "#" },
  { dataField: "solutionName", text: "Solution Name" },
  { dataField: "solutionDescription", text: "Solution Description" },
  { dataField: "actBenefit", text: "Act. Benefit" },
  { dataField: "estBenefit", text: "Est. Benefit" },
  { dataField: "configs", text: "..." },
];

const paginateSolutions = async ({ queryKey }) => {
  const [_, activeWorkspaceId, page, perPage] = queryKey;
  if (activeWorkspaceId) {
    return await solutionService.paginateByWorkspace(
      activeWorkspaceId,
      page,
      perPage
    );
  }
};

const perPage = 10;

export const DashboardSolutions = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );

  const handleCreateNew = () => {
    history.push(
      `/${activeWorkspaceName}${Routes.CreateDashboardSolution.path}`
    );
  };

  const query = useQuery(
    ["solutions", activeWorkspaceId, page, perPage],
    paginateSolutions,
    { onError: (e) => handleError(e, history) }
  );
  const { data, isRefetching } = query;
  const total = data?.total ?? 0;

  return (
    <>
      <div className="py-4">
        <div className="d-flex justify-content-between w-100 flex-wrap mb-2">
          <div className="mb-3 mb-lg-0">
            <h4>Solutions</h4>
          </div>
          <Button variant="primary" size="sm" onClick={handleCreateNew}>
            Create New
          </Button>
        </div>
        {isRefetching ? (
          <Loading marginTop="1%" marginLeft="45%" />
        ) : (
          <Card border="0" className="shadow mb-4">
            <Card.Body>
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0">
                <thead className="thead-light">
                  <tr>
                    {columns.map((column, i) => {
                      return (
                        <th key={`dashboard-column-${i}`} className="border-0">
                          {column.text}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.items
                    ? data?.items.map((row, i) => {
                        return (
                          <SolutionsTableRow
                            key={`solution-row-${i}`}
                            {...row}
                          />
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
              <TablePagination
                page={page}
                perPage={perPage}
                total={total}
                itemsLength={data?.items ? data?.items.length : 0}
                setPage={setPage}
              />
            </Card.Footer>
          </Card>
        )}
      </div>
    </>
  );
};
