import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAction, useTypedSelector } from "hooks";
import { checkEmptyField, handleError } from "utils";
import { LicenseService, TechnologyService } from "services";
import { TechnologyLicensesTable } from "../tables";

const licenseTypeOptions = [
  "Per Device Robot",
  "Consumption Robot",
  "Named User Robot",
  "Orchestrator",
  "Non-Prod Orchestrator",
  "Non-Prod Named User",
  "Non-Prod Per Device Robot",
  "Non-Prod Consumption Robot",
  "Non-Prod Named User Robot",
];
const defaultLicenseType = licenseTypeOptions[0];

const licenseService = new LicenseService();
const technologyService = new TechnologyService();

export const EditTechnology = (props) => {
  const { workspaceId, type } = props;
  const history = useHistory();
  const {
    addLicenseAction,
    configLicenseAction,
    updateActiveTechnologyIdAction,
    changeActiveLicenseIdAction,
    setTechnologiesTabAction,
    fetchAllLicensesAction,
    resetLicenseAction,
    resetTechnologyAction,
  } = useAction();
  // general
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields to edit
  const [technologyName, setTechnologyName] = useState("");
  const [technologyDescription, setTechnologyDescription] = useState("");
  const totalEstCost = useTypedSelector(
    (state) => state.technology.technologyTotalEstCost
  );
  const [licenseName, setLicenseName] = useState("");
  const [licenseType, setLicenseType] = useState(defaultLicenseType);
  const [licenseDescription, setLicenseDescription] = useState("");
  const [licenseCost, setLicenseCost] = useState("");
  const [licenseUnitCount, setLicenseUnitCount] = useState("");
  const [licenseTotalCost, setLicenseTotalCost] = useState("0");
  // booleans
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(true);
  const [deleteLicenseBtnIsDisabled, setDeleteLicenseBtnIsDisabled] =
    useState(true);
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const addLicense = useTypedSelector((state) => state.license.addLicense);
  const configLicense = useTypedSelector(
    (state) => state.license.configLicense
  );
  const [addNewLicense, setAddNewLicense] = useState(false);
  const [configExistedLicense, setConfigExistedLicense] = useState(false);
  //ids
  const activeLicenseId = useTypedSelector(
    (state) => state.license.activeLicenseId
  );
  const activeTechnologyId = useTypedSelector(
    (state) => state.technology.activeTechnologyId
  );
  // lists
  const licenses = useTypedSelector((state) => state.license.licenses);
  // style
  const statusColor = isSaveTypeSuccessful ? "green" : "red";

  const isSavedSuccessfully = () => {
    setStatus("Changes saved successfully.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
    setIsStatusTypeSuccessful(false);
  };

  const isDeletedSuccessfuly = () => {
    setStatus("Deletion successful.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringDeleting = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when deleting");
    setIsStatusTypeSuccessful(false);
  };

  const createTechnology = async () => {
    try {
      const data = {
        technologyName: technologyName,
        technologyDescription: technologyDescription,
        workspaceId: workspaceId,
      };
      const techId = await technologyService.create(data);
      if (techId) {
        updateActiveTechnologyIdAction(techId);
        return techId;
      }
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createLicense = async (techId) => {
    try {
      const data = {
        licenseName: licenseName,
        licenseDescription: licenseDescription,
        licenseType: licenseType,
        licenseCost: licenseCost,
        totalEstCost: licenseTotalCost,
        licenseCount: licenseUnitCount,
        techId: techId,
        workspaceId: workspaceId,
      };
      const licenseId = await licenseService.create(data);
      if (licenseId) {
        changeActiveLicenseIdAction(licenseId);
        return licenseId;
      }
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateTechnology = async () => {
    try {
      const data = {
        tech_name: technologyName,
        tech_description: technologyDescription,
      };
      return await technologyService.update(activeTechnologyId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateLicense = async () => {
    try {
      const data = {
        license_name: licenseName,
        license_description: licenseDescription,
        license_type: licenseType,
        license_cost: Number(licenseCost),
        total_est_cost: Number(licenseTotalCost),
        license_count: licenseUnitCount,
      };
      return await licenseService.update(activeLicenseId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const checkTechnologyFields = () => {
    const technologyNameIsField = checkEmptyField(
      technologyName,
      "Technology Name",
      setError
    );
    const technologyDescriptionIsField = checkEmptyField(
      technologyDescription,
      "Technology Description",
      setError
    );
    const responses = [technologyNameIsField, technologyDescriptionIsField];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const save = async () => {
    const allTechnologyFieldsAreFilled = checkTechnologyFields();
    const allLicenseFieldsAreFilled = checkLicenseFields();
    if (allLicenseFieldsAreFilled && allTechnologyFieldsAreFilled) {
      const technologyResponse = await createTechnology();
      if (technologyResponse) {
        const licenseResponse = await createLicense(technologyResponse);
        if (licenseResponse) {
          isSavedSuccessfully();
        }
      }
    }
  };

  const handleLicenseFieldsCheckingResponse = () => {
    const licenseNameIsField = checkEmptyField(
      licenseName,
      "License Name",
      setError
    );
    const licenseDescriptionIsField = checkEmptyField(
      licenseDescription,
      "License Description",
      setError
    );
    const licenseCostIsField = checkEmptyField(
      licenseCost,
      "License Cost",
      setError
    );
    const licenseUnitCountIsField = checkEmptyField(
      licenseUnitCount,
      "License Unit Count",
      setError
    );
    return [
      licenseNameIsField,
      licenseDescriptionIsField,
      licenseCostIsField,
      licenseUnitCountIsField,
    ];
  };

  const checkLicenseFields = () => {
    const checkResponses = handleLicenseFieldsCheckingResponse();
    const allFieldsAreFilled = checkResponses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const checkIfOneLicenseFieldIsField = () => {
    const checkResponses = handleLicenseFieldsCheckingResponse();
    return checkResponses.some((element) => element === "OK");
  };

  const update = async () => {
    if (
      activeLicenseId &&
      !addNewLicense &&
      !configExistedLicense &&
      activeTechnologyId
    ) {
      // only update license
      const allFieldsAreFilled = checkLicenseFields();
      if (allFieldsAreFilled) {
        await updateLicense();
      }
    }
    if (
      !activeLicenseId &&
      !addNewLicense &&
      !configExistedLicense &&
      activeTechnologyId
    ) {
      // only save license
      const oneLicenseFieldIsField = checkIfOneLicenseFieldIsField();
      if (oneLicenseFieldIsField) {
        const allFieldsAreFilled = checkLicenseFields();
        if (allFieldsAreFilled) {
          await createLicense(activeTechnologyId);
        }
      }
    }
    if (addNewLicense) {
      const allFieldsAreFilled = checkLicenseFields();
      if (allFieldsAreFilled) {
        await createLicense(activeTechnologyId);
        if (activeTechnologyId) {
          await updateTechnology();
        }
        setAddNewLicense(false);
      }
    }
    if (!addNewLicense && activeTechnologyId) {
      await updateTechnology();
    }
    if (activeLicenseId && configExistedLicense) {
      await updateLicense();
      setConfigExistedLicense(false);
    }
    isSavedSuccessfully();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (type === "new") {
      await save();
      fetchAllLicensesAction(true);
    }
    if (type === "config") {
      await update();
      fetchAllLicensesAction(true);
    }
  };

  const handleResetLicenseFields = () => {
    setLicenseName("");
    setLicenseType(defaultLicenseType);
    setLicenseDescription("");
    setLicenseCost("");
    setLicenseUnitCount("");
    setLicenseTotalCost("0");
  };

  const handleCancel = () => {
    setTechnologyName("");
    setTechnologyDescription("");
    handleResetLicenseFields();
    resetLicenseAction();
    resetTechnologyAction();
    setTechnologiesTabAction();
  };

  const fetchTechnologyById = async () => {
    try {
      if (activeTechnologyId) {
        const response = await technologyService.get(activeTechnologyId);
        if (response) {
          setTechnologyName(response.tech_name);
          setTechnologyDescription(response.tech_description);
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchLicenseById = async () => {
    try {
      if (activeLicenseId) {
        const response = await licenseService.get(activeLicenseId);
        if (response) {
          setLicenseName(response.license_name);
          setLicenseType(response.license_type);
          setLicenseDescription(response.license_description);
          setLicenseCost(response.license_cost);
          setLicenseUnitCount(response.license_count);
          setLicenseTotalCost(response.total_est_cost);
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleDeleteLicense = async (e) => {
    e.preventDefault();
    try {
      if (activeLicenseId) {
        const deleteLicenseRes = await licenseService.delete(activeLicenseId);
        if (deleteLicenseRes) {
          fetchAllLicensesAction(true);
          handleResetLicenseFields();
          changeActiveLicenseIdAction("");
          isDeletedSuccessfuly();
        }
      }
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      if (activeTechnologyId) {
        const deleteTechnologyResponse = await technologyService.delete(
          activeTechnologyId
        );
        if (deleteTechnologyResponse) {
          updateActiveTechnologyIdAction("");
          changeActiveLicenseIdAction("");
          isDeletedSuccessfuly();
          setTechnologiesTabAction();
        }
      }
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    setDeleteBtnIsDisabled(true);
    setDeleteLicenseBtnIsDisabled(true);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (activeTechnologyId) {
      setDeleteBtnIsDisabled(false);
    }
    if (activeLicenseId) {
      setDeleteLicenseBtnIsDisabled(false);
    }
    fetchTechnologyById();
    if (addLicense) {
      handleResetLicenseFields();
      changeActiveLicenseIdAction("");
      setAddNewLicense(true);
      addLicenseAction(false);
    }
    if (configLicense && activeLicenseId) {
      fetchLicenseById();
      setConfigExistedLicense(true);
      configLicenseAction(false);
    }
  }, [
    error,
    licenses,
    totalEstCost,
    activeLicenseId,
    activeTechnologyId,
    addLicense,
    configLicense,
    addNewLicense,
    configExistedLicense,
  ]);

  return (
    <>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={3} className="mb-3">
                <Form.Group id="technologyName">
                  <Form.Label>Technology Name</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    value={technologyName || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setTechnologyName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="technologyDescription">
                  <Form.Label>Technology Description</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={250}
                    value={technologyDescription || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setTechnologyDescription(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="totalEstCost">
                  <Form.Label>Total Est. Cost (p.a.)</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    value={`$${totalEstCost}` || "$"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <h6>Related Technology Licenses &gt; {licenseName}</h6>
            </Row>
            <Row>
              <Card border="1" style={{ width: "50%" }} className="shadow mb-4">
                <Card.Body>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Row>
                        <Col md={4} className="mb-3">
                          <Form.Group id="licenseName">
                            <Form.Label>License Name</Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={50}
                              value={licenseName || ""}
                              onChange={(e) => {
                                setError("");
                                setStatus("");
                                setLicenseName(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group id="licenseType" className="mt-2">
                            <Form.Label>License Type</Form.Label>
                            <Form.Control
                              as="select"
                              value={licenseType || defaultLicenseType}
                              className="mb-0"
                              onChange={(e) => {
                                setError("");
                                setStatus("");
                                setLicenseType(e.target.value);
                              }}>
                              {licenseTypeOptions.map((type, i) => {
                                return (
                                  <option key={`licenseType-${i}`} value={type}>
                                    {type}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={8} className="mb-3">
                          <Form.Group id="licenseDescription">
                            <Form.Label>License Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              maxLength={255}
                              value={licenseDescription || ""}
                              rows={5}
                              onChange={(e) => {
                                setError("");
                                setStatus("");
                                setLicenseDescription(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="mb-3">
                      <Form.Group id="licenseCost">
                        <Form.Label>
                          License Cost <br /> (per unit)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={`$${licenseCost}` || "$"}
                          onChange={(e) => {
                            setError("");
                            setStatus("");
                            setLicenseCost(e.target.value.replace("$", ""));
                            setLicenseTotalCost(
                              Number(e.target.value.replace("$", "")) *
                                Number(licenseUnitCount)
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Form.Group id="licenseUnitCount">
                        <Form.Label>License Unit Count</Form.Label>
                        <Form.Control
                          type="number"
                          value={licenseUnitCount || ""}
                          className="mt-4"
                          onChange={(e) => {
                            setError("");
                            setStatus("");
                            setLicenseUnitCount(e.target.value);
                            setLicenseTotalCost(
                              Number(e.target.value) * Number(licenseCost)
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Form.Group id="licenseTotalCost">
                        <Form.Label>Total Cost (p.a.)</Form.Label>
                        <Form.Control
                          readOnly
                          type="text"
                          value={`$${licenseTotalCost}` || "$"}
                          className="mt-4"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Button
                        variant="primary"
                        disabled={deleteLicenseBtnIsDisabled}
                        onClick={handleDeleteLicense}>
                        Delete License
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Col md={6} className="mb-3">
                <TechnologyLicensesTable technologyId={activeTechnologyId} />
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Button
                  disabled={saveBtnIsDisabled}
                  variant="primary"
                  type="submit"
                  onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col md={1}>
                <Button variant="primary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
              <Col md={1} style={{ marginLeft: "70%" }}>
                <Button
                  disabled={deleteBtnIsDisabled}
                  variant="primary"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <h6 style={{ color: statusColor }}>{saveStatus}</h6>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
