import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Button, Table } from "react-bootstrap";
import { useAction, useTypedSelector } from "hooks";
import { TechnologyLicenseTableRow } from "./rows";
import { LicenseService } from "services";
import { handleError } from "utils";

const licenseService = new LicenseService();

const columns = [
  { dataField: "licenseName", text: "License Name" },
  { dataField: "licenseCount", text: "License Count" },
  { dataField: "estCost", text: "Est. Cost (p.a.)" },
  { dataField: "configs", text: "..." },
];

export const TechnologyLicensesTable = (props) => {
  const { technologyId } = props;
  const history = useHistory();
  const licenses = useTypedSelector((state) => state.license.licenses);
  const fetchAllLicenses = useTypedSelector(
    (state) => state.license.fetchAllLicenses
  );
  const {
    addLicenseAction,
    fetchAllLicensesAction,
    fetchLicensesAction,
    updateTechnologyTotalEstCostAction,
  } = useAction();

  const handleCountLicensesTotalCost = (licenses) => {
    let total = 0;
    licenses.forEach((item) => {
      total = total + Number(item.total_est_cost);
    });
    updateTechnologyTotalEstCostAction(total);
  };

  const fetchLicenses = async () => {
    try {
      const response = await licenseService.getAllByTechnology(technologyId);
      fetchLicensesAction(response);
      handleCountLicensesTotalCost(response);
      fetchAllLicensesAction(false);
    } catch (e) {
      handleError(e, history);
    }
  };

  useEffect(() => {
    if (technologyId && fetchAllLicenses) {
      fetchLicenses();
    }
  }, [fetchAllLicenses, technologyId, licenses]);

  return (
    <>
      <div className="">
        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mb-3 ms-4 mb-lg-0">
            <h6>Technology Licenses</h6>
          </div>
          <Button
            variant="primary"
            size="sm"
            className="mb-1 me-4 pe-3 ps-3"
            onClick={() => addLicenseAction(true)}>
            Add
          </Button>
        </div>
        <Card
          border="0"
          className="shadow mb-4"
          style={{ maxHeight: 280, overflowX: "hidden", overflowY: "scroll" }}>
          <Card.Body>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  {columns.map((column, i) => {
                    return (
                      <th key={`licence-column-${i}`} className="border-0">
                        {column.text}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {licenses.map((row, i) => {
                  return <TechnologyLicenseTableRow key={`tech-licence-${i}`} license={row} />;
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
