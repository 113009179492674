import ActionTypes from "../actions/ActionTypes";

const initialState = {
  defaultWorkspaceId: "",
  defaultWorkspaceName: "",
  activeWorkspaceId: "",
  activeWorkspaceName: "",
  uploadWorkspaces: true,
  userWorkspaces: [],
  deleteActiveWorkspace: false,
  disableDashboards: true,
};

export const workspaceReducer = (
  state = initialState,
  action 
) => {
  switch (action.type) {
    case ActionTypes.ACTIVE_WORKSPACE_ID:
      return {
        ...state,
        activeWorkspaceId: action.payload,
      };
    case ActionTypes.DELETE_ACTIVE_WORKSPACE:
      return {
        ...state,
        deleteActiveWorkspace: action.payload,
      };
    case ActionTypes.ACTIVE_WORKSPACE_NAME:
        return {
          ...state,
          activeWorkspaceName: action.payload,
      };
    case ActionTypes.UPLOAD_WORKSPACES:
      return {
        ...state,
        uploadWorkspaces: action.payload,
      };
    case ActionTypes.LOAD_USER_WORKSPACES:
      return {
        ...state,
        userWorkspaces: action.payload,
      };
    case ActionTypes.ADD_WORKSPACE:
      let actualWorkspaces = [...state.userWorkspaces];
      actualWorkspaces.push(action.payload);
      return {
        ...state,
        userWorkspaces: actualWorkspaces,
      };
    case ActionTypes.REMOVE_WORKSPACE:
      const workspaces = [...state.userWorkspaces];
      const index = workspaces.findIndex(obj => obj["workspace_id"] === Number(action.payload));
      const cleanedWorkspaces = index >= 0 ? [
            ...workspaces.slice(0, index),
            ...workspaces.slice(index + 1)
        ] : workspaces;
      return {
        ...state,
        userWorkspaces: cleanedWorkspaces,
      };
    case ActionTypes.UPDATE_WORKSPACE:
      const workspacesToUpdate = [...state.userWorkspaces];
      const workspaceId = Number(action.payload[0]);
      const updatedItem = {workspace_id: workspaceId, ...action.payload[1]};
      const updatedWorkspaces = workspacesToUpdate.map(
        item => item["workspace_id"] !== workspaceId ? item : updatedItem
      );
      return {
        ...state,
        userWorkspaces: updatedWorkspaces,
      };
    case ActionTypes.DISABLE_DASHBOARDS:
      return {
        ...state,
        disableDashboards: action.payload,
      };
    case ActionTypes.DEFAULT_WORKSPACE_NAME:
      return {
        ...state,
        defaultWorkspaceName: action.payload,
      };
    case ActionTypes.DEFAULT_WORKSPACE_ID:
      return {
        ...state,
        defaultWorkspaceId: action.payload,
      };
    default:
      return state;
  };
};
