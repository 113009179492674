import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class AdditionalBenefitsService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for getting additional benefit items from database by workspace id and pagination settings.
   * @param workspaceId
   * @param page
   * @param perPage
   * @returns pagination data with additional benefit items or rises Error
   */
  async paginateByWorkspace(workspaceId, page, perPage) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/additional_benefits/filter_with_pagination?&workspace_id=${workspaceId}&page=${page}&per_page=${perPage}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for creating additional benefit item in database.
   * @param params
   * @returns additional benefit id or rises Error
   */
  async create(params) {
    try {
      const urlWithParams = "/additional_benefits/new?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data.additional_benefit_id;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting additional benefit item from database.
   * @param additionalBenefitId
   * @returns additional benefit db item or rises Error
   */
  async get(additionalBenefitId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/additional_benefits/${additionalBenefitId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for deleting additional benefit item from database.
   * @param additionalBenefitId
   * @returns "OK" message or rises Error
   */
  async delete(additionalBenefitId) {
    try {
      await this.BASE_SERVICE.delete({
        url: `/additional_benefits/${additionalBenefitId}`,
      });
      return "OK";
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for updating all additional benefit items in database.
   * @param additionalBenefitId
   * @param data
   * @returns "OK" message or rises Error
   */
  async update(additionalBenefitId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/additional_benefits/${additionalBenefitId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting all additional benefit items from database by workspace id.
   * @param workspaceId
   * @returns list of additional benefits or rises Error
   */
     async getAllByWorkspace(workspaceId) {
      try {
        const data = await this.BASE_SERVICE.get({
          url: `/additional_benefits/all/filter_by?workspace_id=${workspaceId}`,
        });
        return data;
      } catch (e) {
        throw Error(e);
      }
    }
}
