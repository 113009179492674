import React from "react";
import { Button } from "react-bootstrap";
import { useAction } from "hooks";

export const TechnologyLicenseTableRow = (props) => {
  const { license } = props;
  const { changeActiveLicenseIdAction, configLicenseAction } = useAction();

  const handleChangeActiveId = () => {
    changeActiveLicenseIdAction(license.license_id);
    configLicenseAction(true);
  };

  return (
    <tr className="border-bottom">
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {license.license_name || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {license.license_count || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {`$${Number(license.total_est_cost)}` ?? "--"}
        </div>
      </td>
      <td>
        <Button
          variant="primary"
          size="sm"
          onClick={handleChangeActiveId}
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}>
          Config
        </Button>
      </td>
    </tr>
  );
};
