import React from "react";
import * as XLSX from "xlsx";
import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

export const ActualvsExpectedBarChart = (props) => {
  const { wb, ActualvsExpectedBarChartFilterObject, year } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);

  var solutionReleasedInProductionData = {
    data: [
      {
        x: [],
        y: [],
        type: "bar",
        marker: {
          color: "rgb( 0 89 255)",
        },
        base: 0,
        name: "Monthly Volume - Expected Monthly Volume",
      },
    ],
    layout: {
      //   title: "Actual vs Expected Transection volume",
      shapes: [],
      height: 190,
      yaxis: {
        title: {
          text: "<b>Monthly Volume</b>",
          font: {
            size: 12,
            family: '"Inter", sans-serif',
          },
        },
        tickfont: {
          family: '"Inter", sans-serif',
        },
        tickmode: "linear",
        tick0: 0
      },
      xaxis: {
        tickfont: {
          size: 12,
          family: '"Inter", sans-serif',
        },
        // tickangle :0
      },
      showlegend: true,
      legend: {
        x: 0,
        y: 1.7,
        xanchor: "left",
        yanchor: "top",
        font: {
          family: '"Inter", sans-serif',
          size: 12,
          color: "black"
        },
      },
      margin: { t: 0, b: 50, l: 40, r: 30 },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
    },
    config: {
      showLink: false,
      displayModeBar: false,
      responsive: true
    },
    heading: "Actual vs Expected Transection Volume",
  };

  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    var data = getExpectedVsActualTransactionVolumeData(
      wb,
      ActualvsExpectedBarChartFilterObject.filterObject
    );
    if (Object.keys(data).length > 0) {

      var Year = new Date(year).getFullYear();

      var currentYearRecords = getSelectedYearRecordsWithExpVol(data, Year);
      
      var expectedVolumeMax = Math.max(...currentYearRecords.expectedVolume);
      var yAxisMax = Math.max(...currentYearRecords.y);
      if(expectedVolumeMax > yAxisMax){
        solutionReleasedInProductionData.layout.yaxis.dtick = Math.floor(expectedVolumeMax/2); 
      }
      else{
        solutionReleasedInProductionData.layout.yaxis.dtick = Math.floor(yAxisMax/2); 
      }

      var setPointValues = currentYearRecords.expectedVolume;
      var setPointWidth = 0.7;

      var setPointLines = [];
      for (let i = 0; i < currentYearRecords.expectedVolume.length; i++) {
        setPointLines.push({
          type: "line",
          // x-reference is assigned to the x-values
          xref: "x",
          // y-reference is assigned to the plot paper [0,1]
          yref: "y",
          x0: i - setPointWidth / 2,
          y0: setPointValues[i],
          x1: i + setPointWidth / 2,
          y1: setPointValues[i],
          line: {
            color: "gray",
            width: 3,
          },
          name: "Expected Monthly Volume",
        });
      }
      solutionReleasedInProductionData.layout.shapes = setPointLines;
      solutionReleasedInProductionData.data[0].x = currentYearRecords.x;
      solutionReleasedInProductionData.data[0].y = currentYearRecords.y;
      solutionReleasedInProductionData.data[0].text = currentYearRecords.y.map(String);

    }
  }

  function getSelectedYearRecordsWithExpVol(data, year) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var filteredIndexes = [];

    var filterDates = data.x.filter((el, index) => {
      if (el.indexOf(year) > -1) {
        filteredIndexes.push(index);
        return el;
      }
    });

    var filterValues = [];
    var filteredVolumes = []
    filteredIndexes.forEach((i) => {
      filterValues.push(data.y[i]);
      filteredVolumes.push(data.expectedVolume[i]);
    });

    var missingMonthsOfSelectedYear = [];
    var missingYearValues = [];
    var missingYearVolumes = [];
    var indexesForMerging = [];

    monthNames.forEach((element) => {
      var monthWithYear = element + " " + year;
      if (filterDates.indexOf(monthWithYear) === -1) {
        missingMonthsOfSelectedYear.push(monthWithYear);
        missingYearValues.push(0);
      }
    });

    //var sortedFilteredDate = filterDates.sort();
    filterDates.forEach((ele, index) => {
      var splittedDate = ele.split(" ")[0];
      var indexToPush = monthNames.indexOf(splittedDate);
      missingMonthsOfSelectedYear.splice(indexToPush, 0, ele);
      missingYearValues.splice(indexToPush, 0, filterValues[index]);
      missingYearVolumes.splice(indexToPush, 0, filteredVolumes[index]);
    })

    return {
      x: missingMonthsOfSelectedYear,
      y: missingYearValues,
      expectedVolume: missingYearVolumes,
    };
  }

  function getExpectedVsActualTransactionVolumeData(workbookData, filterObject) {

    var trace1 = {
      x: [],
      y: [],
      expectedVolume: [],
    };
    if (Object.keys(workbookData).length > 0) {
      //will check if array conains an value
      Array.prototype.contains = function (v) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] === v) return true;
        }
        return false;
      };
      //return unique values from array
      Array.prototype.unique = function () {
        var arr = [];
        for (var i = 0; i < this.length; i++) {
          if (!arr.contains(this[i])) {
            arr.push(this[i]);
          }
        }
        return arr;
      }

      var factSolutionSheet = workbookData.Sheets['FactSolution'];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.BusinessUnitKey !== "" && ele.BusinessUnitKey === filterObject.BusinessUnit;
        })
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio;
        })
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.StageKey !== "" && ele.StageKey === filterObject.StageOfDevelopment;
        })
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        })
      }

      //get names from fact solution sheet to map with fact queue performance sheet
      var solutionNames = filteredSheetData.map(element => {
        return element.Name;
      })

      var uniqueNames = solutionNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      //read fact queue performance to map names with solution names
      var factQueuePerfromanceSheet = workbookData.Sheets['FactQueuePerformance'];
      var factQueuePerfromanceSheetData = XLSX.utils.sheet_to_json(factQueuePerfromanceSheet, {
        raw: false, defval: "",
        blankRows: false,
      });

      //map fact solution sheet names with fact queue performance solution names
      var filteredFactQueuePerformance = [];
      uniqueNames.forEach(name => {
        factQueuePerfromanceSheetData.forEach(element => {
          if (element["SolutionName"].replace(/ /g, "") === name.replace(/ /g, "")) {
            filteredFactQueuePerformance.push(element);
          };
        });
      });

      var lookUp = []
      var groupedData = []
      filteredFactQueuePerformance.forEach(element => {
        if (element["Actual Monthly Volume"].indexOf(",") > -1) {
          element["Actual Monthly Volume"] = element["Actual Monthly Volume"].replaceAll(",", "");
        }
        if (element["Expected Monthly Volume"].indexOf(",") > -1) {
          element["Expected Monthly Volume"] = element["Expected Monthly Volume"].replaceAll(",", "");
        }
        if (lookUp.indexOf(element.StartOfMonth) > -1) {
          var index = lookUp.indexOf(element.StartOfMonth);


          if (element["Expected Monthly Volume"] === "") {
            groupedData[index]["Expected Monthly Volume"] += 0;
          }
          else {
            groupedData[index]["Expected Monthly Volume"] += parseInt(element["Expected Monthly Volume"])
          }
          if (element["Actual Monthly Volume"] === "") {
            groupedData[index]["Actual Monthly Volume"] += 0;
          }
          else {
            groupedData[index]["Actual Monthly Volume"] += parseInt(element["Actual Monthly Volume"])
          }

        }
        else {
          lookUp.push(element.StartOfMonth);
          if (element["Expected Monthly Volume"] === "") {
            element["Expected Monthly Volume"] = 0;
          }
          else {
            element["Expected Monthly Volume"] = parseInt(element["Expected Monthly Volume"]);
          }
          if (element["Actual Monthly Volume"] === "") {
            element["Actual Monthly Volume"] = 0;
          }
          else {
            element["Actual Monthly Volume"] = parseInt(element["Actual Monthly Volume"]);
          }
          groupedData.push(element);
        }
      })

      trace1.x = groupedData.map(ele => { return ele.StartOfMonth });
      trace1.y = groupedData.map(ele => { return ele["Actual Monthly Volume"] });
      trace1.expectedVolume = groupedData.map(ele => { return ele["Expected Monthly Volume"] });
    }
    return trace1
  }

  return (
    <>
      <div
        className="rounded my-1 py-1"
        style={{ background: "#e1e1e1", textAlign: "center" }}
      >
        <h6 style={{ marginBottom: "0" }}>
          {solutionReleasedInProductionData.heading}<a className="info" style={{
            border: '1px solid #000',
            borderRadius: '50px',
            fontSize: '9px',
            margin: 0,
            fontWeight: 'bold',
            padding: '0px 4px',
            color: 'black',
            verticalAlign: 'middle',
            marginLeft: '5px'
          }}>i</a>
        </h6>
      </div>
      <div
        className="h-30 rounded"
      >
        <PlotlyComponent
          className="whatever"
          style={{ width: "100%" }}
          data={solutionReleasedInProductionData.data}
          layout={solutionReleasedInProductionData.layout}
          config={solutionReleasedInProductionData.config}
        />
      </div>
    </>
  );
};
