import { useState } from "react";
import { Card, Form, Button, Table, FormCheck } from "react-bootstrap";
import { UserTableRow } from "../components";
import { Loading, TablePagination } from "pages";

const columns = ["Name", "Date Created", "Verified", "Status", "Action"];

export const UsersTable = (props) => {
  const {
    users = [],
    allSelected,
    isCurrentWorkspaceAdmin,
    setIsCurrentWorkspaceAdmin,
    paginationUserWorkspaceAssignmentsQuery,
    refetch,
    page,
    perPage,
  } = props;
  const total = paginationUserWorkspaceAssignmentsQuery.data?.total ?? 0;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = users.filter((u) => u.isSelected).length === 0;

  const selectAllUsers = () => {
    props.selectAllUsers && props.selectAllUsers();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  };

  const applyBulkAction = () => {
    if (bulkOption === "delete_user") deleteUsers();
    if (bulkOption === "make_admin") makeAdmins();
  };

  const deleteUsers = (ids) => {
    props.deleteUsers && props.deleteUsers(ids);
  };

  const makeAdmins = (ids) => {
    props.makeAdmins && props.makeAdmins(ids);
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow">
      <Card.Body>
        {isCurrentWorkspaceAdmin ? (
          <div className="d-flex mb-3">
            <Form.Select
              className="fmxw-200"
              disabled={disabledBulkMenu}
              value={bulkOption}
              onChange={bulkActionChange}>
              <option value="bulk_action">Bulk Action</option>
              <option value="make_admin">Make an Admin</option>
              <option value="delete_user">Delete User</option>
            </Form.Select>
            <Button
              variant="secondary"
              size="sm"
              className="ms-3"
              disabled={disabledBulkMenu}
              onClick={applyBulkAction}>
              Apply
            </Button>
          </div>
        ) : null}

        {paginationUserWorkspaceAssignmentsQuery.isRefetching ? (
          <Loading marginTop="1%" marginLeft="45%" />
        ) : (
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                {isCurrentWorkspaceAdmin ? (
                  <th className="border-bottom">
                    <FormCheck type="checkbox" className="dashboard-check">
                      <FormCheck.Input
                        id="userCheckAll"
                        checked={allSelected}
                        onChange={selectAllUsers}
                      />
                      <FormCheck.Label htmlFor="userCheckAll" />
                    </FormCheck>
                  </th>
                ) : null}
                {columns.map((column, i) => {
                  return (
                    <th key={`column=${i}`} className="border-bottom">
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="border-0">
              {users.map((u) => (
                <UserTableRow
                  key={u.user_id}
                  {...u}
                  selectUser={props.selectUser}
                  deleteUsers={props.deleteUsers}
                  isCurrentWorkspaceAdmin={isCurrentWorkspaceAdmin}
                  setIsCurrentWorkspaceAdmin={setIsCurrentWorkspaceAdmin}
                  handleUpdate={refetch}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
        <TablePagination
          page={page}
          perPage={perPage}
          total={total}
          itemsLength={users.length}
          setPage={props.changePage}
        />
      </Card.Footer>
    </Card>
  );
};
