import React, { useState } from "react";
import {
  BellIcon,
  InboxIcon,
  SearchIcon,
  SupportIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import {
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Routes } from "routes";
import { userNotifications } from "data/notifications";
import { Notification } from "./Notification";
import { useAction, useTypedSelector } from "hooks";
import { AuthService, UserService } from "services";
import { cleanStorage, handleError } from "utils";

const apiAuthService = new AuthService();
const userService = new UserService();

const getUser = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await userService.get(userId);
  }
};

export const Topbar = (props) => {
  const history = useHistory();
  const [notifications, setNotifications] = useState(userNotifications);
  const allNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );
  const bellIconClasses = !allNotificationsRead ? "unread" : "";
  const {
    logoutSuccessAction,
    logoutFailureAction,
    updateActiveWorkspaceNameAction,
    updateActiveWorkspaceIdAction,
    uploadWorkspacesAction,
    resetWorkspaceAssignmentAction,
    updateUserIdAction,
  } = useAction();
  const userId = useTypedSelector((state) => state.user.userId);
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );
  const currentWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );

  const userQuery = useQuery(["userTopbarData", userId], getUser, {
    onError: (e) => handleError(e, history),
  });
  const userName = userQuery.data
    ? `${userQuery.data.first_name} ${userQuery.data.last_name}`
    : "";

  const profilePhoto = userQuery.data ? userQuery.data.profile_photo_url : "";
  
  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 400);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await resetWorkspaceAssignmentAction(
        userId,
        "",
        currentWorkspaceAssignmentId,
        "logout"
      );
      const response = await apiAuthService.logout();
      if (response === "OK") {
        updateUserIdAction("");
        updateActiveWorkspaceNameAction("");
        updateActiveWorkspaceIdAction("");
        uploadWorkspacesAction(true);
        logoutSuccessAction();
        cleanStorage();
        history.push(Routes.SignIn.path);
      }
    } catch (e) {
      logoutFailureAction();
      handleError(e, history);
    }
  };

  return (
    <Navbar
      expand
      variant="dark"
      className="navbar-top navbar-dashboard ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form className="navbar-search form-inline">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <SearchIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className={`text-dark notification-bell ${bellIconClasses}`}>
                <BellIcon className="icon icon-sm bell-shake text-gray-900" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map((n) => (
                    <Notification
                      key={`notification-${n.id}`}
                      link={`/${activeWorkspaceName}${n.path}`}
                      {...n}
                    />
                  ))}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item} className="ms-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {profilePhoto ? (
                    <Image
                      src={profilePhoto}
                      className="avatar rounded-circle"
                    />
                  ) : null}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold text-gray-900">
                      {userName}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
                <Dropdown.Item
                  as={Link}
                  to={`/${activeWorkspaceName}${Routes.SettingsProfile.path}`}
                  className="d-flex align-items-center">
                  <UserCircleIcon className="dropdown-icon text-gray-400 me-2" />{" "}
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/${activeWorkspaceName}${Routes.Messages.path}`}
                  className="d-flex align-items-center">
                  <InboxIcon className="dropdown-icon text-gray-400 me-2" />{" "}
                  Messages
                </Dropdown.Item>
                <Dropdown.Item
                  href="https://cognitiveautomation.atlassian.net/servicedesk/customer/user/login?destination=portals"
                  target="_blank"
                  className="d-flex align-items-center">
                  <SupportIcon className="dropdown-icon text-gray-400 me-2" />{" "}
                  Support
                </Dropdown.Item>

                <Dropdown.Divider as="div" className="my-1" />

                <Dropdown.Item
                  className="d-flex align-items-center"
                  onClick={handleLogout}>
                  <LogoutIcon className="dropdown-icon text-primary me-2" />{" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
