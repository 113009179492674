import React, { useEffect, useState } from "react";
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import {
  Alert,
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import BgImage from "assets/img/illustrations/signin.svg";
import { Routes } from "routes";
import { UserService, WorkspaceAssignmentService } from "../../services";
import { cleanStorage, handleError } from "utils";

const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

const fetchUser = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await userService.getVerifyUserData(userId);
  }
};

const fetchWorkspaceAssignmentsIds = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await workspaceAssignmentService.getIdsByUser(userId);
  }
};

export const VerifyEmail = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [error, setError] = useState("");
  // fields
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  // booleans
  const [isDisabled, setIsDisabled] = useState(false);

  const userQuery = useQuery(["verifyUserEmailQuery", userId], fetchUser, {
    onError: (e) => handleError(e, history),
  });

  const workspaceAssignmentsIdsQuery = useQuery(
    ["workspaceAssignmentsIdsQuery", userId],
    fetchWorkspaceAssignmentsIds,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const failedDuringSaving = (error) => {
    const errorMsg = error ? error : "Failure occurred when saving.";
    setError(errorMsg);
  };

  const workspaceAssignmentsIds = workspaceAssignmentsIdsQuery.data
    ? workspaceAssignmentsIdsQuery.data
    : [];

  const updateUserPassword = async () => {
    try {
      const data = {
        password: password,
        confirm_password: confirmPassword,
      };
      return await userService.updatePassword(userId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateUserWorkspaceAssignments = async () => {
    try {
      const data = {
        is_verified: true,
      };
      for (const item of workspaceAssignmentsIds) {
        const workspaceAssignmentId = item.workspace_assignment_id;
        await workspaceAssignmentService.updateVerifyStatus(
          workspaceAssignmentId,
          data
        );
      }
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsDisabled(true);
    if (password !== confirmPassword) {
      setError("Password and confirmation password must match.");
      setIsDisabled(false);
      return;
    }
    const response = await updateUserPassword();
    if (response) {
      await updateUserWorkspaceAssignments();
      history.push(Routes.SignIn.path);
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    setIsDisabled(false);
    if (!password || !confirmPassword || !isChecked) {
      setIsDisabled(true);
    }
    cleanStorage();
  }, [password, confirmPassword]);

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row
            className="justify-content-center form-bg-image mt-5"
            style={{ backgroundImage: `url(${BgImage})` }}>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Finish Sign Up</h3>
                </div>
                {error ? (
                  <Alert key="danger" variant="danger">
                    {error}
                  </Alert>
                ) : null}
                <Form className="mt-4">
                  <Form.Group id="firstName" className="mb-4">
                    <Form.Label>Your First Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        readOnly
                        value={userQuery.data ? userQuery.data.first_name : ""}
                        type="text"
                        placeholder="John"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="lastName" className="mb-4">
                    <Form.Label>Your Last Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        readOnly
                        value={userQuery.data ? userQuery.data.last_name : ""}
                        type="text"
                        placeholder="Doe"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        readOnly
                        value={
                          userQuery.data ? userQuery.data.email_address : ""
                        }
                        type="email"
                        placeholder="example@company.com"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setError("");
                          setPassword(e.target.value);
                        }}
                        minLength="6"
                        maxLength="50"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        minLength="6"
                        maxLength="50"
                        onChange={(e) => {
                          setError("");
                          setConfirmPassword(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                  <FormCheck type="checkbox" className="mb-4">
                    <FormCheck.Input
                      id="terms"
                      className="me-2"
                      onClick={(e) => {
                        setError("");
                        setIsChecked(e.target.value);
                      }}
                    />
                    <FormCheck.Label htmlFor="terms" className="fw-normal mb-0">
                      I agree to the{" "}
                      <Card.Link className="fw-bold">
                        terms and conditions
                      </Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isDisabled}>
                      Finish Sign Up
                    </Button>
                  </div>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.SignIn.path}
                      className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
