import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

export const WorkspaceTeamTableRow = (props) => {
  const { activeId, team, changeActiveId } = props;
  const rowColor = activeId === team.business_unit_id ? "blue" : "";

  useEffect(() => {}, [activeId]);

  return (
    <tr className="border-bottom" style={{ color: rowColor }}>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {team.business_unit_name || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {team.business_unit_description || ""}
        </div>
      </td>
      <td>
        <Button
          variant="primary"
          size="sm"
          onClick={() => changeActiveId(team.business_unit_id)}
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}>
          Config
        </Button>
      </td>
    </tr>
  );
};
