import { BaseAPIService } from "./base";
import { ParamsService } from "./params";
import { cleanStorage } from "../utils/cleanStorage";

export class AuthService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
     * Method is using for authenticating user and put tokens to the local storage. After than user will not be able to access the application.
     * @param email
     * @param password
     * @returns user workspace_id value or rises Error
     */
    async login({ email, password }) {
        try {
            const data = await this.BASE_SERVICE.post(
                {
                    url: `/auth/login?email=${email}&password=${password}`,
                    body: {},
                }
            );
            localStorage.setItem("access_token", JSON.stringify(data["access_token_data"]));
            localStorage.setItem("refresh_token", JSON.stringify(data["refresh_token_data"]));
            localStorage.setItem("user_id", JSON.stringify(data["user_id"]));
            return [data["workspace_id"], data["user_id"]];
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for registering the user in the application.
     * @param params
     * @returns user data object or rises an Error
     */
    async register(params) {
        try {
            const urlWithParams = '/auth/register?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
   * Method is using for deactivating current user and deleting tokens from local storage. After than user will not be able to access the application.
   * @returns "OK" message or rises Error
   */
    async logout() {
        try {
            const data = await this.BASE_SERVICE.post(
                {
                    url: "auth/logout",
                }
            );
            if (data.message === "User is successfully logout") {
                return "OK"
            };
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * This method is using for checking JWT token with the backend before application starts up. If JWT is expired or not valid method will rise an exception
     * @returns
     */
    async checkToken() {
        try {
            const data =
                await this.BASE_SERVICE.get(
                    {
                        url: "auth/check_token",
                    }
                );
            return data;
        } catch (e) {
            console.error(`Error code while checking token: `, e);
            if (e === "401") throw Error("Wrong JWT token");
            else throw Error("Unknown error!");
        }
    }
}
