const paramsDict = {
    actualAnnualBenefit: "&actual_total_benefit=",
    activeWorkspaceId: "&active_workspace_id=",
    additionalBenefitId: "&additional_benefit_id=",
    annualSalary: "&annual_salary=",
    benefitName: "&benefit_name=",
    benefitDescription: "&benefit_description=",
    benefitCategory: "&benefit_category=",
    benefitPerTransaction: "&benefit_per_transaction=",
    benefitValue: "&benefit_value=",
    businessUnitDescription: "&business_unit_description=",
    businessUnitId: "&business_unit_id=",
    businessUnitName: "&business_unit_name=",
    cardId: "&card_id=",
    commissionDate: "&commission_date=",
    complexity: "&complexity=",
    componentDescription: "&component_description=",
    componentKey: "&component_key=",
    componentName: "&component_name=",
    componentType: "&component_type=",
    confirmPassword: "&confirm_password=",
    costType: "&cost_type=",
    decommissionDate: "&decommission_date=",
    effectiveWorkingHours: "&effective_working_hours=",
    effort: "&effort=",
    email: "&email_address=",
    estAnnualBenefit: "&est_annual_benefit=",
    estAnnualCost: "&est_annual_cost=",
    estCost: "&est_cost=",
    estVolumeFrequency: "&est_avg_volume=",
    estVolumePerAnnum: "&est_volume_per_annum=",
    firstName: "&first_name=",
    frequency: "&frequency=",
    groupDescription: "&group_description=",
    groupId: "&group_id=",
    groupName: "&group_name=",
    healthChangeReason: "&change_reason=",
    healthName: "&health_name=",
    hourlyRate: "&hourly_rate=",
    isAdmin: "&is_admin=",
    isActive: "&is_active=",
    isDisableDashboards: "&disable_dashboards=",
    isPending: "&is_pending=",
    isSuspended: "&is_suspended=",
    isVerified: "&is_verified=",
    keyBenefit: "&key_benefit=",
    lastName: "&last_name=",
    licenseCost: "&license_cost=",
    licenseCount: "&license_count=",
    licenseDescription: "&license_description=",
    licenseId: "&license_id=",
    licenseName: "&license_name=",
    licenseType: "&license_type=",
    organizationRole: "&org_role=",
    password: "&password=",
    plannedCommissionDate: "&planned_commission_date=",
    phoneNumber: "&phone_number=",
    profilePhotoUrl: "&profile_photo_url=",
    queueDescription: "&queue_description=",
    queueId: "&queue_id=",
    queueKey: "&queue_key=",
    queueName: "&queue_name=",
    statusName: "&status_name=",
    solutionComponentId: "&component_id=",
    solutionDescription: "&solution_description=",
    solutionId: "&solution_id=",
    solutionName: "&solution_name=",
    targetCompletionRate: "&target_completion_rate=",
    technologyDescription: "&tech_description=",
    techId: "&tech_id=",
    technologyName: "&tech_name=",
    timePerTrans: "&min_per_trans=",
    totalEstCost: "&total_est_cost=",
    userId: "&user_id=",
    workspaceDescription: "&workspace_description=",
    workspaceEffectiveWorkingHours: "&effective_working_hours=",
    workspaceId: "&workspace_id=",
    workspaceImgPath: "&workspace_img_path=",
    workspaceName: "&workspace_name=",
    workspacePath: "&workspace_path=",
};

export class ParamsService {
    _urlWithParams;
    constructor(url, params) {
        this._urlWithParams = url;
        for (const [key, value] of Object.entries(params)) {
            if (key in paramsDict) {
                if (value) {
                    this._urlWithParams =
                        this._urlWithParams + `${paramsDict[key]}${value}`;
                };
            };
        };
    };

    get urlWithParams() {
        console.log("this._urlWithParams", this._urlWithParams)
        return this._urlWithParams;
    };
};
