import React, { useState } from "react";
import { MenuIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Nav, Image, Collapse } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const CollapsableNavItem = (props) => {
  const {
    eventKey,
    title,
    icon: NavItemIcon,
    image,
    menuIcon,
    children = null,
  } = props;
  const location = useLocation();
  const { pathname } = location;
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const isOpened = collapsedItems.some((item) => item.includes(eventKey));

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some((item) => item.includes(itemKey));
    const newCollapsedItems = isCollapsed
      ? collapsedItems.filter((item) => !item.includes(itemKey))
      : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  return (
    <Nav.Item>
      <Nav.Link
        onClick={(e) => {
          e.preventDefault();
          onNavItemCollapse(eventKey);
        }}
        aria-expanded={isOpened}
        aria-controls={eventKey}
        className="d-flex justify-content-between align-items-center">
        <span>
          {image ? (
            <Image
              src={image}
              width={20}
              height={20}
              className="sidebar-icon svg-icon"
            />
          ) : (
            <span className="sidebar-icon">
              <NavItemIcon className="icon icon-xs me-2" />
            </span>
          )}
          <span className="sidebar-text">{title}</span>
        </span>
        <span className="link-arrow">
          {menuIcon ? (
            <MenuIcon className="icon icon-sm" />
          ) : (
            <ChevronRightIcon className="icon icon-sm" />
          )}
        </span>
      </Nav.Link>
      <Collapse in={isOpened} className="multi-level">
        <div id={eventKey}>{children}</div>
      </Collapse>
    </Nav.Item>
  );
};
