import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { licenseReducer } from "./licenseReducer";
import { queueReducer } from "./queueReducer";
import { tabReducer } from "./tabReducer";
import { technologyReducer } from "./technologyReducer";
import { userReducer } from "./userReducer";
import { workspaceAssignmentReducer } from "./workspaceAssignmentReducer";
import { workspaceReducer } from "./workspaceReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    license: licenseReducer,
    queue: queueReducer,
    tab: tabReducer,
    technology: technologyReducer,
    user: userReducer,
    workspaceAssignment: workspaceAssignmentReducer,
    workspace: workspaceReducer,
});
