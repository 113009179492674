
import React, { useState } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Button, Breadcrumb } from 'react-bootstrap';
import { Form, Card, Row, Col } from "react-bootstrap";

export const Ideate = () => {
  const [costType, setCostType] = useState("Salary");
  const [frequency, setFrequency] = useState("52");
  const [effort, setEffort] = useState("0");
  const [cost, setCost] = useState("0");

  return (
    <>
      <div className="py-4">
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
          <Breadcrumb.Item active>Ideate</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mb-3 mb-lg-0">
            <h4>Ideate</h4>
            <p className="mb-0">
              Quickly capture ideas and understand their respective benefits
            </p>
          </div>
        </div>
        <Card border="0" className="shadow p-4 mt-4">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows="3" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Row className="justify-content-center">
                <Col xs={12} lg={6}>
                  <Form.Label>Frequency</Form.Label>
                  <fieldset>
                    <Row className="justify-content-center">
                      <Col xs={12} lg={3}>
                        <Form.Check
                          defaultChecked
                          type="radio"
                          value="52"
                          label="Weekly"
                          name="Frequency"
                          id="radio1"
                          htmlFor="radio1"
                          onInput={e => setFrequency(e.target.value)}
                        />
                      </Col>
                      <Col xs={12} lg={3}>
                        <Form.Check
                          type="radio"
                          value="12"
                          label="Monthly"
                          name="Frequency"
                          id="radio2"
                          htmlFor="radio2"
                          onInput={e => setFrequency(e.target.value)}
                        />
                      </Col>
                      <Col xs={12} lg={3}>
                        <Form.Check
                          type="radio"
                          value="4"
                          label="Quarterly"
                          name="Frequency"
                          id="radio3"
                          htmlFor="radio3"
                          onInput={e => setFrequency(e.target.value)}
                        />
                      </Col>
                      <Col xs={12} lg={3}>
                        <Form.Check
                          type="radio"
                          value="1"
                          label="Annually"
                          name="Frequency"
                          id="radio4"
                          htmlFor="radio4"
                          onInput={e => setFrequency(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Label>Effort (hours)</Form.Label>
                  <Form.Control type="number" onInput={e => setEffort(e.target.value)} max="9999999999" step="0.01" />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row className="justify-content-center">
                <Col xs={12} lg={6}>
                  <Form.Label>Cost Type</Form.Label>
                  <fieldset>
                    <Row className="justify-content-start">
                      <Col xs={12} lg={3}><Form.Check
                        defaultChecked
                        type="radio"
                        defaultValue="salary"
                        label="Salary ($)"
                        name="Cost Type"
                        id="salary"
                        htmlFor="salary"
                        onInput={() => setCostType("Salary")}
                      /></Col>
                      <Col xs={12} lg={3}><Form.Check
                        type="radio"
                        defaultValue="hourly"
                        label="Hourly ($)"
                        name="Cost Type"
                        id="hourly"
                        htmlFor="hourly"
                        onInput={() => setCostType("Hourly")}
                      /></Col>
                    </Row>
                  </fieldset>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Label>{costType} ($)</Form.Label>
                  <Form.Control type="number" max="999999999999" onInput={e => setCost(e.target.value)} step="0.01" />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Savings ($ per annum)</Form.Label>
              <Form.Control type="name" value={cost > 0 ? (costType === 'Salary' ? parseFloat(frequency * effort * (cost / 1200)).toFixed(2) : parseFloat(frequency * effort * cost).toFixed(2)) : '0'} disabled />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit Idea
            </Button>
          </Form>
        </Card>
      </div>
    </>
  );
};
