import { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAction, useTypedSelector } from "hooks";
import { WorkspaceAssignmentService } from "services";
import { handleError } from "utils";

const workspaceAssignmentService = new WorkspaceAssignmentService();

export const ChangeAdminRightsModal = (props) => {
  const {
    show,
    onHide,
    workspaceAssignmentId,
    userEmail,
    type,
    setUserIsAdmin,
    setIsCurrentWorkspaceAdmin,
  } = props;
  const history = useHistory();
  // general
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [itemIsSaved, setItemIsSaved] = useState(false);
  // ids
  const activeWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  // style
  const statusColor = isSaveTypeSuccessful ? "success" : "danger";
  const closeButtonText = itemIsSaved ? "Close" : "Cancel";

  const {
    addAdminWorkspaceAssignmentAction,
    removeAdminWorkspaceAssignmentAction,
  } = useAction();

  const isSavedSuccessfully = () => {
    setStatus("Admin rights for user has been updated successfully!");
    setIsStatusTypeSuccessful(true);
    setItemIsSaved(true);
  };

  const failedDuringSaving = (e) => {
    const errorMsg = e ? e : "Failure occurred. Please, try again.";
    setStatus(errorMsg);
    setIsStatusTypeSuccessful(false);
  };

  const updateAdminRights = async () => {
    try {
      const data = {
        is_admin: type === "add" ? true : "false",
      };
      return await workspaceAssignmentService.update(
        workspaceAssignmentId,
        data
      );
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setError("");
    setStatus("");
    const response = await updateAdminRights();
    if (response) {
      const userHasGottenAdminRights = type === "add" ? true : false;
      setUserIsAdmin(userHasGottenAdminRights);
      if (activeWorkspaceAssignmentId == workspaceAssignmentId) {
        setIsCurrentWorkspaceAdmin(userHasGottenAdminRights);
      }
      if (type === "add") {
        addAdminWorkspaceAssignmentAction(Number(workspaceAssignmentId));
      }
      if (type === "remove") {
        removeAdminWorkspaceAssignmentAction(Number(workspaceAssignmentId));
      }
      isSavedSuccessfully();
    }
  };

  const handleHideModal = () => {
    setError("");
    setStatus("");
    onHide(true);
    setItemIsSaved(false);
  };

  return (
    <Modal centered show={show} onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type ? type[0].toUpperCase() + type.slice(1) : "Update"} Admin Rights
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        ) : null}
        {saveStatus ? (
          <Alert key={statusColor} variant={statusColor}>
            {saveStatus}
          </Alert>
        ) : null}
        Are you sure you want to {type} admin rights for the user{" "}
        <b style={{ color: "blue" }}>{userEmail}</b>?
      </Modal.Body>
      <Modal.Footer>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Col md={itemIsSaved ? 12 : 6} xs={itemIsSaved ? 12 : 6}>
            <Button variant="secondary" onClick={handleHideModal}>
              {closeButtonText}
            </Button>
          </Col>
          {!itemIsSaved ? (
            <Col md={6} xs={6}>
              <Button variant="primary" type="submit" onClick={handleSave}>
                Confirm
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
