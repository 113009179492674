import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class QueueService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for creating queue item in database.
    * @param params
    * @returns queue id or rises Error
    */
    async create(params) {
        try {
            const urlWithParams = 'queues/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.queue_id;
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
    * Method is using for getting all queue component items from database by solutionId.
    * @param solutionId
    * @returns list of solution components or rises Error
    */
    async getAllBySolutionId(solutionId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/queues/all/filter_by?solution_id=${solutionId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
    * Method is using for getting all queue component items from database by workspace id.
    * @param workspaceId
    * @returns list of solution components or rises Error
    */
         async getAllByWorkspaceId(workspaceId) {
            try {
                const data = await this.BASE_SERVICE.get(
                    {
                        url: `/queues/all/filter_by?workspace_id=${workspaceId}`,
                    }
                );
                return data;
            } catch (e) {
                throw Error(e);
            }
        };

    /**
    * Method is using for updating all queue items in database.
    * @param queueId
    * @param data
    * @returns "OK" message or rises Error
    */
    async update(queueId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/queues/${queueId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
     * Method is using for deleting queue item from database.
     * @param queueId
     * @returns "OK" message or rises Error
     */
    async delete(queueId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/queues/${queueId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for getting queue item from database.
     * @param queueId
     * @returns queue db item or rises Error
     */
    async get(queueId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/queues/${queueId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };
};
