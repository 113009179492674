import { BaseAPIService } from "./base";

export class SessionService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for getting all session items from database by workspace id.
    * @param workspaceId
    * @returns list of session items or rises Error
    */
    async getAllByWorkspace(workspaceId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/session/all/filter_by?&workspace_id=${workspaceId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for creating many session items in database.
     * @param params
     * @returns message response or rises Error
     */
     async createMany(workspaceId, formData) {
        try {
            const response = await this.BASE_SERVICE.post(
                {
                    url: `/session/add_many/${workspaceId}`,
                    type: "form",
                    formData: formData,
                    body: {},
                }
            );
            return response;
        } catch (e) {
            throw Error(e);
        }
    };
};
