import ActionTypes from "./ActionTypes";

export const updateUserIdAction = (payload) => ({
    type: ActionTypes.USER_ID,
    payload,
});

export const updateUsersByWorkspace = (payload) => ({
    type: ActionTypes.ALL_USERS_BY_WORKSPACE,
    payload,
});
