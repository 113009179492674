import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SolutionHealthService } from "services";
import { handleError } from "utils";

const solutionHealthService = new SolutionHealthService();

export const RelatedSolutionComponentsTableRow = (props) => {
  const { activeId, component, changeActiveId } = props;
  const history = useHistory();
  const rowColor = activeId === component.component_id ? "blue" : "";
  const [componentHealth, setComponentHealth] = useState("");

  const fetchSolutionHealthBySolutionComponentId = async () => {
    try {
      const response = await solutionHealthService.getBySolutionComponentId(
        component.component_id
      );
      setComponentHealth(response[0]["health_name"]);
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleChangeActiveId = () => {
    changeActiveId(component.component_id);
  };

  useEffect(() => {
    fetchSolutionHealthBySolutionComponentId();
  }, [activeId]);

  return (
    <tr className="border-bottom" style={{ color: rowColor }}>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {component.component_name}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {component.component_type}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">{componentHealth}</div>
      </td>
      <td>
        <Button
          variant="primary"
          size="sm"
          onClick={handleChangeActiveId}
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}>
          Config
        </Button>
      </td>
    </tr>
  );
};
