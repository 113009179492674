import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { SolutionRelatedTable } from "../tables";
import { CustomToggle } from "pages/components";
import {
  ComponentService,
  LicenseService,
  SolutionHealthService,
} from "services";
import { checkEmptyField, handleError } from "utils";

const componentService = new ComponentService();
const licenseService = new LicenseService();
const solutionHealthService = new SolutionHealthService();

const componentTypeOptions = ["Loader", "Processor", "Reporter"];
const componentHealthOptions = ["Stable", "Improving", "Unstable", "Stopped"];
const defaultHealthChangeReason = "Initial deployment";
const defaultComponentType = componentTypeOptions[1];
const defaultComponentHealth = componentHealthOptions[0];

const fetchComponents = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await componentService.getAllByWorkspaceId(workspaceId);
  }
};

const fetchLicenses = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await licenseService.getAllByWorkspace(workspaceId);
  }
};

export const ComponentsTab = (props) => {
  const { type, solutionId, workspaceId } = props;
  const history = useHistory();
  const [behaveType, setBehaveType] = useState(type);
  // notifiers
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields to edit
  const [componentName, setComponentName] = useState("");
  const [componentKey, setComponentKey] = useState("");
  const [componentDescription, setComponentDescription] = useState("");
  const [componentHealth, setComponentHealth] = useState(
    defaultComponentHealth
  );
  const [componentType, setComponentType] = useState(defaultComponentType);
  const [healthChangeReason, setHealthChangeReason] = useState(
    defaultHealthChangeReason
  );
  const [relatedTechLicense, setRelatedTechLicense] = useState("");
  const [userComponentName, setUserComponentName] = useState("");
  // booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [isRequiredHealthChangeReason, setIsRequiredHealthChangeReason] =
    useState(false);
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
  const [updateSolutionHealthItem, setUpdateSolutionHealthItem] =
    useState(false);
  // ids
  const [activeComponentId, setActiveComponentId] = useState("");
  const [activeSolutionHealthId, setActiveSolutionHealthId] = useState("");
  const statusColor = isSaveTypeSuccessful ? "green" : "red";

  const componentsQuery = useQuery(
    ["solutionComponents", workspaceId],
    fetchComponents,
    { onError: (e) => handleError(e, history) }
  );
  const components = componentsQuery.data
    ? componentsQuery.data.filter(
        (component) => component.solution_id === Number(solutionId)
      )
    : [];

  const componentsWithoutSolution = componentsQuery.data
    ? componentsQuery.data.filter((component) => component.solution_id === null)
    : [];

  const licensesQuery = useQuery(
    ["licensesForComponents", workspaceId],
    fetchLicenses,
    { onError: (e) => handleError(e, history) }
  );
  const licenses = licensesQuery.data ? licensesQuery.data : [];

  const isSavedSuccessfully = () => {
    setStatus("Changes saved successfully.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
    setIsStatusTypeSuccessful(false);
  };

  const failedDuringDeleting = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when deleting");
    setIsStatusTypeSuccessful(false);
  };

  const createComponent = async () => {
    try {
      const data = {
        componentName: componentName,
        componentKey: componentKey,
        componentDescription: componentDescription,
        componentType: componentType,
        solutionId: solutionId,
        licenseId: relatedTechLicense,
        workspaceId: workspaceId,
      };
      return await componentService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateComponent = async () => {
    try {
      if (activeComponentId) {
        const data = {
          component_name: componentName,
          component_key: componentKey,
          component_description: componentDescription,
          component_type: componentType,
          solution_id: solutionId,
          license_id: relatedTechLicense,
          workspace_id: workspaceId,
        };
        return await componentService.update(activeComponentId, data);
      }
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createSolutionHealth = async (componentId) => {
    try {
      const data = {
        healthName: componentHealth,
        healthChangeReason: healthChangeReason,
        solutionComponentId: componentId,
        workspaceId: workspaceId,
      };
      return await solutionHealthService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateSolutionHealth = async () => {
    try {
      const data = {
        health_name: componentHealth,
        change_reason: healthChangeReason,
        component_id: activeComponentId,
        workspace_id: workspaceId,
      };
      return await solutionHealthService.update(activeSolutionHealthId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const deleteComponent = async () => {
    try {
      return await componentService.delete(activeComponentId);
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (activeComponentId) {
      const componentResponse = await deleteComponent();
      if (componentResponse) {
        setStatus("Deletion successful.");
        setIsStatusTypeSuccessful(true);
        handleNewComponent();
        componentsQuery.refetch();
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (behaveType === "new") {
      await save();
    }
    if (behaveType === "config" && !activeComponentId) {
      await save();
    }
    if (behaveType === "config" && activeComponentId) {
      await update();
    }
    componentsQuery.refetch();
  };

  const checkFields = () => {
    let changeReasonRes = "OK";
    const descriptionRes = checkEmptyField(
      componentDescription,
      "Description",
      setError
    );
    const keyRes = checkEmptyField(componentKey, "Component Key", setError);
    const nameRes = checkEmptyField(componentName, "Component Name", setError);
    if (isRequiredHealthChangeReason) {
      changeReasonRes = checkEmptyField(
        healthChangeReason,
        "Health Change Reason",
        setError
      );
    }
    const responses = [descriptionRes, keyRes, nameRes, changeReasonRes];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const save = async () => {
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      const componentId = await createComponent();
      if (componentId) {
        const solutionHealthId = await createSolutionHealth(componentId);
        if (solutionHealthId) {
          isSavedSuccessfully();
          handleNewComponent();
        }
      }
    }
  };

  const update = async () => {
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      let solutionHealthResponse = "";
      const componentResponse = await updateComponent();
      if (activeSolutionHealthId) {
        solutionHealthResponse = await updateSolutionHealth();
      }
      if (!activeSolutionHealthId) {
        solutionHealthResponse = await createSolutionHealth(activeComponentId);
        setActiveSolutionHealthId(solutionHealthResponse);
      }
      if (componentResponse && solutionHealthResponse) {
        setUpdateSolutionHealthItem(false);
        isSavedSuccessfully();
      }
    }
  };

  const fetchSolutionHealthBySolutionComponentId = async (
    solutionComponentId
  ) => {
    try {
      if (solutionComponentId) {
        const response = await solutionHealthService.getBySolutionComponentId(
          solutionComponentId
        );
        const relatedSolutionHealth = response[0];
        if (relatedSolutionHealth) {
          setActiveSolutionHealthId(response[0]["solution_health_id"]);
          setComponentHealth(response[0]["health_name"]);
          setHealthChangeReason(response[0]["change_reason"]);
          return;
        }
        setComponentHealth(defaultComponentHealth);
        setHealthChangeReason(defaultHealthChangeReason);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const changeActiveComponent = async (components) => {
    if (activeComponentId) {
      const item = components.find((x) => x.component_id === activeComponentId);
      if (item) {
        setBehaveType("config");
        setComponentName(item.component_name);
        setComponentKey(item.component_key);
        setComponentDescription(item.component_description);
        setComponentType(item.component_type);
        setRelatedTechLicense(item.license_id);
        await fetchSolutionHealthBySolutionComponentId(item.component_id);
      }
    }
  };

  const handleNewComponent = () => {
    setError("");
    setStatus("");
    setBehaveType("new");
    setActiveComponentId("");
    setActiveSolutionHealthId("");
    setComponentName("");
    setComponentKey("");
    setComponentHealth(defaultComponentHealth);
    setComponentType(defaultComponentType);
    setComponentDescription("");
    setHealthChangeReason(defaultHealthChangeReason);
    setRelatedTechLicense("");
    setUserComponentName("");
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    setIsRequiredHealthChangeReason(false);
    setDeleteBtnIsDisabled(true);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (componentHealth !== defaultComponentHealth) {
      setIsRequiredHealthChangeReason(true);
    }
    if (activeComponentId) {
      setDeleteBtnIsDisabled(false);
    }
    if (!updateSolutionHealthItem) {
      changeActiveComponent(components);
    }
  }, [error, componentHealth, activeComponentId, activeSolutionHealthId]);

  return (
    <>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="componentName">
                      <Form.Label>Component Name</Form.Label>
                      {componentsWithoutSolution.length > 0 ? (
                        <Dropdown
                          as={ButtonGroup}
                          className="border border-dark rounded pt-2 pb-2 pe-2 ps-2 w-100">
                          <Dropdown.Toggle
                            id="dropdown-custom-components"
                            as={CustomToggle}>
                            {componentName
                              ? `${componentName.substring(0, 12)}...`
                              : ""}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Row>
                              <Col md={9}>
                                <Form.Control
                                  id="userComponentName"
                                  type="text"
                                  placeholder="Enter new component name..."
                                  value={userComponentName || ""}
                                  className="ms-3"
                                  onChange={(e) => {
                                    setError("");
                                    setStatus("");
                                    setUserComponentName(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col md={3}>
                                <Button
                                  disabled={userComponentName ? false : true}
                                  onClick={() => {
                                    handleNewComponent();
                                    setComponentName(userComponentName);
                                    setUserComponentName("");
                                  }}
                                  className="text-center pb-2 pt-2 ps-2 pe-2">
                                  Add
                                </Button>
                              </Col>
                            </Row>
                            <Dropdown.Divider />
                            <Dropdown.Header>
                              Or update existed solution component item:
                            </Dropdown.Header>
                            <Dropdown.Divider />
                            {componentsWithoutSolution.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  key={item.component_id}
                                  active={componentName === item.component_name}
                                  onClick={() => {
                                    setError("");
                                    setStatus("");
                                    setActiveComponentId(item.component_id);
                                    changeActiveComponent(
                                      componentsWithoutSolution
                                    );
                                  }}>
                                  {item.component_name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <Form.Control
                          type="text"
                          maxLength={50}
                          value={componentName || ""}
                          onChange={(e) => {
                            setError("");
                            setStatus("");
                            setComponentName(e.target.value);
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group id="componentKey">
                      <Form.Label>Component Key</Form.Label>
                      <Form.Control
                        type="text"
                        value={componentKey || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setComponentKey(e.target.value);
                        }}></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        value={componentDescription || ""}
                        rows={4}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setComponentDescription(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="componentHealth">
                      <Form.Label>Component Health</Form.Label>
                      <Form.Control
                        as="select"
                        value={componentHealth || defaultComponentHealth}
                        className="mb-0"
                        onChange={(e) => {
                          setHealthChangeReason("");
                          setError("");
                          setStatus("");
                          setComponentHealth(e.target.value);
                          setIsRequiredHealthChangeReason(true);
                          setUpdateSolutionHealthItem(true);
                        }}>
                        {componentHealthOptions.map((item, i) => {
                          return (
                            <option key={`componentHealth-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="healthChangeReason">
                      <Form.Label>Health Change Reason</Form.Label>
                      <Form.Control
                        required={isRequiredHealthChangeReason}
                        value={healthChangeReason || ""}
                        type="text"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setHealthChangeReason(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="componentType">
                      <Form.Label>Component Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={componentType || defaultComponentType}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setComponentType(e.target.value);
                        }}>
                        {componentTypeOptions.map((item, i) => {
                          return (
                            <option key={`componentType-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="relatedTechLicense">
                      <Form.Label>Related Tech License</Form.Label>
                      <Form.Control
                        as="select"
                        value={relatedTechLicense || ""}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setRelatedTechLicense(e.target.value);
                        }}>
                        <option value=""></option>
                        {licenses.map((license, i) => {
                          return (
                            <option
                              key={`relatedLicence-${i}`}
                              value={license.license_id}>
                              {license.license_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <SolutionRelatedTable
                  type="component"
                  components={components}
                  activeId={activeComponentId}
                  changeActiveId={(e) => setActiveComponentId(e)}
                  createNew={(e) => handleNewComponent()}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button
                  disabled={saveBtnIsDisabled}
                  variant="primary"
                  type="submit"
                  onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col md={3}>
                <h6 style={{ color: statusColor }}>{saveStatus}</h6>
              </Col>
              <Col md={2}>
                <Button
                  disabled={deleteBtnIsDisabled}
                  variant="primary"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
