import { useState } from "react";
import {
  Card,
  Image,
  Button,
  Dropdown,
  Tooltip,
  FormCheck,
  ButtonGroup,
  OverlayTrigger,
} from "react-bootstrap";
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { ChangeAdminRightsModal } from "./ChangeAdminRightsModal";
import { EditUserModal } from "./EditUserModal";
import { ResetPasswordModal } from "./ResetPasswordModal";
import { useTypedSelector } from "hooks";
import { WorkspaceAssignmentService } from "services";
import { handleError, getFirstLetterOfEachWord } from "utils";

const workspaceAssignmentService = new WorkspaceAssignmentService();

export const UserTableRow = (props) => {
  const history = useHistory();
  // ids
  const activeWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  const adminWorkspaceAssignments = useTypedSelector(
    (state) => state.workspaceAssignment.adminWorkspaceAssignments
  );
  const userId = useTypedSelector((state) => state.user.userId);
  // booleans
  const isActive = props.is_active_workspace_assignment;
  const isPending = props.is_pending;
  const isVerified = props.is_verified;
  const isSuspended = props.is_suspended;
  const [userIsAdmin, setUserIsAdmin] = useState(props.is_admin);
  const [changeAdminRightsType, setChangeAdminRightsType] = useState("");
  const [userDetailsModalShow, setUserDetailsModalShow] = useState(false);
  const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
  const [changeAdminRightsModalShow, setChangeAdminRightsModalShow] =
    useState(false);

  const VerifiedIcon = isVerified ? CheckCircleIcon : InformationCircleIcon;

  let status = "";
  let statusVariant = "";
  if (isPending) {
    statusVariant = "purple";
    status = "Pending";
  } else if (isSuspended) {
    statusVariant = "danger";
    status = "Suspended";
  } else if (!isActive) {
    statusVariant = "warning";
    status = "Inactive";
  } else if (isActive) {
    statusVariant = "success";
    status = "Active";
  } else {
    statusVariant = "primary";
  }

  const getWorkspaceAssignment = async ({ queryKey }) => {
    const [_, activeWorkspaceAssignmentId] = queryKey;
    if (activeWorkspaceAssignmentId) {
      const response = await workspaceAssignmentService.get(
        activeWorkspaceAssignmentId
      );
      if (response) {
        props.setIsCurrentWorkspaceAdmin(response.is_admin);
      }
    }
  };

  const workspaceAssignmentQuery = useQuery(
    ["getWorkspaceAssignmentForUserRow", activeWorkspaceAssignmentId],
    getWorkspaceAssignment,
    {
      onError: (e) => handleError(e, history),
    }
  );

  return (
    <tr className="border-bottom">
      <EditUserModal
        show={userDetailsModalShow}
        onHide={() => setUserDetailsModalShow(false)}
        user={props}
        refetch={props.handleUpdate}
      />
      <ResetPasswordModal
        show={resetPasswordModalShow}
        onHide={() => setResetPasswordModalShow(false)}
        userId={props.user_id}
        userEmail={props.email_address}
      />
      <ChangeAdminRightsModal
        show={changeAdminRightsModalShow}
        onHide={() => setChangeAdminRightsModalShow(false)}
        workspaceAssignmentId={props.workspace_assignment_id}
        userEmail={props.email_address}
        type={changeAdminRightsType}
        setUserIsAdmin={setUserIsAdmin}
        setIsCurrentWorkspaceAdmin={props.setIsCurrentWorkspaceAdmin}
      />
      {props.isCurrentWorkspaceAdmin ?
      <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${props.user_id}`}
            checked={props.isSelected}
            onChange={() => props.selectUser(props.user_id)}
          />
          <FormCheck.Label htmlFor={`user-${props.user_id}`} />
        </FormCheck>
      </td> : null}
      <td>
        <Card.Link className="d-flex align-items-center">
          {props.profile_photo_url ? (
            <Image
              src={props.profile_photo_url}
              className="avatar rounded-circle me-3"
            />
          ) : (
            <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
              <span>{`${getFirstLetterOfEachWord(
                props.first_name.split(" ")[0]
              )}${getFirstLetterOfEachWord(props.last_name.split(" ")[0])}`}</span>
            </div>
          )}
          <div className="d-block">
            <span className="fw-bold">
              {props.first_name} {props.last_name}
            </span>
            <div className="small text-gray">{props.email_address}</div>
          </div>
        </Card.Link>
      </td>
      <td>
        <span className="fw-normal">
          {props.time_created_workspace_assignment}
        </span>
      </td>
      <td>
        <span className="fw-normal d-flex align-items-center">
          <VerifiedIcon
            className={`icon icon-xxs text-${statusVariant} me-1`}
          />
          Email
        </span>
      </td>
      <td>
        <span className={`fw-normal text-${statusVariant}`}>{status}</span>
      </td>
      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            as={Button}
            split
            variant="link"
            className="text-dark m-0 p-0">
            <DotsHorizontalIcon className="icon icon-xs" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
            <Dropdown.Item
              className="d-flex align-items-center"
              onClick={() => setUserDetailsModalShow(true)}>
              <EyeIcon className="dropdown-icon text-gray-400 me-2" />
              View Details
            </Dropdown.Item>
            {props.isCurrentWorkspaceAdmin ? (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={() => setResetPasswordModalShow(true)}>
                <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
                Reset Password
              </Dropdown.Item>
            ) : null}
            {props.isCurrentWorkspaceAdmin ? (
              userIsAdmin ? (
                adminWorkspaceAssignments.length > 1 ? (
                  <Dropdown.Item
                    className="d-flex align-items-center"
                    onClick={() => {
                      setChangeAdminRightsType("remove");
                      setChangeAdminRightsModalShow(true);
                    }}>
                    <UserIcon className="dropdown-icon text-gray-400 me-2" />
                    Remove Admin Rights
                  </Dropdown.Item>
                ) : null
              ) : (
                <Dropdown.Item
                  className="d-flex align-items-center"
                  onClick={() => {
                    setChangeAdminRightsType("add");
                    setChangeAdminRightsModalShow(true);
                  }}>
                  <UserIcon className="dropdown-icon text-gray-400 me-2" />
                  Make an Admin
                </Dropdown.Item>
              )
            ) : null}
          </Dropdown.Menu>
        </Dropdown>

        {props.isCurrentWorkspaceAdmin || props.user_id == Number(userId) ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link
              className="ms-2"
              onClick={() => props.deleteUsers([props.user_id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        ) : null}
      </td>
    </tr>
  );
};
