import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  AdditionalBenefitsService,
  QueueBenefitService,
  SolutionService,
  TransactionService,
} from "services";
import { checkEmptyField, handleError } from "utils";

const additionalBenefitsService = new AdditionalBenefitsService();
const queueBenefitService = new QueueBenefitService();
const solutionService = new SolutionService();
const transactionService = new TransactionService();

export const QueueBenefitModal = (props) => {
  const {
    show,
    onHide,
    queueId,
    queueName,
    workspaceId,
    solutionId,
    activeQueueBenefitId,
    changeActiveQueueBenefitId,
    refetch,
  } = props;
  const history = useHistory();
  // notifiers
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  // // fields
  const [additionalBenefitId, setAdditionalBenefitId] = useState("");
  const [additionalBenefitName, setAdditionalBenefitName] = useState("");
  const [additionalBenefitDescription, setAdditionalBenefitDescription] =
    useState("");
  const [additionalBenefitEstValue, setAdditionalBenefitEstValue] =
    useState("");
  const [queueBenefitEstVolume, setQueueBenefitEstVolume] = useState("");
  const [queueBenefitPerTransactions, setQueueBenefitPerTransactions] =
    useState("");
  const [actualBenefitLTD, setActualBenefitLTD] = useState("");
  const [actualBenefitCategory, setActualBenefitCategory] = useState("");
  const [additionalBenefitEstValueFromDb, setAdditionalBenefitEstValueFromDb] =
    useState("");
  // lists
  const [additionalBenefits, setAdditionalBenefits] = useState([]);
  // booleans
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [saveBtnIsShown, setSaveBtnIsShown] = useState(true);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(true);

  const getActiveQueueBenefit = async () => {
    try {
      const response = await queueBenefitService.get(activeQueueBenefitId);
      if (response) {
        const additionalBenefitId = Number(response.additional_benefit_id);
        setAdditionalBenefitId(additionalBenefitId);
        setAdditionalBenefitName(additionalBenefitId);
        setQueueBenefitEstVolume(response.est_volume_per_annum);
        const benefit = additionalBenefits.find(
          (item) => item.additional_benefit_id === additionalBenefitId
        );
        setAdditionalBenefitDescription(benefit.benefit_description);
        setAdditionalBenefitEstValue(benefit.benefit_value);
        setAdditionalBenefitEstValueFromDb(benefit.benefit_value);
        setActualBenefitCategory(benefit.benefit_category);
        const benefitPerTransaction =
          await queueBenefitService.countBenefitPerTransaction(
            queueId,
            additionalBenefitId
          );
        setQueueBenefitPerTransactions(benefitPerTransaction);
        const successfulTransactionsNumber =
          await transactionService.countByQueueAndStatus(queueId, "Complete");
        const actualBenefit =
          benefitPerTransaction * successfulTransactionsNumber;
        setActualBenefitLTD(actualBenefit);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleChangeAdditionalBenefit = async (e) => {
    setError("");
    setStatus("");
    const additionalBenefitId = Number(e.target.value);
    setAdditionalBenefitId(additionalBenefitId);
    setAdditionalBenefitName(additionalBenefitId);
    const benefit = additionalBenefits.find(
      (item) => item.additional_benefit_id === additionalBenefitId
    );
    setAdditionalBenefitDescription(benefit.benefit_description);
    setAdditionalBenefitEstValue(benefit.benefit_value);
    setActualBenefitCategory(benefit.benefit_category);
    const benefitPerTransaction =
      await queueBenefitService.countBenefitPerTransaction(
        queueId,
        additionalBenefitId
      );
    setQueueBenefitPerTransactions(benefitPerTransaction);
    const estVolumePerAnnum = await queueBenefitService.countEstVolumePerAnnum(
      queueId
    );
    setQueueBenefitEstVolume(estVolumePerAnnum);
    const successfulTransactionsNumber =
      await transactionService.countByQueueAndStatus(queueId, "Complete");
    const actualBenefit = benefitPerTransaction * successfulTransactionsNumber;
    setActualBenefitLTD(actualBenefit);
  };

  const fetchAdditionalBenefits = async () => {
    try {
      if (workspaceId) {
        const response = await additionalBenefitsService.getAllByWorkspace(
          workspaceId
        );
        setAdditionalBenefits(response);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
  };

  const checkFields = () => {
    const benefitNameIsField = checkEmptyField(
      additionalBenefitName,
      "Benefit Name",
      setError
    );
    if (benefitNameIsField === "OK") {
      return true;
    }
  };

  const updateSolutionEstAnnualBenefitField = async (value, type) => {
    try {
      const data = {
        value: value,
        type: type,
      };
      return await solutionService.updateEstAnnualBenefit(solutionId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      try {
        const data = {
          benefitPerTransaction: Number(queueBenefitPerTransactions),
          estVolumePerAnnum: Number(queueBenefitEstVolume),
          additionalBenefitId: additionalBenefitId,
          queueId: queueId,
          workspaceId: workspaceId,
        };
        const queueBenefitId = await queueBenefitService.create(data);
        if (queueBenefitId) {
          await updateSolutionEstAnnualBenefitField(
            additionalBenefitEstValue,
            "add"
          );
          handleHideModal();
        }
      } catch (e) {
        failedDuringSaving(e.message);
        handleError(e, history);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      try {
        const data = {
          benefit_per_transaction: Number(queueBenefitPerTransactions),
          est_volume_per_annum: Number(queueBenefitEstVolume),
          additional_benefit_id: additionalBenefitId,
          queue_id: queueId,
          workspace_id: workspaceId,
        };
        const response = await queueBenefitService.update(
          activeQueueBenefitId,
          data
        );
        if (response) {
          const benefitValueBefore = Number(additionalBenefitEstValueFromDb);
          const benefitValueAfter = Number(additionalBenefitEstValue);
          if (benefitValueBefore > benefitValueAfter) {
            const reduceValue = benefitValueBefore - benefitValueAfter;
            await updateSolutionEstAnnualBenefitField(reduceValue, "remove");
          }
          if (benefitValueBefore < benefitValueAfter) {
            const increaseValue = benefitValueAfter - benefitValueBefore;
            await updateSolutionEstAnnualBenefitField(increaseValue, "add");
          }
          handleHideModal();
        }
      } catch (e) {
        failedDuringSaving(e.message);
        handleError(e, history);
      }
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await queueBenefitService.delete(activeQueueBenefitId);
      await updateSolutionEstAnnualBenefitField(
        additionalBenefitEstValue,
        "remove"
      );
      handleHideModal();
    } catch (e) {
      if (e) setError(e);
      setStatus("Failure occurred when deleting");
      handleError(e, history);
    }
  };

  const handleHideModal = () => {
    onHide(true);
    refetch();
    changeActiveQueueBenefitId("");
    setError("");
    setStatus("");
    setAdditionalBenefitId("");
    setAdditionalBenefitName("");
    setAdditionalBenefitDescription("");
    setAdditionalBenefitEstValue("");
    setAdditionalBenefitEstValueFromDb("");
    setQueueBenefitEstVolume("");
    setQueueBenefitPerTransactions("");
    setActualBenefitLTD("");
    setActualBenefitCategory("");
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    setSaveBtnIsShown(true);
    setDeleteBtnIsDisabled(true);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (activeQueueBenefitId) {
      setSaveBtnIsShown(false);
      setDeleteBtnIsDisabled(false);
      getActiveQueueBenefit();
    }
    fetchAdditionalBenefits();
  }, [error, activeQueueBenefitId]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleHideModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {error ? (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        ) : null}
        {status ? (
          <Alert key="danger" variant="danger">
            {status}
          </Alert>
        ) : null}
        <Row>
          <Col md={3} className="mt-1">
            <Form.Label>Queue Name</Form.Label>
          </Col>
          <Col md={9} className="mb-3">
            <Form.Group id="queueName">
              <Form.Control readOnly type="text" value={queueName || ""} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="mb-3">
            <Form.Group id="additionalBenefitId">
              <Form.Label>Benefit ID</Form.Label>
              <Form.Control
                readOnly
                type="text"
                defaultValue={additionalBenefitId || ""}
              />
            </Form.Group>
            <Form.Group id="additionalBenefitName" className="mt-2">
              <Form.Label>Benefit Name</Form.Label>
              <Form.Control
                as="select"
                value={additionalBenefitName || ""}
                onChange={handleChangeAdditionalBenefit}>
                <option value=""></option>
                {additionalBenefits.map((additionalBenefit, i) => {
                  return (
                    <option
                      key={`additionalBenefit-${i}`}
                      value={additionalBenefit.additional_benefit_id}>
                      {additionalBenefit.benefit_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={7} className="mb-3">
            <Form.Group id="additionalBenefitDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                readOnly
                as="textarea"
                rows={5}
                defaultValue={additionalBenefitDescription || ""}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <Form.Group id="additionalBenefitEstValue">
              <Form.Label>Est. Benefit (p.a.)</Form.Label>
              <Form.Control
                readOnly
                type="text"
                value={`$${additionalBenefitEstValue}` || "$"}
              />
            </Form.Group>
          </Col>
          <Col md={4} className="mb-3">
            <Form.Group id="queueBenefitEstVolume">
              <Form.Label>Est. Volume (p.a.)</Form.Label>
              <Form.Control
                readOnly
                type="number"
                value={queueBenefitEstVolume || ""}
              />
            </Form.Group>
          </Col>
          <Col md={4} className="mb-3">
            <Form.Group id="queueBenefitPerTransactions">
              <Form.Label>Benefit Per Trans.</Form.Label>
              <Form.Control
                readOnly
                type="number"
                value={
                  queueBenefitPerTransactions
                    ? Number(queueBenefitPerTransactions).toFixed(2)
                    : ""
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <Form.Group id="actualBenefit">
              <Form.Label>Act. Benefit (LTD)</Form.Label>
              <Form.Control
                readOnly
                type="text"
                value={
                  actualBenefitLTD ? `$${actualBenefitLTD.toFixed(2)}` : "$"
                }
              />
            </Form.Group>
          </Col>
          <Col md={8} className="mb-3">
            <Form.Group id="actualBenefitCategory">
              <Form.Label>Benefit Category</Form.Label>
              <Form.Control
                readOnly
                type="text"
                value={actualBenefitCategory || ""}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          {saveBtnIsShown ? (
            <Col md={6}>
              <Button
                disabled={saveBtnIsDisabled}
                variant="primary"
                type="submit"
                onClick={handleSave}>
                Save
              </Button>
            </Col>
          ) : null}
          {!saveBtnIsShown ? (
            <Col md={6}>
              <Button variant="primary" type="submit" onClick={handleUpdate}>
                Update
              </Button>
            </Col>
          ) : null}
          {activeQueueBenefitId ? (
            <Col md={6}>
              <Button
                disabled={deleteBtnIsDisabled}
                variant="primary"
                onClick={handleDelete}>
                Delete
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
