import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAction } from "hooks";
import { App } from "./App";
import { Loading } from "./components";
import { WorkspaceService } from "services";
import { handleError, removeGapAndConvertToLowerCase } from "utils";

const workspaceService = new WorkspaceService();

export const LoadApp = () => {
  const history = useHistory();
  const [isDefaultWorkspace, setIsDefaultWorkspace] = useState(false);
  const {
    updateActiveWorkspaceIdAction,
    updateActiveWorkspaceNameAction,
    updateDefaultWorkspaceIdAction,
    updateDefaultWorkspaceNameAction,
  } = useAction();

  const fetchDefaultWorkspaceData = async () => {
    try {
      const response = await workspaceService.getDefault();
      if (response) {
        const defaultWorkspaceName = removeGapAndConvertToLowerCase(
          response.workspace_name
        );
        updateActiveWorkspaceIdAction(response.workspace_id);
        updateDefaultWorkspaceIdAction(response.workspace_id);
        updateActiveWorkspaceNameAction(defaultWorkspaceName);
        updateDefaultWorkspaceNameAction(defaultWorkspaceName);
        setIsDefaultWorkspace(true);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  useEffect(() => {
    fetchDefaultWorkspaceData();
  }, []);

  return (
    <>
      {!isDefaultWorkspace ? (
        <Loading marginTop="25%" marginLeft="50%" />
      ) : (
        <App />
      )}
    </>
  );
};
