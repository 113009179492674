import ActionTypes from "../actions/ActionTypes";

const initialState = {
  technologiesTab: false,
  technologyConfigTab: false,
  technologyNewTab: false,
};

export const tabReducer = (
  state = initialState,
  action 
) => {
  switch (action.type) {
    case ActionTypes.TECHNOLOGIES_TAB:
        return {
            ...state,
            technologiesTab: true,
            technologyConfigTab: false,
            technologyNewTab: false,
        };
    case ActionTypes.TECHNOLOGY_CONFIG_TAB:
        return {
            ...state,
            technologiesTab: false,
            technologyConfigTab: true,
            technologyNewTab: false,
        };
    case ActionTypes.TECHNOLOGY_NEW_TAB:
        return {
            ...state,
            technologiesTab: false,
            technologyConfigTab: false,
            technologyNewTab: true,
        };
    case ActionTypes.RESET_TECHNOLOGY_TABS:
      return {
        ...state,
        technologiesTab: false,
        technologyConfigTab: false,
        technologyNewTab: false,
      };
    default:
      return state;
  }
};
