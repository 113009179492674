import ActionTypes from "./ActionTypes";
import { WorkspaceAssignmentService } from "../../services";

const workspaceAssignmentService = new WorkspaceAssignmentService();

export const updateCurrentWorkspaceAssignmentIdAction = (payload) => ({
  type: ActionTypes.CURRENT_WORKSPACE_ASSIGNMENT,
  payload,
});

export const changeWorkspaceAssignmentAction = (userId, activeWorkspaceId) => {
  return async (dispatch) => {
    try {
      // find workspace assignment by activeWorkspaceId and userId
      const workspaceAssignments =
        await workspaceAssignmentService.getAllByWorkspaceAndUser(
          activeWorkspaceId,
          userId
        );
      if (workspaceAssignments.length > 0) {
        const firstWorkspaceAssignment = workspaceAssignments[0];
        if (
          firstWorkspaceAssignment.workspace_id === Number(activeWorkspaceId)
        ) {
          const newWAId = firstWorkspaceAssignment.workspace_assignment_id;
          dispatch({
            type: ActionTypes.CURRENT_WORKSPACE_ASSIGNMENT,
            payload: newWAId,
          });
          localStorage.setItem("workspace_assignment_id", newWAId);

          // update workspace assignment is_active field to true
          const data = {
            is_active: true,
          };
          await workspaceAssignmentService.update(newWAId, data);
        }
      }
    } catch (error) {
      console.error("Change workspace action error: ", error);
    }
  };
};

export const resetWorkspaceAssignmentAction = (
  userId,
  activeWorkspaceId,
  activeWorkspaceAssignmentId,
  type
) => {
  return async (dispatch) => {
    try {
      let data = {};
      // update previous workspace assignment is_active field to false
      data = {
        is_active: "false",
      };
      await workspaceAssignmentService.update(
        activeWorkspaceAssignmentId,
        data
      );
      if (activeWorkspaceId) {
        // find workspace assignment by activeWorkspaceId and userId
        const workspaceAssignments =
          await workspaceAssignmentService.getAllByWorkspaceAndUser(
            activeWorkspaceId,
            userId
          );
        if (workspaceAssignments.length > 0) {
          const firstWorkspaceAssignment = workspaceAssignments[0];
          if (
            firstWorkspaceAssignment.workspace_id === Number(activeWorkspaceId)
          ) {
            const newWAId = firstWorkspaceAssignment.workspace_assignment_id;
            dispatch({
              type: ActionTypes.CURRENT_WORKSPACE_ASSIGNMENT,
              payload: newWAId,
            });
            localStorage.setItem("workspace_assignment_id", newWAId);

            if (type !== "logout") {
              // update workspace assignment is_active field to true
              data = {
                is_active: true,
              };
              await workspaceAssignmentService.update(newWAId, data);
            }
          }
        }
      }
    } catch (error) {
      console.error("Change workspace action error: ", error);
    }
  };
};

export const addAdminWorkspaceAssignmentAction = (payload) => ({
  type: ActionTypes.ADD_ADMIN_WORKSPACE_ASSIGNMENT,
  payload,
});

export const removeAdminWorkspaceAssignmentAction = (payload) => ({
  type: ActionTypes.REMOVE_ADMIN_WORKSPACE_ASSIGNMENT,
  payload,
});

export const resetAdminWorkspaceAssignmentsAction = () => ({
  type: ActionTypes.RESET_ADMIN_WORKSPACE_ASSIGNMENTS,
});
