import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useTypedSelector } from "hooks";
import { TechnologyService, WorkspaceService } from "services";
import { handleError } from "utils";

const technologyService = new TechnologyService();
const workspaceService = new WorkspaceService();

const getWorkspace = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await workspaceService.get(workspaceId);
  }
};

const getTechnology = async ({ queryKey }) => {
  const [_, activeTechnologyId] = queryKey;
  if (activeTechnologyId) {
    return await technologyService.get(activeTechnologyId);
  }
};

export const WorkspaceHeader = (props) => {
  const { type, workspaceId } = props;
  const history = useHistory();
  const subHeader = type === "new" ? type.toUpperCase() : "";
  const technologiesTab = useTypedSelector(
    (state) => state.tab.technologiesTab
  );
  const technologyConfigTab = useTypedSelector(
    (state) => state.tab.technologyConfigTab
  );
  const technologyNewTab = useTypedSelector(
    (state) => state.tab.technologyNewTab
  );
  const activeTechnologyId = useTypedSelector(
    (state) => state.technology.activeTechnologyId
  );
  const techTabIsOpened =
    technologiesTab || technologyConfigTab || technologyNewTab ? true : false;

  const { data } = useQuery(
    ["getWorkspaceDataForHeader", workspaceId],
    getWorkspace,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const technologyQuery = useQuery(
    ["geTechnologyDataForHeader", activeTechnologyId],
    getTechnology,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const techName = technologyQuery.data ? technologyQuery.data.tech_name : "";

  return (
    <>
      <div className="py-4">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item>
            <HomeIcon className="icon icon-xs" />
          </Breadcrumb.Item>
          <Breadcrumb.Item>Workspace</Breadcrumb.Item>
          {data?.workspace_name ? (
            <Breadcrumb.Item>{data.workspace_name}</Breadcrumb.Item>
          ) : null}
          {subHeader ? (
            <Breadcrumb.Item active>{subHeader}</Breadcrumb.Item>
          ) : null}
          {techTabIsOpened ? <Breadcrumb.Item>Technology</Breadcrumb.Item> : null}
          {techName && techTabIsOpened ? <Breadcrumb.Item>{techName}</Breadcrumb.Item> : null}
        </Breadcrumb>
      </div>
    </>
  );
};
