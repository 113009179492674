import { AuthService } from "../services";
import { Routes } from "routes";
import { cleanStorage } from "utils";

const apiAuthService = new AuthService();

export const handleError = async (error, history) => {
  if (error.message.toString() === "Error: Logout") {
    const response = await apiAuthService.logout();
    if (response === "OK") {
      cleanStorage();
      history.push(Routes.SignIn.path);
    }
    return;
  }
  console.error(error.message.toString());
};
