import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  CheckIcon,
  CogIcon,
  HomeIcon,
  PlusIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { AddUserModal } from "./components";
import { UsersTable } from "./table/UsersTable";
import { useAction, useTypedSelector } from "hooks";
import {
  UserService,
  WorkspaceAssignmentService,
  WorkspaceService,
} from "services";
import { cleanStorage, handleError } from "utils";
import { Routes } from "routes";

const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();
const workspaceService = new WorkspaceService();

const SwalWithBootstrapButtons = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  })
);

const all = "all";
const numberOfRows = [5, 10, 20];

export const Users = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(numberOfRows[0]);
  // ids
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const activeWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  const defaultWorkspaceId = useTypedSelector(
    (state) => state.workspace.defaultWorkspaceId
  );
  const userId = useTypedSelector((state) => state.user.userId);
  // lists
  const users = useTypedSelector((state) => state.user.allUsersByWorkspace);
  const selectedUsersIds = users
    .filter((u) => u.isSelected)
    .map((u) => u.user_id);
  const adminWorkspaceAssignments = useTypedSelector(
    (state) => state.workspaceAssignment.adminWorkspaceAssignments
  );
  // strings/numbers
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState(all);
  const defaultWorkspaceName = useTypedSelector(
    (state) => state.workspace.defaultWorkspaceName
  );
  // booleans
  const [modalShow, setModalShow] = useState(false);
  const [isCurrentWorkspaceAdmin, setIsCurrentWorkspaceAdmin] = useState(false);
  const totalUsers = users.length;
  const allSelected = selectedUsersIds.length === totalUsers;

  const {
    updateUsersByWorkspace,
    addAdminWorkspaceAssignmentAction,
    removeAdminWorkspaceAssignmentAction,
    resetAdminWorkspaceAssignmentsAction,
    updateActiveWorkspaceIdAction,
    updateActiveWorkspaceNameAction,
    updateDefaultWorkspaceIdAction,
    updateDefaultWorkspaceNameAction,
    changeWorkspaceAssignmentAction,
    removeWorkspaceAction,
    updateCurrentWorkspaceAssignmentIdAction,
    updateUserIdAction,
    uploadWorkspacesAction,
    logoutSuccessAction,
  } = useAction();

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newUsers = users.map((u) => ({
      ...u,
      show:
        u.first_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
        u.last_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
        u.email_address.toLowerCase().includes(newSearchValue.toLowerCase()),
    }));
    setSearchValue(newSearchValue);
    updateUsersByWorkspace(newUsers);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    let newUsers = [];
    switch (newStatusFilter) {
      case "active":
        newUsers = users.map((u) => ({
          ...u,
          show: u.is_active_workspace_assignment,
        }));
        break;
      case "inactive":
        newUsers = users.map((u) => ({
          ...u,
          show: !u.is_active_workspace_assignment,
        }));
        break;
      case "pending":
        newUsers = users.map((u) => ({ ...u, show: u.is_pending }));
        break;
      case "suspended":
        newUsers = users.map((u) => ({ ...u, show: u.is_suspended }));
        break;
      default:
        newUsers = users.map((u) => ({ ...u, show: true }));
        break;
    }
    setStatusFilter(newStatusFilter);
    updateUsersByWorkspace(newUsers);
  };

  const selectAllUsers = () => {
    const newUsers =
      selectedUsersIds.length === totalUsers
        ? users.map((u) => ({ ...u, isSelected: false }))
        : users.map((u) => ({ ...u, isSelected: true }));
    updateUsersByWorkspace(newUsers);
  };

  const selectUser = (id) => {
    const newUsers = users.map((u) =>
      u.user_id === id ? { ...u, isSelected: !u.isSelected } : u
    );
    updateUsersByWorkspace(newUsers);
  };

  const deleteWorkspaceAssignmentById = async (id) => {
    try {
      return await workspaceAssignmentService.delete(id);
    } catch (e) {
      handleError(e, history);
    }
  };

  const deleteUser = async (userToDeleteId) => {
    try {
      // get data about user
      const userData = users.filter((item) => item.user_id === userToDeleteId);
      const currentWorkspaceAssignmentId = userData[0].workspace_assignment_id;
      // check if user is only one admin for this WA
      if (
        adminWorkspaceAssignments.length <= 1 &&
        adminWorkspaceAssignments[0] == currentWorkspaceAssignmentId
      ) {
        return "ADMIN";
      }
      // get list of workspace_assignments related to this user
      const userWorkspaceAssignments =
        await workspaceAssignmentService.getAllByUser(userToDeleteId);
      if (userWorkspaceAssignments.length > 1) {
        // check if current workspace is default for user
        const currentUserWorkspace = userData[0].active_workspace_id;
        if (Number(defaultWorkspaceId) == currentUserWorkspace) {
          // if workspace is default user workspace than delete workspace assignment with
          // this workspace and make the next workspace in the list default for this user

          // remove current WA from list of user workspace assignments
          const index = userWorkspaceAssignments.findIndex(
            (obj) =>
              obj["workspace_assignment_id"] == currentWorkspaceAssignmentId
          );
          const updatedWorkspaceAssignments =
            index >= 0
              ? [
                  ...userWorkspaceAssignments.slice(0, index),
                  ...userWorkspaceAssignments.slice(index + 1),
                ]
              : userWorkspaceAssignments;

          // find the next default workspace
          let nextDefaultWorkspaceName = "";
          const nextDefaultWorkspaceId =
            updatedWorkspaceAssignments[0].workspace_id;
          const nextDefaultWorkspaceData = await workspaceService.get(
            nextDefaultWorkspaceId
          );
          if (nextDefaultWorkspaceData) {
            nextDefaultWorkspaceName = nextDefaultWorkspaceData.workspace_name;
          }
          // delete current workspace assignment
          const waResponse = await deleteWorkspaceAssignmentById(
            currentWorkspaceAssignmentId
          );
          if (waResponse) {
            removeAdminWorkspaceAssignmentAction(
              Number(currentWorkspaceAssignmentId)
            );
            // update user workspace id value in db
            const data = { workspace_id: nextDefaultWorkspaceId };
            const updateUserResponse = await userService.update(
              userToDeleteId,
              data
            );
            if (updateUserResponse) {
              updateActiveWorkspaceIdAction(nextDefaultWorkspaceId);
              updateDefaultWorkspaceIdAction(nextDefaultWorkspaceId);
              updateActiveWorkspaceNameAction(nextDefaultWorkspaceName);
              updateDefaultWorkspaceNameAction(nextDefaultWorkspaceName);
              changeWorkspaceAssignmentAction(
                userToDeleteId,
                nextDefaultWorkspaceId
              );
              removeWorkspaceAction(userData[0].active_workspace_id);
              return "OK";
            }
          }
        } else {
          // delete current workspace assignment
          const waResponse = await deleteWorkspaceAssignmentById(
            currentWorkspaceAssignmentId
          );
          if (waResponse) {
            removeAdminWorkspaceAssignmentAction(
              Number(currentWorkspaceAssignmentId)
            );
            if (userId == userToDeleteId) {
              updateActiveWorkspaceIdAction(defaultWorkspaceId);
              updateActiveWorkspaceNameAction(defaultWorkspaceName);
              changeWorkspaceAssignmentAction(
                userToDeleteId,
                defaultWorkspaceId
              );
              removeWorkspaceAction(userData[0].active_workspace_id);
            }
            return "OK";
          }
        }
      } else {
        // user is connected only to one workspace

        // delete current WA
        const waResponse = await deleteWorkspaceAssignmentById(
          currentWorkspaceAssignmentId
        );
        if (waResponse) {
          removeAdminWorkspaceAssignmentAction(
            Number(currentWorkspaceAssignmentId)
          );
          // delete user from system
          const deleteUserResponse = await userService.delete(userToDeleteId);
          if (deleteUserResponse) {
            updateCurrentWorkspaceAssignmentIdAction("");
            updateUserIdAction("");
            updateActiveWorkspaceNameAction("");
            updateActiveWorkspaceIdAction("");
            uploadWorkspacesAction(true);
            logoutSuccessAction();
            cleanStorage();
            history.push(Routes.SignIn.path);
            return "OK";
          }
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const makeAdmins = async (ids) => {
    const usersToBeAdmins = ids ? ids : selectedUsersIds;
    if (usersToBeAdmins.length > 0) {
      for (const user of usersToBeAdmins) {
        const userData = users.filter((item) => item.user_id === user);
        const userWAId = userData[0].workspace_assignment_id;
        const adminWA = userData[0].is_admin;
        if (!adminWA) {
          const data = { is_admin: true };
          const response = await workspaceAssignmentService.update(
            userWAId,
            data
          );
          if (response) {
            addAdminWorkspaceAssignmentAction(Number(userWAId));
          }
        }
      }
    }
    refetch();
    await SwalWithBootstrapButtons.fire(
      "Updated",
      "Admin rights have been updated for selected users.",
      "success"
    );
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids ? ids : selectedUsersIds;
    const usersNr = usersToBeDeleted.length;
    const textMessage =
      usersNr === 1
        ? "Are you sure do you want to delete this user?"
        : `Are you sure do you want to delete these ${usersNr} users?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      if (usersNr === 1) {
        const response = await deleteUser(usersToBeDeleted[0]);
        switch (response) {
          case "OK":
            refetch();
            await SwalWithBootstrapButtons.fire(
              "Deleted",
              "The user has been deleted from this workspace.",
              "success"
            );
            break;
          case "ADMIN":
            const confirmMessage =
              "You can not delete this user as they is the only admin of this workspace. If you do want to delete this user, grant admin rights to another user, then try again.";
            await SwalWithBootstrapButtons.fire(
              "Error",
              confirmMessage,
              "error"
            );
            break;
          default:
            await SwalWithBootstrapButtons.fire(
              "Error",
              "Please, try to delete user again.",
              "error"
            );
            break;
        }
      }
      if (usersNr > 1) {
        let successDeletions = 0;
        let isAdmin = "";
        let errorDeletions = [];
        let admins = 0;
        for (const user of usersToBeDeleted) {
          const userData = users.filter((item) => item.user_id === user);
          const userEmail = userData[0].email_address;
          const userAdmin = userData[0].is_admin;
          if (userAdmin) admins++;
          const wAAdminsNumber = adminWorkspaceAssignments.length;
          if (admins === wAAdminsNumber) {
            await SwalWithBootstrapButtons.fire(
              "Error",
              `The last admin user ${userEmail} can not be deleted.`,
              "error"
            );
            return;
          }
          const response = await deleteUser(user);
          if (response === "OK") successDeletions++;
          if (response === "ADMIN") isAdmin = userEmail;
          if (response !== "OK" && response !== "ADMIN") {
            errorDeletions.push(userEmail);
          }
        }
        refetch();
        if (successDeletions === usersNr) {
          // if all selected users are deleted
          await SwalWithBootstrapButtons.fire(
            "Deleted",
            "The users has been deleted from this workspace.",
            "success"
          );
          return;
        }
        if (isAdmin) {
          // if tried to delete main admin
          const confirmMessage = `You can not delete user ${isAdmin} as they is the only admin of this workspace. If you do want to delete this user, grant admin rights to another user, then try again.`;
          await SwalWithBootstrapButtons.fire("Error", confirmMessage, "error");
        }
        if (errorDeletions.length > 0) {
          await SwalWithBootstrapButtons.fire(
            "Error",
            `Error occured during deleting such users: ${errorDeletions}. Please, try to delete user again.`,
            "error"
          );
        }
      }
    }
  };

  const paginateUserWorkspaceAssignments = async ({ queryKey }) => {
    const [_, activeWorkspaceId, page, perPage] = queryKey;
    updateUsersByWorkspace([]);
    resetAdminWorkspaceAssignmentsAction();
    const data = await workspaceAssignmentService.paginateByWorkspace(
      activeWorkspaceId,
      page,
      perPage
    );
    const { items, total } = data;
    let usersData = [];
    if (items.length > 0) {
      for (const workspaceAssignment of items) {
        if (
          workspaceAssignment.workspace_assignment_id ===
          activeWorkspaceAssignmentId
        ) {
          setIsCurrentWorkspaceAdmin(workspaceAssignment.is_admin);
        }
        const user = await userService.get(workspaceAssignment.user_id);
        const updatedUserData = {
          ...user,
          isSelected: false,
          show: true,
          business_unit_id: workspaceAssignment.business_unit_id,
          is_active_workspace_assignment: workspaceAssignment.is_active,
          is_admin: workspaceAssignment.is_admin,
          is_pending: workspaceAssignment.is_pending,
          is_suspended: workspaceAssignment.is_suspended,
          is_verified: workspaceAssignment.is_verified,
          org_role: workspaceAssignment.org_role,
          time_created_workspace_assignment: workspaceAssignment.time_created,
          workspace_assignment_id: workspaceAssignment.workspace_assignment_id,
          active_workspace_id: workspaceAssignment.workspace_id,
        };
        if (workspaceAssignment.is_admin == true) {
          addAdminWorkspaceAssignmentAction(
            Number(workspaceAssignment.workspace_assignment_id)
          );
        }
        usersData.push(updatedUserData);
      }
      updateUsersByWorkspace(usersData);
      const data = { usersData: usersData, total: total };
      return data;
    }
  };

  const paginationUserWorkspaceAssignmentsQuery = useQuery(
    ["workspaceassignments", activeWorkspaceId, page, perPage],
    paginateUserWorkspaceAssignments,
    { onError: (e) => handleError(e, history) }
  );

  const refetch = () => {
    paginationUserWorkspaceAssignmentsQuery.refetch();
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Users</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Users</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <AddUserModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          refetch={refetch}
        />
        {isCurrentWorkspaceAdmin ? (
          <div className="btn-toolbar mb-2 mb-md-0">
            <Button
              variant="primary"
              size="sm"
              className="d-inline-flex align-items-center"
              onClick={() => setModalShow(true)}>
              <PlusIcon className="icon icon-xs me-2" /> New User
            </Button>
          </div>
        ) : null}
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search users"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select
              value={statusFilter}
              className="fmxw-200 d-none d-md-inline"
              onChange={changeStatusFilter}>
              <option value={all}>All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="pending">Pending</option>
              <option value="suspended">Suspended</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle
                  split
                  as={Button}
                  variant="link"
                  className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">Show</span>
                  {numberOfRows.map((number, i) => {
                    return (
                      <Dropdown.Item
                        key={`row-${i}`}
                        className="fw-bold"
                        eventKey={number}
                        onClick={() => setPerPage(number)}>
                        {number}
                        {number == perPage ? (
                          <CheckIcon className="icon icon-xxs ms-auto" />
                        ) : null}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
      <UsersTable
        users={users.filter((u) => u.show)}
        allSelected={allSelected}
        selectUser={selectUser}
        deleteUsers={deleteUsers}
        makeAdmins={makeAdmins}
        selectAllUsers={selectAllUsers}
        isCurrentWorkspaceAdmin={isCurrentWorkspaceAdmin}
        setIsCurrentWorkspaceAdmin={setIsCurrentWorkspaceAdmin}
        page={page}
        perPage={perPage}
        paginationUserWorkspaceAssignmentsQuery={
          paginationUserWorkspaceAssignmentsQuery
        }
        changePage={setPage}
        refetch={refetch}
      />
    </>
  );
};
