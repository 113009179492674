import { v4 as uuidv4 } from "uuid";

export default [
    {
        "id": uuidv4(),
        "priceMonthly": 0,
        "priceYearly": 0,
        "variant": "secondary",
        "title": "Free",
        "btnText": "Start for Free",
        "description": "Used by Developers or small businesses for experimentation",
        "features": [
            "Access to support portal",
            "Unlimited Ideation submissions",
            "3 prioritised initiatives on backlog dashboard",
            "Unlimited innovation platform connections",
            "3 innovation solution API endpoints",
            "3 solutions visible on innovation dashboards",
            "3 Gigabytes of total storage"            
        ]
    },
    {
        "id": uuidv4(),
        "priceMonthly": 120,
        "priceYearly": 108,
        "variant": "secondary",
        "title": "Standard",
        "btnText": "Go to Standard",
        "description": "Appropriate for small to medium businesses",
        "features": [
            "Access to support portal with 24 hour SLA’s",
            "Unlimited Ideation submissions",
            "10 prioritised initiatives on backlog dashboard",
            "Unlimited innovation platform connections",
            "10 innovation solution API endpoints",
            "10 solutions visible on innovation dashboards",
            "10 Gigabytes of total storage"            
        ]
    },
    {
        "id": uuidv4(),
        "priceMonthly": 450,
        "priceYearly": 405,
        "title": "Premium",
        "btnText": "Start with Premium",
        "btnTextColor": "dark",
        "description": "Designed for medium to large businesses",
        "features": [
            "Approval workflow enabled for innovative ideas",
            "Access to support portal with 12 hour SLA’s",
            "Unlimited Ideation submissions",
            "40 prioritised initiatives on backlog dashboard",
            "Unlimited innovation platform connections",
            "40 innovation solution API endpoints",
            "40 solutions visible on innovation dashboards",
            "40 Gigabytes of total storage"            
        ]
    }
    // ,
    // {
    //     "id": uuidv4(),
    //     // "priceMonthly": 299,
    //     // "priceYearly": 199,
    //     "variant": "secondary",
    //     "title": "Enterprise",
    //     "btnText": "Contact",
    //     "btnTextColor": "dark",
    //     "description": "Custom solution designed for more complex needs",
    //     "features": [
    //         "Approval workflow enabled for innovative ideas",
    //         "Access to support portal with tiered SLA’s",
    //         "Unlimited Ideation submissions",
    //         "Unlimited prioritised initiatives on backlog dashboard",
    //         "Unlimited innovation platform connections",
    //         "Unlimited innovation solution API endpoints",
    //         "Unlimited solutions visible on innovation dashboards",
    //         "Unlimited Gigabytes of total storage"            
    //     ]
    // }
]