import React from 'react'

export const SingleTile = () => {
  return (
    <>
      <div className="d-flex justify-content-around rounded my-2 px-3 py-3" style={{background: "#e1e1e1"}}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2>Tile Value</h2>
          <p style={{fontSize: "small"}}> Tile Text </p>
        </div>
      </div>
    </>
    )
};
