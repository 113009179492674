import { BaseAPIService } from "./base";

export class RoleAssignmentService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for getting all role assignment items from database by user id.
    * @param userId
    * @param workspaceId
    * @returns list of role assignment items or rises Error
    */
    async getAllByUserAndActiveWorkspaceId(userId, workspaceId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/role_assignment/all/filter_by?user_id=${userId}&workspace_id=${workspaceId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };
};
