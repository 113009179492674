import { v4 as uuidv4 } from "uuid";

export default [
    {
        "id": uuidv4(),
        "title": "Meeting with Ms. Bonnie from Cognitive Automation Labs LLC",
        "time": "10:00 AM",
        "statusKey": "inProgress",
        "description": "Ideation Session "
    },
    {
        "id": uuidv4(),
        "title": "Upgrate to CAL",
        "time": "10:00 AM",
        "statusKey": "done",
        "description": "Deep-Dive Session"
    },
    {
        "id": uuidv4(),
        "title": "Meeting with Ms. Bonnie from Cognitive Automation Labs LLC",
        "time": "10:00 AM",
        "statusKey": "inProgress",
        "description": "Development alignment session"
    },
    {
        "id": uuidv4(),
        "title": "Meeting with Ms. Bonnie from Cognitive Automation Labs LLC",
        "time": "10:00 AM",
        "statusKey": "inProgress",
        "description": "User acceptance session"
    },
    {
        "id": uuidv4(),
        "title": "Meeting with Ms. Bonnie from Cognitive Automation Labs LLC",
        "time": "10:00 AM",
        "statusKey": "waiting",
        "description": "Migration to Production"
    },
    {
        "id": uuidv4(),
        "title": "Meeting with Ms. Bonnie from Cognitive Automation Labs LLC",
        "time": "10:00 AM",
        "statusKey": "waiting",
        "description": "Hyper care ammendments"
    },
    {
        "id": uuidv4(),
        "title": "Meeting with Ms. Bonnie from Cognitive Automation Labs LLC",
        "time": "10:00 AM",
        "statusKey": "done",
        "description": "Hypercare ammendments"
    }
];