import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Card, Button, Table } from "react-bootstrap";
import { AdditionalBenefitModal } from "../components";
import { AdditionalBenefitsTableRow } from "../tables";
import { Loading, TablePagination } from "pages/components";
import { AdditionalBenefitsService } from "services";
import { handleError } from "utils";

const additionalBenefitsService = new AdditionalBenefitsService();

const columns = [
  { dateField: "index", text: "#" },
  { dataField: "benefitName", text: "Benefit Name" },
  { dataField: "benefitDescription", text: "Description" },
  { dataField: "category", text: "Category" },
  { dataField: "estValue", text: "Est. Value (p.a.)" },
  { dataField: "configs", text: "..." },
];

const perPage = 5;

const paginateAdditionalBenefits = async ({ queryKey }) => {
  const [_, workspaceId, page, perPage] = queryKey;
  if (workspaceId) {
    return await additionalBenefitsService.paginateByWorkspace(
      workspaceId,
      page,
      perPage
    );
  }
};

export const BenefitsTab = (props) => {
  const { workspaceId } = props;
  const history = useHistory();
  const [page, setPage] = useState(1);
  // booleans
  const [modalShow, setModalShow] = useState(false);
  const [itemHasChanged, setItemHasChanged] = useState(true);
  // ids
  const [activeTableItemId, setActiveTableItemId] = useState("");

  const query = useQuery(
    ["additionalBenefits", workspaceId, page, perPage],
    paginateAdditionalBenefits,
    { onError: (e) => handleError(e, history) }
  );
  const { data, isRefetching } = query;
  const total = data?.total ?? 0;

  useEffect(() => {
    if (itemHasChanged) {
      query.refetch();
      setItemHasChanged(false);
    }
  }, [itemHasChanged]);

  return (
    <div className="">
      <AdditionalBenefitModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        workspaceId={workspaceId}
        activeItemId={activeTableItemId}
        changeActiveId={(e) => setActiveTableItemId(e)}
        itemHasChanged={(e) => setItemHasChanged(e)}
      />
      <div className="d-flex justify-content-between w-100 flex-wrap">
        <div className="mb-3 ms-4 mb-lg-0">
          <h6>Workspace Additional Benefits</h6>
        </div>
        <Button
          variant="primary"
          size="sm"
          className="mb-1 me-4 pe-3 ps-3"
          onClick={() => {
            setModalShow(true);
            setItemHasChanged(false);
          }}>
          Add
        </Button>
      </div>
      {isRefetching ? (
        <Loading marginTop="1%" marginLeft="45%" />
      ) : (
        <Card border="0" className="shadow mb-4">
          <Card.Body>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  {columns.map((column, i) => {
                    return (
                      <th key={`column-${i}`} className="border-0">
                        {column.text}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.items
                  ? data?.items.map((row, i) => {
                      return (
                        <AdditionalBenefitsTableRow
                          key={`additional-benefit-${i}`}
                          item={row}
                          handleConfig={setModalShow}
                          changeActiveId={setActiveTableItemId}
                        />
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <TablePagination
              page={page}
              perPage={perPage}
              total={total}
              itemsLength={data?.items ? data?.items.length : 0}
              setPage={setPage}
            />
          </Card.Footer>
        </Card>
      )}
    </div>
  );
};
