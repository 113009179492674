import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class QueueBenefitService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
  * Method is using for creating queue benefit item in database.
  * @param params
  * @returns queue benefit id or rises Error
  */
  async create(params) {
      try {
          const urlWithParams = 'queue_benefit/new?';
          const paramsService = new ParamsService(
              urlWithParams,
              params
          );
          const url = paramsService.urlWithParams;
          const data = await this.BASE_SERVICE.post(
              {
                  url: url,
                  body: {},
              }
          );
          return data.queue_benefit_id;
      } catch (e) {
        throw new Error(e.message);
      }
  };

  /*
   * Method is using for getting all queue benefit items from database by queue id.
   * @param queueId
   * @returns list of queue benefits or rises Error
   */
  async getAllByQueueId(queueId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/queue_benefit/all/filter_by?queue_id=${queueId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /*
   * Method is using for counting benefit per transaction value for queue benefit.
   * @param queueId
   * @param additionalBenefitId
   * @returns benefit per transaction value or rises Error
   */
  async countBenefitPerTransaction(queueId, additionalBenefitId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/queue_benefit/count/benefit_per_transaction?queue_id=${queueId}&additional_benefit_id=${additionalBenefitId}`,
      });
      return data.benefit_per_transaction;
    } catch (e) {
      throw Error(e);
    }
  }

  /*
   * Method is using for counting est_volume_per_annum value for queue benefit.
   * @param queueId
   * @returns est_volume_per_annum value or rises Error
   */
    async countEstVolumePerAnnum(queueId) {
      try {
        const data = await this.BASE_SERVICE.get({
          url: `/queue_benefit/count/est_volume_per_annum?queue_id=${queueId}`,
        });
        return data.est_volume_per_annum;
      } catch (e) {
        throw Error(e);
      }
    }

  /**
  * Method is using for updating all queue benefit items in database.
  * @param queueBenefitId
  * @param data
  * @returns "OK" message or rises Error
  */
  async update(queueBenefitId, data) {
      try {
          await this.BASE_SERVICE.put(
              {
                  url: `/queue_benefit/${queueBenefitId}`,
                  body: data,
              }
          );
          return "OK";
      } catch (e) {
        throw new Error(e.message);
      }
  };

  /**
   * Method is using for deleting queue benefit item from database.
   * @param queueBenefitId
   * @returns "OK" message or rises Error
   */
  async delete(queueBenefitId) {
      try {
          await this.BASE_SERVICE.delete(
              {
                  url: `/queue_benefit/${queueBenefitId}`,
              }
          );
          return "OK";
      } catch (e) {
          throw Error(e);
      }
  };

  /**
   * Method is using for getting queue benefit item from database.
   * @param queueBenefitId
   * @returns queue benefit item or rises Error
   */
  async get(queueBenefitId) {
      try {
          const data = await this.BASE_SERVICE.get(
              {
                  url: `/queue_benefit/${queueBenefitId}`,
              }
          );
          return data;
      } catch (e) {
          throw Error(e);
      }
  };
}
