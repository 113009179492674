import ActionTypes from "../actions/ActionTypes";

const workspaceAssignmentId = localStorage.getItem("workspace_assignment_id");

const initialState = {
  currentWorkspaceAssignmentId: workspaceAssignmentId,
  adminWorkspaceAssignments: [],
};

export const workspaceAssignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CURRENT_WORKSPACE_ASSIGNMENT:
      return {
        ...state,
        currentWorkspaceAssignmentId: action.payload,
      };
    case ActionTypes.ADD_ADMIN_WORKSPACE_ASSIGNMENT:
      let actualAdminWorkspaceAssignments = [
        ...state.adminWorkspaceAssignments,
      ];
      const elementIndex = actualAdminWorkspaceAssignments.findIndex(
        (element) => element === Number(action.payload)
      );
      if (elementIndex === -1) {
        actualAdminWorkspaceAssignments.push(action.payload);
      }
      return {
        ...state,
        adminWorkspaceAssignments: actualAdminWorkspaceAssignments,
      };
    case ActionTypes.REMOVE_ADMIN_WORKSPACE_ASSIGNMENT:
      const workspaceAssignments = [...state.adminWorkspaceAssignments];
      const index = workspaceAssignments.findIndex(
        (element) => element === Number(action.payload)
      );
      const cleanedAdminWorkspaceAssignments =
        index >= 0
          ? [
              ...workspaceAssignments.slice(0, index),
              ...workspaceAssignments.slice(index + 1),
            ]
          : workspaceAssignments;
      return {
        ...state,
        adminWorkspaceAssignments: cleanedAdminWorkspaceAssignments,
      };
    case ActionTypes.RESET_ADMIN_WORKSPACE_ASSIGNMENTS:
      return {
        ...state,
        adminWorkspaceAssignments: [],
      };
    default:
      return state;
  }
};
