import React, { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserService, WorkspaceAssignmentService } from "services";
import { handleError } from "utils";

const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

export const ResetPasswordModal = (props) => {
  const { show, onHide, userId, userEmail } = props;
  const history = useHistory();
  // general
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // booleans
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [itemIsSaved, setItemIsSaved] = useState(false);
  // style
  const statusColor = isSaveTypeSuccessful ? "success" : "danger";
  const closeButtonText = itemIsSaved ? "Close" : "Cancel";

  const isSavedSuccessfully = () => {
    setStatus("User password has been reset successfully!");
    setIsStatusTypeSuccessful(true);
    setItemIsSaved(true);
  };

  const failedDuringSaving = (e) => {
    const errorMsg = e
      ? e.toString()
      : "Failure occurred when reseting. Please, try again.";
    setStatus(errorMsg);
    setIsStatusTypeSuccessful(false);
  };

  const resetUserPassword = async () => {
    try {
      return await userService.resetPassword(userId);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const fetchWorkspaceAssignmentsIdsByUser = async () => {
    try {
      return await workspaceAssignmentService.getIdsByUser(userId);
    } catch (e) {
      handleError(e, history);
    }
  };

  const sendResetPasswordEmail = async () => {
    try {
      const data = {
        userId: userId,
      };
      return await userService.sendResetPasswordEmail(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateUserWorkspaceAssignments = async (ids) => {
    try {
      const data = {
        is_verified: "false",
      };
      if (ids.length > 0) {
        for (const item of ids) {
          const workspaceAssignmentId = item.workspace_assignment_id;
          await workspaceAssignmentService.updateVerifyStatus(
            workspaceAssignmentId,
            data
          );
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveBtnIsDisabled(true);
    setError("");
    setStatus("");
    const response = await resetUserPassword();
    console.log({ response });
    if (response) {
      const userWorkspaceAssignmentIds =
        await fetchWorkspaceAssignmentsIdsByUser();
      console.log({ userWorkspaceAssignmentIds });
      await updateUserWorkspaceAssignments(userWorkspaceAssignmentIds);
      const emailIsSent = await sendResetPasswordEmail();
      if (emailIsSent) {
        isSavedSuccessfully();
      }
    }
    setSaveBtnIsDisabled(false);
  };

  const handleHideModal = () => {
    onHide(true);
    setItemIsSaved(false);
    setError("");
    setStatus("");
  };

  return (
    <Modal centered show={show} onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        ) : null}
        {saveStatus ? (
          <Alert key={statusColor} variant={statusColor}>
            {saveStatus}
          </Alert>
        ) : null}
        Are you sure you want to reset the password for the user{" "}
        <b style={{ color: "blue" }}>{userEmail}</b>?
      </Modal.Body>
      <Modal.Footer>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Col md={itemIsSaved ? 12 : 6} xs={itemIsSaved ? 12 : 6}>
            <Button variant="secondary" onClick={handleHideModal}>
              {closeButtonText}
            </Button>
          </Col>
          {!itemIsSaved ? (
            <Col md={6} xs={6}>
              <Button
                disabled={saveBtnIsDisabled}
                variant="primary"
                type="submit"
                onClick={handleSave}>
                Reset
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
