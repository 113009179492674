import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { AdditionalBenefitsService } from "services";
import { handleError } from "utils";

const additionalBenefitsService = new AdditionalBenefitsService();

const getAdditionalBenefit = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (id) {
    return await additionalBenefitsService.get(id);
  }
};

export const RelatedSolutionQueueBenefitsTableRow = (props) => {
  const { activeId, queueBenefit, changeActiveId, onModalHide } = props;
  const history = useHistory();
  const rowColor = activeId === queueBenefit.queue_benefit_id ? "blue" : "";

  const handleChangeActiveId = (e) => {
    e.preventDefault();
    changeActiveId(queueBenefit.queue_benefit_id);
    onModalHide(false);
  };

  const { data } = useQuery(
    ["additionalBenefit", queueBenefit.additional_benefit_id],
    getAdditionalBenefit,
    {
      onError: (e) => handleError(e, history),
    }
  );

  useEffect(() => {}, [activeId]);

  return (
    <tr className="border-bottom" style={{ color: rowColor }}>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {data ? data.benefit_name : ""}
        </div>
      </td>
      <td>{data ? data.benefit_category : ""}</td>
      <td>{data ? `$${data.benefit_value.toFixed(2)}` : "--"}</td>
      <td>
        <Button
          variant="primary"
          size="sm"
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}
          onClick={handleChangeActiveId}>
          Config
        </Button>
      </td>
    </tr>
  );
};
