import React, { useEffect, useState } from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon } from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Routes } from "routes";
import { UserService, WorkspaceAssignmentService } from "../../services";
import { cleanStorage, handleError } from "utils";

const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

const fetchUser = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await userService.getVerifyUserData(userId);
  }
};

const fetchWorkspaceAssignmentsIds = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await workspaceAssignmentService.getIdsByUser(userId);
  }
};

export const ResetPassword = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [error, setError] = useState("");
  // fields
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // booleans
  const [isDisabled, setIsDisabled] = useState(false);

  const userQuery = useQuery(
    ["resetPasswordUserEmailQuery", userId],
    fetchUser,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const workspaceAssignmentsIdsQuery = useQuery(
    ["resetPasswordWorkspaceAssignmentsIdsQuery", userId],
    fetchWorkspaceAssignmentsIds,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const workspaceAssignmentsIds = workspaceAssignmentsIdsQuery.data
    ? workspaceAssignmentsIdsQuery.data
    : [];

  const failedDuringSaving = (error) => {
    const errorMsg = error ? error : "Failure occurred when saving.";
    setError(errorMsg);
  };

  const updateUserPassword = async () => {
    try {
      const data = {
        password: password,
        confirm_password: confirmPassword,
      };
      return await userService.updatePassword(userId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateUserWorkspaceAssignments = async () => {
    try {
      const data = {
        is_verified: true,
      };
      for (const item of workspaceAssignmentsIds) {
        const workspaceAssignmentId = item.workspace_assignment_id;
        await workspaceAssignmentService.updateVerifyStatus(
          workspaceAssignmentId,
          data
        );
      }
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsDisabled(true);
    if (password !== confirmPassword) {
      setError("Password and confirmation password must match.");
      setIsDisabled(false);
      return;
    }
    const response = await updateUserPassword();
    if (response) {
      await updateUserWorkspaceAssignments();
      history.push(Routes.SignIn.path);
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    setIsDisabled(false);
    if (!password || !confirmPassword) {
      setIsDisabled(true);
    }
    cleanStorage();
  }, [password, confirmPassword]);

  return (
    <main>
      <section className="vh-lg-100 mt-5 pt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.SignIn.path}
                className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <Form.Control
                        readOnly
                        type="email"
                        value={
                          userQuery.data ? userQuery.data.email_address : ""
                        }
                        placeholder="example@company.com"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setError("");
                          setPassword(e.target.value);
                        }}
                        minLength="6"
                        maxLength="50"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        minLength="6"
                        maxLength="50"
                        onChange={(e) => {
                          setError("");
                          setConfirmPassword(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className="d-grid">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isDisabled}>
                      Set password
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
