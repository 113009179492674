
import React from "react";
import { useHistory } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb } from 'react-bootstrap';
import { useTypedSelector } from 'hooks';
import { Routes } from "routes";

export const SettingsConnectors = () => {
    const history = useHistory();
    const activeWorkspaceName = useTypedSelector((state) => state.workspace.activeWorkspaceName);

    const goToTablesDocs = () => {
        history.push(`/${activeWorkspaceName}${Routes.Tables.path}`);
    };

    return (
        <>
            <div className="py-4">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item active>Connectors</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                    <div className="mb-3 mb-lg-0">
                        <h4>Connectors</h4>
                        <p className="mb-0">
                            Coming soon.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
