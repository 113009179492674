import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";
import {
  Alert,
  Col,
  Row,
  Card,
  Form,
  Breadcrumb,
  Button,
} from "react-bootstrap";
import Table from "react-bootstrap-table-next";
import Pagination, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import Papa from "papaparse";
import { Loading } from "pages";
import { useTypedSelector } from "hooks";
import { TransactionService } from "services";
import { formatDate, handleError } from "utils";

const transactionService = new TransactionService();

const perPage = 10;
const transactionsColumnsList = [
  "transaction_key",
  "queue_key",
  "queue_id",
  "status",
  "tags",
  "start_time",
  "end_time",
];

const csvHeaders = [
  { label: "transaction_id", key: "transactionId" },
  { label: "transaction_key", key: "transactionKey" },
  { label: "queue_id", key: "queueId" },
  { label: "queue_key", key: "queueKey" },
  { label: "status", key: "status" },
  { label: "tags", key: "tags" },
  { label: "start_time", key: "startTime" },
  { label: "end_time", key: "endTime" },
];

const paginateTransactions = async ({ queryKey }) => {
  const [_, activeWorkspaceId, page, perPage] = queryKey;
  return await transactionService.paginateByWorkspace(
    activeWorkspaceId,
    page,
    perPage
  );
};

const getTransactionsByWorkspace = async ({ queryKey }) => {
  const [_, activeWorkspaceId] = queryKey;
  return await transactionService.getAllByWorkspace(activeWorkspaceId);
};

export const TablesTransactions = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );

  const paginationTransactionQuery = useQuery(
    [
      "transactions",
      activeWorkspaceId,
      page,
      perPage,
    ],
    paginateTransactions,
    { onError: (e) => handleError(e, history) }
  );

  const allTransactionsByWorkspaceQuery = useQuery(
    ["transactions", activeWorkspaceId],
    getTransactionsByWorkspace,
    { onError: (e) => handleError(e, history) }
  );

  const columns = [
    { dataField: "transaction_key", text: "Transaction Key" },
    { dataField: "queue_key", text: "Queue Key" },
    { dataField: "status", text: "Status" },
    { dataField: "tags", text: "Tags" },
    { dataField: "start_time", text: "Start Time" },
    { dataField: "end_time", text: "End Time" },
  ];

  const customTotal = (from, to, size) => (
    <div>
      Showing {from} to {to} of {size} entries
    </div>
  );

  const customSizePerPage = (props) => {
    const { options, currentSizePerPage, onSizePerPageChange } = props;

    const onPageChange = (e) => {
      const page = e.target.value;
      onSizePerPageChange(page);
    };

    return (
      <Row as="label">
        <Col xs="auto">
          <Form.Select
            value={currentSizePerPage}
            onChange={onPageChange}
            className="pe-5">
            {options.map((o) => (
              <option key={o.page} value={o.page}>
                {o.text}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs="auto" className="d-flex align-items-center ps-0">
          entries per page
        </Col>
      </Row>
    );
  };

  const handlePageChange = (e, details) => {
    if (e === "pagination") {
      setPage(details.page);
      paginationTransactionQuery.refetch();
    }
    setError("");
    setStatus("");
  };

  const postManyTransactions = async (formData) => {
    try {
      return await transactionService.createMany(activeWorkspaceId, formData);
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleDataToDownload = () => {
    let dataToDownload = [];
    allTransactionsByWorkspaceQuery.data?.forEach((item) => {
      const dataRow = {
        transactionId: item.transaction_id,
        transactionKey: item.transaction_key,
        queueId: item.queue_id,
        queueKey: item.queue_key,
        status: item.status,
        tags: item.tags,
        startTime: item.start_time,
        endTime: item.end_time,
      };
      dataToDownload.push(dataRow);
    });
    return dataToDownload;
  };

  const handleCsvUpload = async (e) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      Papa.parse(file, {
        complete: async function (results) {
          const columns = results.data[0];
          const missingColumns = [];
          transactionsColumnsList.forEach(async (column) => {
            const columnIsIncluded = columns.includes(column);
            if (!columnIsIncluded) {
              missingColumns.push(column);
            }
          });
          if (missingColumns.length > 0) {
            setError(
              `Csv file should contain such columns: ${missingColumns}.`
            );
            setStatus("");
            return;
          }
          let formData = new FormData();
          formData.append("file", file);
          const result = await postManyTransactions(formData);
          if (result) {
            paginationTransactionQuery.refetch();
            allTransactionsByWorkspaceQuery.refetch();
            setError("");
            setStatus("Transactions added successfully!");
            return;
          }
          setStatus("");
          setError(
            "Failure occurred when adding transactions. Please check your data and try again!"
          );
        },
      });
    }
  };

  const handleClickUpload = () => {
    setError("");
    setStatus("");
    document.getElementById("getCsvFile").click();
  };

  return (
    <>
      <div className="py-4">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item>
            <HomeIcon className="icon icon-xs" />
          </Breadcrumb.Item>
          <Breadcrumb.Item>Tables</Breadcrumb.Item>
          <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mb-3 ms-4 mb-lg-0">
            <h4>Transactions</h4>
          </div>
          <Col>
            {paginationTransactionQuery.isLoading ? (
              <Loading marginTop="1%" marginLeft="45%" />
            ) : null}
          </Col>
          <CSVLink
            className="ms-100 me-2"
            filename={`transactions_${formatDate(new Date())}.csv`}
            data={handleDataToDownload()}
            headers={csvHeaders}
            separator=",">
            <Button style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              Export
            </Button>
          </CSVLink>
          <Button
            onClick={handleClickUpload}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <input
              value=""
              type="file"
              accept=".csv"
              id="getCsvFile"
              onChange={handleCsvUpload}
              style={{ display: "none" }}
            />
            Upload
          </Button>
        </div>
      </div>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      {status ? (
        <Alert key="success" variant="success">
          {status}
        </Alert>
      ) : null}
      {!paginationTransactionQuery.isLoading ? (
        <ToolkitProvider
          keyField="transaction_id"
          columns={columns}
          data={paginationTransactionQuery.data?.items}>
          {({ baseProps }) => (
            <PaginationProvider
              pagination={Pagination({
                page: page,
                custom: true,
                showTotal: true,
                alwaysShowAllBtns: true,
                totalSize: paginationTransactionQuery.data?.total ?? 0,
                withFirstAndLast: false,
                paginationTotalRenderer: customTotal,
                sizePerPageRenderer: customSizePerPage,
              })}>
              {({ paginationProps, paginationTableProps }) => (
                <Card border="0" className="shadow mb-4">
                  <Card.Body>
                    <Table
                      {...baseProps}
                      {...paginationTableProps}
                      headerWrapperClasses="thead-light"
                      bodyClasses="border-0"
                      rowClasses="border-bottom"
                      classes="table-flush dataTable-table"
                      remote={true}
                      onTableChange={handlePageChange}
                    />
                    <div className="dataTable-bottom">
                      <div className="dataTable-info">
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div className="dataTable-pagination">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </PaginationProvider>
          )}
        </ToolkitProvider>
      ) : null}
    </>
  );
};
