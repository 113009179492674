import React from "react";

export const MultiTiles = (props) => {
  const {
    dollarSign,
    color1,
    color2,
    tile1value,
    tile1text,
    tile2value,
    tile2text,
  } = props;

  return (
    <>
      <div className="row rounded mb-3 mx-0" style={{backgroundColor: '#f3f3f3'}}>
        <div className="col-sm-6 ps-1 pe-0">
          <div className="card bg-transparent" style={{ border: '0' }}>
            <div className="card-body text-center px-0 py-3 pt-4">
              <h3 className="card-title mb-1" style={{ color: `${color1}` }}>
                {dollarSign}
                {tile1value}
              </h3>
              <p className="card-text mb-0" style={{ fontSize: "0.75rem" }}>{tile1text}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 ps-0 pe-1">
          <div className="card bg-transparent" style={{ border: '0' }}>
            <div className="card-body text-center px-0 py-3 pt-4">
              <h3 className="card-title mb-1" style={{ color: `${color2}` }}>
                {dollarSign}
                {tile2value}
              </h3>
              <p className="card-text mb-0" style={{ fontSize: "0.75rem" }}>{tile2text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
