import React from "react";
import * as XLSX from "xlsx";
import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

export const BacklogBarChart = (props) => {
  const { wb, BarChartFilterObject } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);

  var BacklogBarChartData = {
    data: [
      {
        type: "bar",
        x: ["a", "b"],
        y: [5, 10],
        text: ["5", "10"],
        marker: {
          color: ["#0059ff ", "#000c55", "#12121a", "#c7c8ca", "#e1e1e1"],
        },
      },
    ],
    layout: {
    //   title: "Responsive to window's size!",
      font: { size: 18 },
      yaxis: {
        showgrid: false,
        zeroline: false,
        showticklabels: false,
        title: "Ideas count",
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      height: 300,
    //   yaxis: {
    //     title: {
    //       text: "<b>Success Rate</b>",
    //       font: {
    //         size: 12,
    //         family: '"Inter", sans-serif',
    //       },
    //     },
    //     tickfont: {
    //       family: '"Inter", sans-serif',
    //     },
    //     tickformat: "0%",
    //     range: [0, 1],
    //   },
    //   xaxis: {
    //     tickfont: {
    //       family: '"Inter", sans-serif',
    //     },
    //     tickangle: 30,
    //   },
      
    //   legend: {
    //     x: 1,
    //     y: 0.5,
    //   },
    //   margin: { t: 15, b: 67, l: 40, r: 30 },
    },
    config: {
      showLink: false,
      displayModeBar: false,
      responsive: true,
    },
  };
  return (
    <>
      <div className="rounded">
        <PlotlyComponent
          style={{ width: "100%" }}
          data={BacklogBarChartData.data}
          layout={BacklogBarChartData.layout}
          config={BacklogBarChartData.config}
        />
      </div>
    </>
  );
};
