import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useAction } from "hooks";
import { BenefitsTab, TechnologyTab, WorkspaceTab } from "../tabs";

const benefitsTabName = "benefits";
const technologyTabName = "technology";
const workspaceTabName = "workspace";

export const EditWorkspace = (props) => {
  const { type, workspaceId } = props;
  const {
    setTechnologiesTabAction,
    resetTechnologyTabsAction,
    resetLicenseAction,
    resetTechnologyAction,
  } = useAction();
  const [activeTab, setActiveTab] = useState(workspaceTabName);
  const [isTabDisabled, setIsTabDisabled] = useState(false);

  const handleSelect = (e) => {
    setActiveTab(e);
    if (e === technologyTabName) {
      resetLicenseAction();
      resetTechnologyAction();
      setTechnologiesTabAction();
      return;
    }
    resetTechnologyTabsAction();
  };

  useEffect(() => {
    setIsTabDisabled(true);
    if (workspaceId) {
      setIsTabDisabled(false);
    }
  }, []);

  return (
    <>
      <Tabs
        activeKey={activeTab}
        id="fill-tab-example"
        className="mb-3"
        onSelect={handleSelect}>
        <Tab eventKey={workspaceTabName} title="Workspace">
          <WorkspaceTab type={type} workspaceId={workspaceId} />
        </Tab>
        <Tab
          eventKey={benefitsTabName}
          title="Benefits"
          disabled={isTabDisabled}>
          <BenefitsTab workspaceId={workspaceId} />
        </Tab>
        <Tab
          eventKey={technologyTabName}
          title="Technology"
          disabled={isTabDisabled}>
          <TechnologyTab workspaceId={workspaceId} />
        </Tab>
      </Tabs>
    </>
  );
};
