import React, { useEffect, useState } from "react";
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import {
  Alert,
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import BgImage from "assets/img/illustrations/signin.svg";
import { useTypedSelector } from "hooks";
import { Routes } from "routes";
import {
  AuthService,
  UserService,
  WorkspaceAssignmentService,
} from "../../services";
import { handleError, isValidEmail } from "utils";

const apiAuthService = new AuthService();
const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

const savingErrorText =
  "Problem has occurred during saving your data. Please, try again.";
const userExistsErrorText = "A user with this email exists in the database.";

export const Signup = () => {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  // new users are automatically assigned to the default workspace
  const defaultWorkspaceId = useTypedSelector(
    (state) => state.workspace.defaultWorkspaceId
  );

  const handleFirstName = (e) => {
    setError("");
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setError("");
    setLastName(e.target.value);
  };
  const handleEmail = (e) => {
    setError("");
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setError("");
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setError("");
    setConfirmPassword(e.target.value);
  };
  const handleCheck = (e) => {
    setError("");
    setIsChecked(e.target.value);
  };

  const createWorkspaceAssignment = async (userId) => {
    try {
      const data = {
        isAdmin: false,
        isVerified: true,
        userId: userId,
        workspaceId: defaultWorkspaceId,
      };
      return await workspaceAssignmentService.create(data);
    } catch (e) {
      handleError(e, history);
    }
  };

  const checkUserHasDefaultWorkspace = async (userId) => {
    try {
      return await workspaceAssignmentService.checkUserHasWorkspace(
        userId,
        defaultWorkspaceId
      );
    } catch (e) {
      handleError(e, history);
    }
  };

  const getUserIdByEmail = async () => {
    try {
      return await userService.getUserIdByEmail(email);
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleRegisterUserError = async (errorMessage) => {
    if (errorMessage === userExistsErrorText) {
      const existedUserId = await getUserIdByEmail();
      if (existedUserId) {
        // check if registered user has workspace asssignment item with default workspace
        const isUserHasDefaultWorkspace = await checkUserHasDefaultWorkspace(
          existedUserId
        );
        if (!isUserHasDefaultWorkspace) {
          const workspaceAssignmentId = await createWorkspaceAssignment(
            existedUserId
          );
          if (workspaceAssignmentId) {
            history.push(Routes.SignIn.path);
            return;
          } 
          setError(savingErrorText);
          return;
        }
        setError(userExistsErrorText);
      }
      return;
    }
    setError(errorMessage);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const userResponse = await apiAuthService.register({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        workspaceId: defaultWorkspaceId,
      });
      if (userResponse) {
        const workspaceAssignmentId = await createWorkspaceAssignment(
          userResponse.user_id
        );
        if (workspaceAssignmentId) {
          history.push(Routes.SignIn.path);
          return;
        }
      }
      setError(savingErrorText);
    } catch (e) {
      await handleRegisterUserError(e.message);
    }
  };

  useEffect(() => {
    setIsDisabled(false);
    if (email && !isValidEmail(email)) {
      setError("Email is not valid!");
    }
    if (password && password.length < 6) {
      setError("Password too short!");
    }
    if (password !== confirmPassword) {
      setError("Password and confirmation password must match!");
    }
    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      firstName === "" ||
      lastName === "" ||
      isChecked === false
    ) {
      setIsDisabled(true);
    }
  }, [
    email,
    error,
    isChecked,
    firstName,
    lastName,
    password,
    confirmPassword,
    isDisabled,
  ]);

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row
            className="justify-content-center form-bg-image mt-5"
            style={{ backgroundImage: `url(${BgImage})` }}>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                {error ? (
                  <Alert key="danger" variant="danger">
                    {error}
                  </Alert>
                ) : null}
                <Form className="mt-4">
                  <Form.Group id="firstName" className="mb-4">
                    <Form.Label>Your First Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="John"
                        onChange={handleFirstName}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="lastName" className="mb-4">
                    <Form.Label>Your Last Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="Doe"
                        onChange={handleLastName}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        onChange={handleEmail}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        onChange={handlePassword}
                        minLength="6"
                        maxLength="50"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Confirm Password"
                        onChange={handleConfirmPassword}
                        minLength="6"
                        maxLength="50"
                      />
                    </InputGroup>
                  </Form.Group>
                  <FormCheck type="checkbox" className="mb-4">
                    <FormCheck.Input
                      required
                      id="terms"
                      className="me-2"
                      onClick={handleCheck}
                    />
                    <FormCheck.Label htmlFor="terms" className="fw-normal mb-0">
                      I agree to the{" "}
                      <Card.Link className="fw-bold">
                        terms and conditions
                      </Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="submit"
                      onClick={onSubmit}
                      disabled={isDisabled}>
                      Sign up
                    </Button>
                  </div>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                    <FacebookIcon size="xs" color="currentColor" />
                  </Button>
                  <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                    <TwitterIcon size="xs" color="currentColor" />
                  </Button>
                  <Button variant="outline-gray-500" className="btn-icon-only btn-pill">
                    <GithubIcon size="xs" color="currentColor" />
                  </Button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.SignIn.path}
                      className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
