import * as AuthActionCreators from "./authActions";
import * as LicenseActionCreators from "./licenseActions";
import * as QueueActionCreators from "./queueActions";
import * as TabActionCreators from "./tabActions"; 
import * as TechnologyActionCreators from "./technologyActions";
import * as UserActionCreators from "./userActions";
import * as WorkspaceActionCreators from "./workspaceActions";
import * as WorkspaceAssignmentActionCreators from "./workspaceAssignmentActions"

const actions = {
    ...AuthActionCreators,
    ...LicenseActionCreators,
    ...QueueActionCreators,
    ...TabActionCreators,
    ...TechnologyActionCreators,
    ...UserActionCreators,
    ...WorkspaceActionCreators,
    ...WorkspaceAssignmentActionCreators,
};

export default actions;
