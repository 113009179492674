import { BaseAPIService } from "./base";

export class AppRoleService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
     * Method is using for getting app role item from database.
     * @param appRoleId
     * @returns solution db item or rises Error
     */
         async get(appRoleId) {
            try {
                const data = await this.BASE_SERVICE.get(
                    {
                        url: `/app_roles/${appRoleId}`,
                    }
                );
                return data;
            } catch (e) {
                throw Error(e);
            }
        };
};
