import React, { useEffect } from "react";
import { useTypedSelector } from "hooks";
import { EditTechnology } from "../components";
import { TechnologiesTable } from "../tables";

export const TechnologyTab = (props) => {
  const { workspaceId } = props;
  const technologiesTab = useTypedSelector(
    (state) => state.tab.technologiesTab
  );
  const technologyConfigTab = useTypedSelector(
    (state) => state.tab.technologyConfigTab
  );
  const technologyNewTab = useTypedSelector(
    (state) => state.tab.technologyNewTab
  );

  useEffect(() => {}, [technologiesTab, technologyConfigTab, technologyNewTab]);

  return (
    <>
      {technologiesTab ? <TechnologiesTable workspaceId={workspaceId} /> : null}
      {technologyNewTab ? (
        <EditTechnology workspaceId={workspaceId} type="new" />
      ) : null}
      {technologyConfigTab ? (
        <EditTechnology workspaceId={workspaceId} type="config" />
      ) : null}
    </>
  );
};
