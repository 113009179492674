import { useParams } from 'react-router-dom'
import { EditSolution, SolutionHeader } from "./components";

export const ConfigDashboardSolution = () => {
    const { id } = useParams();

    return (
        <>
            <SolutionHeader type="config" />
            <EditSolution type="config" solutionId={id} />
        </>
    )
}
