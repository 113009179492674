import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { UserService } from "services";
import { handleError } from "utils";

const userService = new UserService();

const fetchUser = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  if (userId) {
    return await userService.get(userId);
  }
};

export const ProfileHeader = (props) => {
  const { userId } = props;
  const history = useHistory();

  const userQuery = useQuery(["profileHeaderData", userId], fetchUser, {
    onError: (e) => handleError(e, history),
  });

  const userName = userQuery.data
    ? `${userQuery.data.first_name} ${userQuery.data.last_name}`
    : "";

  return (
    <>
      <div className="py-4">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item>
            <HomeIcon className="icon icon-xs" />
          </Breadcrumb.Item>
          <Breadcrumb.Item>Profile</Breadcrumb.Item>
          <Breadcrumb.Item>{userName}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
};
