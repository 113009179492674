import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useAction } from "hooks";

export const RelatedSolutionQueuesTableRow = (props) => {
  const { activeId, queue, changeActiveId } = props;
  const { queueChangesAction } = useAction();
  const rowColor = activeId === queue.queue_id ? "blue" : "";

  const handleChangeActiveId = (e) => {
    e.preventDefault();
    changeActiveId(queue.queue_id);
    queueChangesAction(false);
  };

  useEffect(() => {}, [activeId]);

  return (
    <tr className="border-bottom" style={{ color: rowColor }}>
      <td className="fw-bold">
        <div className="d-flex align-items-center">{queue.queue_name}</div>
      </td>
      <td>
        {queue.est_annual_benefit
          ? `$${queue.est_annual_benefit.toFixed(2)}`
          : "--"}
      </td>
      <td>
        {queue.actual_total_benefit
          ? `$${queue.actual_total_benefit.toFixed(2)}`
          : "--"}
      </td>
      <td>
        <Button
          variant="primary"
          size="sm"
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}
          onClick={handleChangeActiveId}>
          Config
        </Button>
      </td>
    </tr>
  );
};
