import React from "react";
import moment from "moment-timezone";
import { Row, Col, Image, ListGroup } from "react-bootstrap";

export const Notification = (props) => {
  const { link, sender, image, time, message, read = false } = props;
  const readClassName = read ? "" : "text-danger";
  const receiveTime = moment(time).fromNow();

  return (
    <ListGroup.Item
      action
      href={link}
      className="list-group-item-action border-bottom">
      <Row className="align-items-center">
        <Col xs="auto">
          <Image src={image} className="avatar-md rounded" />
        </Col>
        <Col className="ps-0 ms-2">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="h6 mb-0 text-small">{sender}</h4>
            </div>
            <div className="text-end">
              <small className={readClassName}>{receiveTime}</small>
            </div>
          </div>
          <p className="font-small mt-1 mb-0">{message}</p>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
