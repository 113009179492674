import { useEffect } from "react";
import { Nav, Badge, Image } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Routes } from "routes";
import { useAction, useTypedSelector } from "hooks";
import { removeGapAndConvertToLowerCase } from "utils";

export const NavItem = (props) => {
  const {
    title,
    link,
    target,
    icon: NavItemIcon,
    image,
    badgeText,
    badgeBg,
    badgeColor = "white",
    activeWorkspaceId,
    disableDashboards,
    isLogo,
    show,
    setShow,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const currentWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  const userId = useTypedSelector((state) => state.user.userId);
  const contracted = props.contracted ? "contracted" : "";
  const classNames = badgeText
    ? "d-flex align-items-center justify-content-between"
    : "";
  const navItemClassName = link === pathname ? "active" : "";

  const { changeWorkspaceAction, disableDashboardsAction } =
    useAction();

  const styles = {
    bottomLogoPosition: {
      marginTop: "290%",
    },
    bottomLogoText: {
      fontSize: 12,
    },
  };

  const logoPosition = isLogo ? styles.bottomLogoPosition : null;
  const logoText = isLogo ? styles.bottomLogoText : null;

  const handleClick = async () => {
    setShow(false);
    if (activeWorkspaceId) {
      const preparedTitle = removeGapAndConvertToLowerCase(title);
      changeWorkspaceAction(
        preparedTitle,
        activeWorkspaceId,
        currentWorkspaceAssignmentId,
        userId
      );
      disableDashboardsAction(disableDashboards);
      if (disableDashboards === true) {
        history.push(`/${preparedTitle}${Routes.DashboardSolutions.path}`);
        return;
      } 
      history.push(`/${preparedTitle}`);
    }
  };

  useEffect(() => {}, [activeWorkspaceId]);

  return (
    <Nav.Item
      className={navItemClassName}
      style={logoPosition}
      onClick={handleClick}>
      <Nav.Link as={Link} to={link ? link : ""} target={target} className={classNames}>
        <span>
          {NavItemIcon && (
            <span className="sidebar-icon">
              <NavItemIcon className="icon icon-xs me-2" />
            </span>
          )}

          {image ? (
            <Image
              src={image}
              width={20}
              height={20}
              className="sidebar-icon svg-icon"
            />
          ) : null}

          {!show && contracted && !NavItemIcon && !image ? (
            <span className="sidebar-text-contracted">{title[0]}</span>
          ) : null}

          <span className="sidebar-text" style={logoText}>
            {title}
          </span>
        </span>

        {badgeText ? (
          <Badge
            pill
            bg={badgeBg}
            text={badgeColor}
            className="badge-sm notification-count">
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};
