import ActionTypes from "./ActionTypes";
import { WorkspaceAssignmentService, WorkspaceService } from "../../services";

const workspaceAssignmentService = new WorkspaceAssignmentService();
const workspaceService = new WorkspaceService();

export const updateActiveWorkspaceIdAction = (payload) => ({
  type: ActionTypes.ACTIVE_WORKSPACE_ID,
  payload,
});
export const updateActiveWorkspaceNameAction = (payload) => ({
  type: ActionTypes.ACTIVE_WORKSPACE_NAME,
  payload,
});
export const updateDefaultWorkspaceIdAction = (payload) => ({
  type: ActionTypes.DEFAULT_WORKSPACE_ID,
  payload,
});
export const updateDefaultWorkspaceNameAction = (payload) => ({
  type: ActionTypes.DEFAULT_WORKSPACE_NAME,
  payload,
});
export const uploadWorkspacesAction = (payload) => ({
  type: ActionTypes.UPLOAD_WORKSPACES,
  payload,
});

export const loadUserWorkspacesAction = (payload) => ({
  type: ActionTypes.LOAD_USER_WORKSPACES,
  payload,
});

export const addWorkspaceAction = (payload) => ({
  type: ActionTypes.ADD_WORKSPACE,
  payload,
});

export const removeWorkspaceAction = (payload) => ({
  type: ActionTypes.REMOVE_WORKSPACE,
  payload,
});

export const updateWorkspaceAction = (payload) => ({
  type: ActionTypes.UPDATE_WORKSPACE,
  payload,
});

export const deleteActiveWorkspaceAction = (payload) => ({
  type: ActionTypes.DELETE_ACTIVE_WORKSPACE,
  payload,
});

export const disableDashboardsAction = (isDashboardsDisabled) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DISABLE_DASHBOARDS,
        payload: isDashboardsDisabled,
      });
      localStorage.setItem("disable_dashboards", isDashboardsDisabled);
    } catch (error) {
      console.error("Update disable dashboards value action error: ", error);
    };
  };
};

export const changeWorkspaceAction = (
  workspaceName,
  activeWorkspaceId,
  currentWorkspaceAssignmentId,
  userId
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.ACTIVE_WORKSPACE_NAME,
        payload: workspaceName,
      });
      dispatch({
        type: ActionTypes.ACTIVE_WORKSPACE_ID,
        payload: activeWorkspaceId,
      });
      // find workspace assignment by activeWorkspaceId
      const workspaceAssignments =
        await workspaceAssignmentService.getAllByWorkspaceAndUser(
          activeWorkspaceId,
          userId
        );
      let newWAId = "";
      if (workspaceAssignments.length > 0) {
        newWAId = workspaceAssignments[0].workspace_assignment_id;
      }
      if (newWAId) {
        dispatch({
          type: ActionTypes.CURRENT_WORKSPACE_ASSIGNMENT,
          payload: newWAId,
        });

        // update previous workspace assignment is_active field to false
        if (currentWorkspaceAssignmentId) {
          data = {
            is_active: "false",
          };
          await workspaceAssignmentService.update(
            currentWorkspaceAssignmentId,
            data
          );
        }

        // update new workspace assignment is_active field and pending state
        let data = {};
        data = {
          is_active: true,
          is_pending: "false",
        };
        await workspaceAssignmentService.update(newWAId, data);
      }
    } catch (error) {
      console.error("Change workspace action error: ", error);
    }
  };
};

export const deleteWorkspaceThunkAction = (
  workspaceToDeleteId,
  defaultWorkspaceName,
  defaultWorkspaceId,
  userId,
) => {
  return async (dispatch) => {
    try {
      // delete current workspace
      await workspaceService.delete(workspaceToDeleteId);
      dispatch({
        type: ActionTypes.REMOVE_WORKSPACE,
        payload: workspaceToDeleteId,
      });
      dispatch({
        type: ActionTypes.ACTIVE_WORKSPACE_NAME,
        payload: defaultWorkspaceName,
      });
      dispatch({
        type: ActionTypes.ACTIVE_WORKSPACE_ID,
        payload: defaultWorkspaceId,
      });
      dispatch({
        type: ActionTypes.DISABLE_DASHBOARDS,
        payload: true,
      });

      // update default workspace disable_dashboards field to true
        const data = { disable_dashboards: true };
        await workspaceService.update(defaultWorkspaceId, data);

      // find workspace assignment by activeWorkspaceId and userId
      const workspaceAssignments =
        await workspaceAssignmentService.getAllByWorkspaceAndUser(
          defaultWorkspaceId,
          userId
        );
      if (workspaceAssignments.length > 0) {
        const firstWorkspaceAssignment = workspaceAssignments[0];
        if (
          firstWorkspaceAssignment.workspace_id === Number(defaultWorkspaceId)
        ) {
          const newWAId = firstWorkspaceAssignment.workspace_assignment_id;
          dispatch({
            type: ActionTypes.CURRENT_WORKSPACE_ASSIGNMENT,
            payload: newWAId,
          });
          localStorage.setItem("workspace_assignment_id", newWAId);

          // update new workspace assignment is_active field to true
          const data = {
            is_active: true,
          };
          await workspaceAssignmentService.update(newWAId, data);

          dispatch({
            type: ActionTypes.DELETE_ACTIVE_WORKSPACE,
            payload: true,
          });
        };
      };
    } catch (error) {
      console.error("Delete workspace action error: ", error);
    }
  };
};
