import React from "react";
import * as XLSX from "xlsx";
import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

var successRateBarData;

export const BarChart = (props) => {
  const { wb, BarChartFilterObject, year } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);

  successRateBarData = {
    data: [
      {
        x: [],
        y: [],
        text: [],
        type: "bar",
        textposition: "auto",
        // hoverinfo: "none",
        marker: {
          color: "rgb( 0 89 255)",
        },
      },
    ],
    layout: {
      //   title: "January 2013 Sales Report",
      barmode: "Bar",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      yaxis: {
        title: {
          text: "<b>Success Rate</b>",
          font: {
            size: 12,
            family: '"Inter", sans-serif',
          },
        },
        tickfont: {
          family: '"Inter", sans-serif',
        },
        tickformat: '0%',
        range: [0, 1]
      },
      xaxis: {
        tickfont: {
          size: 12,
          family: '"Inter", sans-serif',
        },
        // tickangle :0
      },
      height: 190,
      legend: {
        x: 1,
        y: 0.5,
      },
      margin: { t: 15, b: 47, l: 40, r: 30 },
    },
    config: {
      showLink: false,
      displayModeBar: false,
      responsive: true
    },
    heading: "Solution Health Status",
  };

  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    var data = generateSuccessRateBarChart(
      wb,
      BarChartFilterObject.filterObject
    );

    var Year = new Date(year).getFullYear();

    var currentYearRecords = getSelectedYearRecordsOnly(data, Year);

    for (let i = 0; i < currentYearRecords.y.length; i++) {
      currentYearRecords.y[i] = currentYearRecords.y[i] / 100
    }

    successRateBarData.data[0].x = currentYearRecords.x;
    successRateBarData.data[0].y = currentYearRecords.y;
    successRateBarData.data[0].text = currentYearRecords.text.map(element => { return element + "%"; });
  }

  function getSelectedYearRecordsOnly(data, year) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var filteredIndexes = [];

    var filterDates = data.x.filter((el, index) => {
      if (el.indexOf(year) > -1) {
        filteredIndexes.push(index);
        return el;
      }
    });

    var filterValues = [];
    filteredIndexes.forEach((i) => {
      filterValues.push(data.y[i]);
    });

    var missingMonthsOfSelectedYear = [];
    var missingYearValues = [];
    var indexesForMerging = [];

    monthNames.forEach((element) => {
      var monthWithYear = element + " " + year;
      if (filterDates.indexOf(monthWithYear) == -1) {
        missingMonthsOfSelectedYear.push(monthWithYear);
        missingYearValues.push(0);
      }
    });

    //var sortedFilteredDate = filterDates.sort();
    filterDates.forEach((ele, index) => {
      var splittedDate = ele.split(" ")[0];
      var indexToPush = monthNames.indexOf(splittedDate);
      missingMonthsOfSelectedYear.splice(indexToPush, 0, ele);
      missingYearValues.splice(indexToPush, 0, filterValues[index]);
    });

    return {
      x: missingMonthsOfSelectedYear,
      y: missingYearValues,
      text: missingYearValues.map(String),
    };
  }

  function generateSuccessRateBarChart(workbookData, filterObject) {
    var trace1 = {
      x: [],
      y: [],
      text: [],
    };
    if (Object.keys(workbookData).length > 0) {

      var factSolutionSheet = workbookData.Sheets['FactSolution'];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.BusinessUnitKey != "" && ele.BusinessUnitKey == filterObject.BusinessUnit;
        })
      }
      if (filterObject.Portfolio != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.PortfolioKey != "" && ele.PortfolioKey == filterObject.Portfolio;
        })
      }
      if (filterObject.StageOfDevelopment != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.StageKey != "" && ele.StageKey == filterObject.StageOfDevelopment;
        })
      }
      if (filterObject.SolutionsName != "") {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.Name != "" && ele.Name == filterObject.SolutionsName;
        })
      }

      //get names from fact solution sheet to map with fact queue performance sheet
      var solutionNames = filteredSheetData.map(element => {
        return element.Name;
      })

      var uniqueNames = solutionNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })


      //read fact queue performance to map names with solution names
      var factQueuePerfromanceSheet = workbookData.Sheets['FactQueuePerformance'];
      var factQueuePerfromanceSheetData = XLSX.utils.sheet_to_json(factQueuePerfromanceSheet, {
        raw: false, defval: "",
        blankRows: false,
      });

      //map fact solution sheet names with fact queue performance solution names
      var filteredFactQueuePerformance = [];
      uniqueNames.forEach(name => {
        factQueuePerfromanceSheetData.forEach(element => {
          if (element["SolutionName"].replace(/ /g, "") === name.replace(/ /g, "")) {
            filteredFactQueuePerformance.push(element);
          };
        });
      });
      var lookUp = []
      var groupedData = []
      filteredFactQueuePerformance.forEach(element => {
        if (element["Actual Monthly Volume"].indexOf(",") > -1) {
          element["Actual Monthly Volume"] = element["Actual Monthly Volume"].replaceAll(",", "");
        }
        if (element["Successful Monthly Volume"].indexOf(",") > -1) {
          element["Successful Monthly Volume"] = element["Successful Monthly Volume"].replaceAll(",", "");
        }
        if (lookUp.indexOf(element.StartOfMonth) > -1) {
          var index = lookUp.indexOf(element.StartOfMonth);


          if (element["Successful Monthly Volume"] == "") {
            groupedData[index]["Successful Monthly Volume"] += 0;
          }
          else {
            groupedData[index]["Successful Monthly Volume"] += parseInt(element["Successful Monthly Volume"])
          }
          if (element["Actual Monthly Volume"] == "") {
            groupedData[index]["Actual Monthly Volume"] += 0;
          }
          else {
            groupedData[index]["Actual Monthly Volume"] += parseInt(element["Actual Monthly Volume"])
          }

        }
        else {
          lookUp.push(element.StartOfMonth);
          if (element["Successful Monthly Volume"] == "") {
            element["Successful Monthly Volume"] = 0;
          }
          else {
            element["Successful Monthly Volume"] = parseInt(element["Successful Monthly Volume"]);
          }
          if (element["Actual Monthly Volume"] == "") {
            element["Actual Monthly Volume"] = 0;
          }
          else {
            element["Actual Monthly Volume"] = parseInt(element["Actual Monthly Volume"]);
          }
          groupedData.push(element);
        }
      })

      trace1.x = groupedData.map(element => { return element.StartOfMonth });
      trace1.y = groupedData.map(element => {

        if (element["Successful Monthly Volume"] == 0 && element["Actual Monthly Volume"] == 0) {
          return 0;
        }
        else {
          return (Math.floor((element["Successful Monthly Volume"] / element["Actual Monthly Volume"]) * 100));
        }
      });
      trace1.text = trace1.y.map(String);
    }
    return trace1;
  }

  return (
    <>
      <div className="rounded my-1 py-1" style={{ background: "#e1e1e1" }}>
        <h6 style={{ marginBottom: "0", textAlign: "center" }}>
          {successRateBarData.heading}<a className="info" style={{
            border: '1px solid #000',
            borderRadius: '50px',
            fontSize: '9px',
            margin: 0,
            fontWeight: 'bold',
            padding: '0px 4px',
            color: 'black',
            verticalAlign: 'middle',
            marginLeft: '5px'
          }}>i</a>
        </h6>
      </div>
      <div className="h-30 rounded">
        <PlotlyComponent
          style={{ width: "100%" }}
          data={successRateBarData.data}
          layout={successRateBarData.layout}
          config={successRateBarData.config}
        />
      </div>
    </>
  );
};
