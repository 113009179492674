import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTypedSelector } from "hooks";

export const SolutionsTableRow = (solution) => {
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );

  return (
    <tr className="border-bottom">
      <td>
        <Card.Link href="#" className="text-primary fw-bold">
          {solution.solution_id}
        </Card.Link>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {solution.solution_name}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {solution.solution_description}
        </div>
      </td>
      <td>
        {solution.actual_total_benefit
          ? `$${solution.actual_total_benefit}`
          : "--"}
      </td>
      <td>
        {solution.est_annual_benefit ? `$${solution.est_annual_benefit}` : "--"}
      </td>
      <td key={solution.index}>
        <Link
          to={`/${activeWorkspaceName}/solutions/config/${solution.solution_id}`}>
          <Button
            variant="primary"
            size="sm"
            style={{
              backgroundColor: "blue",
              color: "white",
              paddingTop: 1,
              paddingBottom: 1,
            }}>
            Config
          </Button>
        </Link>
      </td>
    </tr>
  );
};
