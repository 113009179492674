import ActionTypes from "./ActionTypes";

export const changeActiveLicenseIdAction = (payload) => ({
    type: ActionTypes.ACTIVE_LICENSE_ID,
    payload,
});

export const fetchAllLicensesAction = (payload) => ({
    type: ActionTypes.FETCH_ALL_LICENSES,
    payload,
});

export const addLicenseAction = (payload) => ({
    type: ActionTypes.ADD_LICENSE,
    payload,
});

export const configLicenseAction = (payload) => ({
    type: ActionTypes.CONFIG_LICENSE,
    payload,
});

export const fetchLicensesAction = (payload) => ({
    type: ActionTypes.LICENSES,
    payload,
});

export const resetLicenseAction = () => ({
    type: ActionTypes.RESET_LICENSES,
});
