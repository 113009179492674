import axios from "axios";
import moment from "moment-timezone";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const DEV_API_URL = process.env.REACT_APP_BASE_API_URL;

export const BASE_API_URL = DEV_API_URL && ENVIRONMENT === "development"
  ? DEV_API_URL
  : "/api";


/**
 * Class-wrapper for Axios.
 * Implemented methods:
 * @method get
 * @method post
 * @method put
 * @method delete
 */
export class BaseAPIService {
  static instance;
  baseURL = BASE_API_URL;
  static getInstance() {
    if (!this.instance) {
      this.instance = new this();
    }
    return this.instance;
  }
  /**
   * Method for GET requests.
   * Need to provide generic type of data in the response
   * @param url
   * @returns Promise with provided generic type
   */

  async get({ url }) {
    const headers = await this.composeHeaders();
    const config = {
      url,
      headers: headers,
      method: "get",
      baseURL: this.baseURL,
    };
    const res = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        if (error.response) {
          console.error("GET Error response: ", error.response);
          if (error.response?.data.message) {
            throw error.response?.data.message.toString();
          }
          throw error.response?.statusText.toString();
        } else {
          throw Error("Unknown error.");
        }
      });
    return res;
  }

  /**
   * Method for DELETE requests.
   * Need to provide generic type of data in the response
   * @param url
   * @returns Promise with provided generic type
   */
  async delete({ url }) {
    const composedHeaders = await this.composeHeaders();
    const config = {
      url,
      headers: composedHeaders,
      method: "delete",
      baseURL: this.baseURL,
    };
    const res = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        if (error.response) {
          console.error("DELETE Error response: ", error.response);
          throw error.response?.statusText.toString();
        } else {
          throw Error("Unknown error.");
        }
      });
    return res;
  }

  /**
   * Method for PUT requests.
   * Need to provide generic type of data in the response
   * @param url
   * @param body - body object of the request
   * @returns Promise with provided generic type
   */
  async put({ url, body = {} }) {
    const concatedBody = this.concatBody(body);
    const composedHeaders = await this.composeHeaders();
    const config = {
      url,
      headers: composedHeaders,
      method: "put",
      baseURL: this.baseURL,
      data: concatedBody,
    };
    const res = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        if (error.response?.data.message) {
          throw error.response?.data.message.toString();
        }
        if (error.response.data.length > 0) {
          console.error("PUT Error response: ", error.response);
          let errors = [];
          for (error of error.response.data) {
            const problemField = error["loc"][0].replace("_", " ");
            errors.push(`${problemField}: ${error.msg}`);
          }
          const errorMessage = errors.join('; ')
          throw new Error(errorMessage);
        }
        if (error.message === "Network error") {
          throw error.message;
        } else {
          throw Error("Unknown error. Need debug!");
        }
      });
    return res;
  }

  /**
   * Method for POST requests.
   * Need to provide generic type of data in the response
   * @param url
   * @param body - body object of the request
   * @returns Promise with provided generic type
   */
  async post({ url, type, formData, body = {} }) {
    const concatenatedBody = formData ? formData : this.concatBody(body);
    const composedHeaders = await this.composeHeaders(type);
    const config = {
      url,
      headers: composedHeaders,
      method: "post",
      baseURL: this.baseURL,
      data: concatenatedBody,
    };
    const res = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error("Axios POST Error", error);
        const errors = ["Missing Authorization Header", "Token has expired"];
        if (errors.includes(error.response.data.msg)) {
          throw new Error("Logout");
        }
        if (error.response?.data.message) {
          throw error.response?.data.message.toString();
        }
        if (error.response.data.length > 0) {
          console.error("POST Error response: ", error.response);
          let errors = [];
          for (error of error.response.data) {
            const problemField = error["loc"][0].replace("_", " ");
            errors.push(`${problemField}: ${error.msg}`);
          }
          const errorMessage = errors.join('; ');
          throw new Error(errorMessage);
        } else {
          throw new Error("Interval server error!");
        }
      });
    return res;
  }

  /**
   * Method for refreshing the access token.
   * Need to provide generic type of data in the response
   * @param default_headers - request headers
   * @returns Promise with provided refreshed access token or an empty string or an Error
   */
  async refreshToken({ default_headers }) {
    const config = {
      url: "auth/refresh_token",
      headers: default_headers,
      method: "get",
      baseURL: this.baseURL,
    };
    const res = await axios(config)
      .then((data) => {
        const access_token_data = data.data["access_token_data"];
        const access_token = access_token_data["access_token"];
        localStorage.setItem(
          "access_token",
          JSON.stringify(data.data["access_token_data"])
        );
        return access_token;
      })
      .catch(function (error) {
        if (
          error.response.data.message ===
          "Failed to update token, please log in again"
        ) {
          return "";
        } else {
          throw Error("Unknown error.");
        }
      });
    return res;
  }

  // Helper methods
  /**
   * @param body
   * @returns stringified request body object
   */
  concatBody = (body) => {
    let concatedBody = {};
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        concatedBody = { ...concatedBody, [key]: value };
      }
    });
    return JSON.stringify(concatedBody);
  };

  /**
   * This method add Bearer token (JWT) into headers of the requests
   * @returns stringified request header object
   */
  composeHeaders = async (type) => {
    let accessToken = "";
    let refreshToken = "";
    let isAccessTokenValid;
    const now = moment().toDate();
    const accessTokenData = JSON.parse(localStorage.getItem("access_token"));
    if (accessTokenData) {
      accessToken = accessTokenData["access_token"];
      const accessTokenValidUntil = accessTokenData["valid_until"];
      isAccessTokenValid = moment(accessTokenValidUntil).toDate() > now;
    }
    const headerType =
      type === "form" ? "multipart/form-data" : "application/json";
    let default_headers = {
      "Content-Type": headerType,
    };
    if (accessToken && isAccessTokenValid) {
      default_headers = {
        ...default_headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    if (isAccessTokenValid === false) {
      const refreshTokenData = JSON.parse(
        localStorage.getItem("refresh_token")
      );
      refreshToken = refreshTokenData["refresh_token"];
      default_headers = {
        ...default_headers,
        Authorization: `Bearer ${refreshToken}`,
      };
      const refreshedAccessToken = await this.refreshToken({
        default_headers: default_headers,
      });
      if (refreshedAccessToken === "") {
        default_headers = {
          ...default_headers,
          Authorization: `Bearer ${accessToken}`,
        };
      } else {
        default_headers = {
          ...default_headers,
          Authorization: `Bearer ${refreshedAccessToken}`,
        };
      }
    }
    default_headers = { ...default_headers };
    return default_headers;
  };
}
