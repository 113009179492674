import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class WorkspaceAssignmentService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for creating workspace assignment item in database.
   * @param params
   * @returns workspace assignment id or rises Error
   */
  async create(params) {
    try {
      const urlWithParams = "/workspace_assignments/new?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data.workspace_assignment_id;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting workspace assignment item from database.
   * @param workspaceAssignmentId
   * @returns workspace db item or rises Error
   */
  async get(workspaceAssignmentId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/workspace_assignments/${workspaceAssignmentId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all workspace assignment items from database by user id.
   * @param userId
   * @returns list of workspace assignment items or rises Error
   */
  async getAllByUser(userId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/workspace_assignments/all/filter_by?&user_id=${userId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for checking if user has connected with workspace.
   * @param userId
   * @param workspaceId
   * @returns True if user has connected with workspace else False or rises Error
   */
  async checkUserHasWorkspace(userId, workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/workspace_assignments/user-has-workspace?user_id=${userId}&workspace_id=${workspaceId}`
      });
      return data.response;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all workspace assignment ids which are related to user by user id.
   * @param userId
   * @returns list of workspace assignment items or rises Error
   */
  async getIdsByUser(userId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/workspace_assignments/ids/filter_by?&user_id=${userId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all workspace assignment items from database by workspace id.
   * @param workspaceId
   * @returns list of workspace assignment items or rises Error
   */
  async getAllByWorkspaceId(workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/workspace_assignments/all/filter_by?&workspace_id=${workspaceId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all workspace assignment items from database by workspace and user ids.
   * @param workspaceId
   * @param userId
   * @returns list of workspace assignment items or rises Error
   */
  async getAllByWorkspaceAndUser(workspaceId, userId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/workspace_assignments/all/filter_by?&workspace_id=${workspaceId}&user_id=${userId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for deleting workspace assignment item from database.
   * @param workspaceAssignmentId
   * @returns "OK" message or rises Error
   */
  async delete(workspaceAssignmentId) {
    try {
      await this.BASE_SERVICE.delete({
        url: `/workspace_assignments/${workspaceAssignmentId}`,
      });
      return "OK";
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for updating workspace assignment item in database.
   * @param workspaceAssignmentId
   * @param data
   * @returns "OK" message or rises Error
   */
  async update(workspaceAssignmentId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/workspace_assignments/${workspaceAssignmentId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for updating workspace assignment`s verify status.
   * @param workspaceAssignmentId
   * @param data
   * @returns "OK" message or rises Error
   */
  async updateVerifyStatus(workspaceAssignmentId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/workspace_assignments/update_verify_status/${workspaceAssignmentId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting workspace assignment items from database by workspace id and pagination settings.
   * @param workspaceId
   * @param page
   * @param perPage
   * @returns pagination data with workspace assignment items or rises Error
   */
    async paginateByWorkspace(workspaceId, page, perPage) {
      try {
        const data = await this.BASE_SERVICE.get({
          url: `/workspace_assignments/filter_with_pagination?&workspace_id=${workspaceId}&page=${page}&per_page=${perPage}`,
        });
        return data;
      } catch (e) {
        throw Error(e);
      }
    }
}
