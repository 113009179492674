import { Button } from "react-bootstrap";

export const AdditionalBenefitsTableRow = (props) => {
  const { item, handleConfig, changeActiveId } = props;

  return (
    <tr className="border-bottom">
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {item.additional_benefit_id || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {item.benefit_name || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {item.benefit_description || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {item.benefit_category || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {item.benefit_value ? `$${item.benefit_value}` : "--"}
        </div>
      </td>
      <td>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            changeActiveId(item.additional_benefit_id);
            handleConfig(true);
          }}
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}>
          Config
        </Button>
      </td>
    </tr>
  );
};
