import React, { useEffect } from "react";
import { Route, useLocation, Switch, useHistory } from "react-router-dom";
import { RouteWithSidebar } from "components";
import { useAction, useTypedSelector } from "hooks";
import { Routes } from "routes";
import {
  Accordions,
  Alerts,
  Badges,
  Billing,
  BootstrapTables,
  Breadcrumbs,
  BusinessUnits,
  Buttons,
  Calendar,
  ComingSoon,
  Components,
  ConfigDashboardSolution,
  CreateDashboardSolution,
  CreateWorkspace,
  ConfigWorkspace,
  DashboardBacklog,
  DashboardDetailed,
  DashboardExecutive,
  DashboardSolutions,
  DataTables,
  DocsBuild,
  DocsChangelog,
  DocsDownload,
  DocsFolderStructure,
  DocsLicense,
  DocsOverview,
  DocsQuickStart,
  ForgotPassword,
  Forms,
  Ideas,
  Ideate,
  Invoice,
  Kanban,
  Lock,
  Map,
  Messages,
  Modals,
  Navbars,
  Navs,
  NotFound,
  PaginationComponent,
  PluginCalendar,
  PluginCharts,
  PluginDatatable,
  PluginDropzone,
  PluginMap,
  PluginSweetAlert,
  Popovers,
  Presentation,
  Pricing,
  Progress,
  Queues,
  ResetPassword,
  ServerError,
  SettingsConnectors,
  SettingsPlatforms,
  SettingsProfile,
  SettingsQuotes,
  SettingsPage,
  Sessions,
  SignIn,
  Signup,
  SingleMessage,
  Solutions,
  TablesTransactions,
  Tables,
  Tabs,
  Tasks,
  Toasts,
  Tooltips,
  Transactions,
  Users,
  VerifyEmail,
  WidgetsComponent,
  Widgets,
} from "pages";
import {
  AuthService,
  WorkspaceService,
  WorkspaceAssignmentService,
} from "services";
import {
  allEqualTo,
  cleanStorage,
  removeGapAndConvertToLowerCase,
} from "utils";

const authService = new AuthService();
const workspaceAssignmentService = new WorkspaceAssignmentService();
const workspaceService = new WorkspaceService();

export const App = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    logoutSuccessAction,
    deleteActiveWorkspaceAction,
    changeWorkspaceAction,
    disableDashboardsAction,
  } = useAction();
  const currentLocation = location.pathname;
  const currentWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );
  const userId = useTypedSelector((state) => state.user.userId);
  const deleteActiveWorkspace = useTypedSelector(
    (state) => state.workspace.deleteActiveWorkspace
  );
  const isDashboardsDisabled = useTypedSelector(
    (state) => state.workspace.disableDashboards
  );

  const noTokenAllowedPath = [
    "sign-in",
    "sign-up",
    "verify",
    "forgot-password",
    "reset-password",
    "lock",
    "404",
    "500",
    "home",
  ];

  const checkTokenAndPath = async () => {
    try {
      let isIncluded = false;
      const pathArray = currentLocation.split("/");
      const rootPath = allEqualTo(pathArray, "");

      // can load this pages without log in
      for (const item of pathArray) {
        if (noTokenAllowedPath.includes(item)) {
          isIncluded = true;
          const originPath = pathArray.join("/");
          history.push(originPath);
          return;
        }
      }

      // check if token in Local Storage is valid
      const response = await authService.checkToken();
      if (response) {
        // if token valid logic
        if (rootPath) {
          // if valid token and "/"" path
          isIncluded = true;
          history.push(`/${activeWorkspaceName}`);
          return;
        }

        // get list of all workspace assignments by current user
        const workspaceAssignments =
          await workspaceAssignmentService.getAllByUser(userId);
        if (workspaceAssignments.length > 0) {
          for (const item of workspaceAssignments) {
            // get workspace data by workspace_id of each workspace assignment
            const workspaceItem = await workspaceService.get(item.workspace_id);
            const workspaceName = removeGapAndConvertToLowerCase(
              workspaceItem.workspace_name
            );
            const disableDashboards = workspaceItem.disable_dashboards;

            // check is path includes name of workspace
            if (pathArray.includes(workspaceName)) {
              isIncluded = true;
              changeWorkspaceAction(
                workspaceName,
                workspaceItem.workspace_id,
                currentWorkspaceAssignmentId,
                userId
              );
              disableDashboardsAction(disableDashboards);
              const originPath = pathArray.join("/");

              // if path includes the user`s workspace`s name && disable_dashboard value = True && path is "/workspaceName/"
              if (disableDashboards === true && pathArray.length < 3) {
                history.push(`${originPath}${Routes.DashboardSolutions.path}`);
                return;
              }

              // if path includes the user`s workspace`s name && disable_dashboard value = False && path is "/workspaceName/"
              if (disableDashboards === false && pathArray.length < 3) {
                history.push(`${originPath}`);
                return;
              }
              history.push(originPath);
              return;
            }
          }
        }
        if (!isIncluded) {
          if (deleteActiveWorkspace) {
            deleteActiveWorkspaceAction(false);
            disableDashboardsAction(true);
            history.push(`/${activeWorkspaceName}/solutions`);
            return;
          } 
          history.push(`/${activeWorkspaceName}`);
        }
      }
    } catch (error) {
      cleanStorage();
      logoutSuccessAction();
      history.push(Routes.SignIn.path);
    }
  };

  const routeWithSideBarSwitcherMap = {
    Accordions: Accordions,
    Alerts: Alerts,
    Badges: Badges,
    Buttons: Buttons,
    Billing: Billing,
    BootstrapTables: BootstrapTables,
    Breadcrumbs: Breadcrumbs,
    BusinessUnits: BusinessUnits,
    Calendar: Calendar,
    ComingSoon: ComingSoon,
    Components: Components,
    ConfigDashboardSolution: ConfigDashboardSolution,
    ConfigWorkspace: ConfigWorkspace,
    CreateDashboardSolution: CreateDashboardSolution,
    CreateWorkspace: CreateWorkspace,
    DashboardSolutions: DashboardSolutions,
    DataTables: DataTables,
    DocsBuild: DocsBuild,
    DocsChangelog: DocsChangelog,
    DocsDownload: DocsDownload,
    DocsFolderStructure: DocsFolderStructure,
    DocsLicense: DocsLicense,
    DocsOverview: DocsOverview,
    DocsQuickStart: DocsQuickStart,
    Forms: Forms,
    Ideas: Ideas,
    Ideate: Ideate,
    Invoice: Invoice,
    Kanban: Kanban,
    Map: Map,
    Modals: Modals,
    Messages: Messages,
    Navbars: Navbars,
    Navs: Navs,
    PaginationComponent: PaginationComponent,
    PluginCalendar: PluginCalendar,
    PluginCharts: PluginCharts,
    PluginDatatable: PluginDatatable,
    PluginDropzone: PluginDropzone,
    PluginMap: PluginMap,
    PluginSweetAlert: PluginSweetAlert,
    Popovers: Popovers,
    Pricing: Pricing,
    Progress: Progress,
    Queues: Queues,
    Sessions: Sessions,
    SettingsConnectors: SettingsConnectors,
    SettingsPlatforms: SettingsPlatforms,
    SettingsProfile: SettingsProfile,
    SettingsQuotes: SettingsQuotes,
    SettingsPage: SettingsPage,
    SingleMessage: SingleMessage,
    Solutions: Solutions,
    TablesTransactions: TablesTransactions,
    Tables: Tables,
    Tabs: Tabs,
    Tasks: Tasks,
    Toasts: Toasts,
    Tooltips: Tooltips,
    Transactions: Transactions,
    Users: Users,
    WidgetsComponent: WidgetsComponent,
    Widgets: Widgets,
  };

  const nonLoggedInRouteSwitcherMap = {
    ForgotPassword: ForgotPassword,
    Lock: Lock,
    NotFound: NotFound,
    Presentation: Presentation,
    ResetPassword: ResetPassword,
    ServerError: ServerError,
    SignIn: SignIn,
    Signup: Signup,
    VerifyEmail: VerifyEmail,
  };

  const dashboardRouteWithSideBarSwitcherMap = {
    DashboardBacklog: DashboardBacklog,
    DashboardDetailed: DashboardDetailed,
    DashboardExecutive: DashboardExecutive,
  };

  useEffect(() => {
    checkTokenAndPath();
  }, [
    activeWorkspaceName,
    currentLocation,
    deleteActiveWorkspace,
    isDashboardsDisabled,
  ]);

  return (
    <Switch>
      {Object.entries(nonLoggedInRouteSwitcherMap).map(
        ([pathName, component], i) => {
          return (
            <Route
              key={`non-logged-in-route-${i}`}
              exec
              path={Routes[pathName].path}
              component={component}
            />
          );
        }
      )}
      {Object.entries(routeWithSideBarSwitcherMap).map(
        ([pathName, component], i) => {
          return (
            <RouteWithSidebar
              key={`route-with-sidebar-${i}`}
              exec
              path={`/${activeWorkspaceName}${Routes[pathName].path}`}
              component={component}
            />
          );
        }
      )}
      {!isDashboardsDisabled
        ? Object.entries(dashboardRouteWithSideBarSwitcherMap).map(
            ([pathName, component], i) => {
              return (
                <RouteWithSidebar
                  key={`dashboard-route-${i}`}
                  exec
                  path={`/${activeWorkspaceName}${Routes[pathName].path}`}
                  component={component}
                />
              );
            }
          )
        : null}
    </Switch>
  );
};
