import ActionTypes from "../actions/ActionTypes";

const userId = localStorage.getItem("user_id");

const initialState = {
  userId: userId,
  allUsersByWorkspace: [],
};

export const userReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case ActionTypes.ALL_USERS_BY_WORKSPACE:
      return {
        ...state,
        allUsersByWorkspace: action.payload,
      };
    default:
      return state;
  }
};
