import React from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb } from "react-bootstrap";
import { useTypedSelector } from "hooks";
import { Routes } from "routes";

export const SolutionHeader = (props) => {
  const { type } = props;
  const subHeader = type === "new" ? "Create New" : "Config";
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );

  return (
    <>
      <div className="py-4">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item>
            <HomeIcon className="icon icon-xs" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/${activeWorkspaceName}${Routes.DashboardSolutions.path}`}>
            Solutions
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{subHeader}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
};
