import React from 'react'
import * as d3 from "d3";
import "./tableComponent.css";

export const BacklogTable = (props) => {
    const { wb, BTFilterObject, tableName } = props;

    var chartWidth = "100px";
    // percent = d3.format("0%");

    var data = [
        { Approve: true, "Solution Name": "Credit", "Prioritization": 1, "Estimsted 3Y Return": 14432, "Development Cost $": 3454, "Annual Operation Cost": 1.0000, "3Y Net Return": 122, "Deveolpment Effort (Weeks)": 4 },
        { Approve: true, "Solution Name": "upload   ", "Prioritization": 4, "Estimsted 3Y Return": 24432, "Development Cost $": 6454, "Annual Operation Cost": 1.0000, "3Y Net Return": 132, "Deveolpment Effort (Weeks)": 9 },
        { Approve: true, "Solution Name": "Credit", "Prioritization": 2, "Estimsted 3Y Return": 54432, "Development Cost $": 454, "Annual Operation Cost": 1.0000, "3Y Net Return": 232, "Deveolpment Effort (Weeks)": 4 },
        { Approve: true, "Solution Name": "Credit", "Prioritization": 0, "Estimsted 3Y Return": 84432, "Development Cost $": 554, "Annual Operation Cost": 1.0000, "3Y Net Return": 232, "Deveolpment Effort (Weeks)": 7 },
        { Approve: false, "Solution Name": "Credit", "Prioritization": 4, "Estimsted 3Y Return": 7432, "Development Cost $": 9454, "Annual Operation Cost": 1.0000, "3Y Net Return": 12, "Deveolpment Effort (Weeks)": 4 },
        { Approve: true, "Solution Name": "Credit", "Prioritization": 4, "Estimsted 3Y Return": 45432, "Development Cost $": 3454, "Annual Operation Cost": 1.0000, "3Y Net Return": 732, "Deveolpment Effort (Weeks)": 5 },
        { Approve: true, "Solution Name": "Credit", "Prioritization": 1, "Estimsted 3Y Return": 23432, "Development Cost $": 654, "Annual Operation Cost": 1.0000, "3Y Net Return": 132, "Deveolpment Effort (Weeks)": 4 },
        { Approve: true, "Solution Name": "Credit", "Prioritization": 4, "Estimsted 3Y Return": 12432, "Development Cost $": 4254, "Annual Operation Cost": 1.0000, "3Y Net Return": 132, "Deveolpment Effort (Weeks)": 3 },
        { Approve: true, "Solution Name": "Credit", "Prioritization": 0, "Estimsted 3Y Return": 84432, "Development Cost $": 554, "Annual Operation Cost": 1.0000, "3Y Net Return": 232, "Deveolpment Effort (Weeks)": 7 },

    ];
    drawtableChart(data, chartWidth, "backlogGrid")

    function drawtableChart(data, chartWidth, containerId) {
        var totalEstimate = d3.sum(data, function (d, i) {
            return d['Estimsted 3Y Return'];
        });

        var totalDevelopment = d3.sum(data, function (d, i) {
            return d['Development Cost $'];
        });

        var totalAO = d3.sum(data, function (d, i) {
            return d['Annual Operation Cost'];
        });

        var total3YR = d3.sum(data, function (d, i) {
            return d['3Y Net Return'];
        });

        var totalDevelopmentF = d3.sum(data, function (d, i) {
            return d['Deveolpment Effort (Weeks)'];
        });

        // Sort the data in descending order
        // data.sort(function (a, b) { return d3.descending(a[1], b[1]) });

        // Setup the scale for the values for display, use abs max as max value
        var x = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['Estimsted 3Y Return']);
            })
            ])
            .range(["0%", "100%"]);

        var x1 = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['Development Cost $']);
            })
            ])
            .range(["0%", "100%"]);

        var x2 = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['3Y Net Return']);
            })
            ])
            .range(["0%", "100%"]);

        var x3 = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['Deveolpment Effort (Weeks)']);
            })
            ])
            .range(["0%", "100%"]);

        d3.select(".classTable").remove();

        var table = d3.select("#" + containerId).append("table")

            .attr("class", "  classTable")
            .attr("width", "100%")
            .attr("height", "300")
        //    if( window.innerWidth>666)
        var totalRow = table.append("tr");
        if (window.innerWidth > 746)
            totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[0]);
        totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[1]);
        if (window.innerWidth > 746)
            totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[2]);
        totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[3]);
        totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[4]);
        totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[5]);
        totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[6]);
        totalRow.append("th").attr("class", "totalDesc").text(Object.keys(data[0])[7]);



        // Create a table with rows and bind a data row to each table row
        var tr = table.selectAll("tr.data")
            .data(data)
            .enter()
            .append("tr")
            .attr("class", "datarow");

        // Set the even columns
        table.selectAll(".datarow").filter(":nth-child(odd)").attr("class", "datarow odd")

        // Create the name column
        if (window.innerWidth > 746) {
            tr.append("td").attr("class", "data name")
                .append("svg")
                .attr("width", "50px")
                .attr("height", "20px")
                .append("path")
                .attr("d", function (d, i) {
                    return d['Approve'] == true ? "M 10 12 L 4.5 6.6 L 6 5.2 L 10 9.2 L 19 0.1 L 20.5 1.5 z" : "M 17 15 a 0.5968 0.5968 90 0 1 -0.4244 -0.176 L 12.1424 10.3908 L 7.7092 14.824 a 0.6 0.6 90 0 1 -0.8484 0 L 5.4468 13.4084 a 0.6 0.6 90 0 1 0 -0.8484 L 9.88 8.1284 L 5.4468 3.6952 a 0.6 0.6 90 0 1 0 -0.8484 L 6.8624 1.4328 a 0.6 0.6 90 0 1 0.8484 0 L 12.1424 5.866 L 16.5756 1.4328 a 0.6 0.6 90 0 1 0.8484 0 L 18.838 2.8484 a 0.6 0.6 90 0 1 0 0.8484 L 14.4048 8.1284 L 18.838 12.5616 a 0.6 0.6 90 0 1 0 0.8484 L 17.4224 14.824 A 0.5964 0.5964 90 0 1 17 15 Z"
                })
        }


        tr.append("td").attr("class", "data name dataRightPadding")
            .text(function (d, i) {
                return d['Solution Name']
            })
        if (window.innerWidth > 746) {
            tr.append("td").attr("class", "data name")
                .style("text-align", "right")
                .style("padding-right", "10px")
                // .attr("class","bi bi-reception-3")
                .append("svg")
                .attr("width", 16)
                .attr("height", 16)
                .style("fill", "currentColor")
                .attr("class", function (d, i) {
                    if (d['Prioritization'] == 0) return "bi bi-reception-0"
                    if (d['Prioritization'] == 1) return "bi bi-reception-1"
                    if (d['Prioritization'] == 2) return "bi bi-reception-2"
                    if (d['Prioritization'] == 3) return "bi bi-reception-3"
                    if (d['Prioritization'] == 4) return "bi bi-reception-4"
                })
                .append("path")
                .attr("d", function (d, i) {
                    if (d['Prioritization'] == 0) return "M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['Prioritization'] == 3) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['Prioritization'] == 2) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4 5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['Prioritization'] == 1) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['Prioritization'] == 4) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z"
                })
        }
        // Create a column at the beginning of the table for the chart
        var chart = tr.append("td").attr("class", "chart")
            .attr("width", chartWidth)

        // Create the div structure of the chart 
        chart.append("div")
            .attr("class", "container tdLeftBorder rightBarText")
            .text(function (d, i) { return "$" + (d['Estimsted 3Y Return']) })
            .style("text-align", "right")
            .append("div")
            .style("height", "100%")
            .attr("class", "positive barLeftToRight");

        // Creates the positive div bar
        tr.select("div.positive")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x(d['Estimsted 3Y Return'])
            })

        chart = tr.append("td")
            .attr("class", "chart ")
            .attr("width", chartWidth)

        chart.append("div")
            .style("height", "100%")
            .attr("class", "container1 tdRightBorder rightBarText")
            .text(function (d, i) {
                return "$" + (d['Development Cost $'])
            })
            .style("text-align", "end")
            .append("div")
            .style("height", "100%")
            .style("z-index", -1)
            .attr("class", "positive1  barRightToLeft");

        // Creates the positive div bar
        tr.select("div.positive1")
            // .style("text-align", "end")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x1(d['Development Cost $'])
            })

            .style("right", "0")

        tr.append("td")
            .attr("width", chartWidth)
            .text(function (d, i) {
                return "$" + d3.format(".4f")(d['Annual Operation Cost'])
            })
            .style("text-align", "right")

        chart = tr.append("td")
            .attr("class", "chart")
            .attr("width", chartWidth)


        chart.append("div")
            .attr("class", "container tdLeftBorder rightBarText")
            .text(function (d, i) {
                return "$" + (d['3Y Net Return'])
            })
            .style("text-align", "right")
            .append("div")
            .style("height", "100%")
            .attr("class", "positive3 barLeftToRight");

        tr.select("div.positive3")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x2(d['3Y Net Return'])
            })

        chart = tr.append("td")
            .attr("class", "chart")
            .attr("width", chartWidth)


        chart.append("div")
            .attr("class", "container tdLeftBorder rightBarText")
            .text(function (d, i) {
                return (d['Deveolpment Effort (Weeks)'])
            })
            .style("text-align", "right")
            .append("div")
            .style("height", "100%")
            .attr("class", "positive4 barLeftToRight");

        tr.select("div.positive4")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x3(d['Deveolpment Effort (Weeks)'])
            })

        var totalTr = table.append("tr")
            .attr("class", "bordered");
        // Add the Total row
        totalTr.append("td")
            .attr("class", "totalDesc")
            .text("Total")
        if (window.innerWidth > 746) {
            totalTr.append("td")
                .attr("class", "totalDesc")
                .text("");

            totalTr.append("td")
                .attr("class", "totalDesc")
                .text("");
        }
        totalTr.append("td")
            .attr("class", "totalDesc")
            .text(("$" + totalEstimate))
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text(("$" + totalDevelopment))
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text("$" + totalAO)
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text(("$" + total3YR))
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text((totalDevelopmentF))
            .style("text-align", "right")
    }

    return (
        <>
            <div className="rounded my-1 py-1 mb-2" style={{ background: "#e1e1e1" }}>
                <h6 style={{ marginBottom: "0", textAlign: "center" }}>
                    {tableName}
                </h6>
            </div>
            <div id="backlogGrid" height="300px"></div>
        </>
    )
}
