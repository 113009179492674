import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { CustomToggle } from "pages/components";
import { AdditionalBenefitsService } from "services";
import { checkEmptyField, handleError } from "utils";

const additionalBenefitsService = new AdditionalBenefitsService();

const defaultCategoryTitle = "Choose Category";

export const AdditionalBenefitModal = (props) => {
  const {
    show,
    onHide,
    workspaceId,
    activeItemId,
    changeActiveId,
    itemHasChanged,
  } = props;
  const history = useHistory();
  // notifiers
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  // fields
  const [benefitId, setBenefitId] = useState("");
  const [benefitName, setBenefitName] = useState("");
  const [benefitDescription, setBenefitDescription] = useState("");
  const [benefitEstValue, setBenefitEstValue] = useState("");
  const [benefitCategory, setBenefitCategory] = useState("");
  const [userCategory, setUserCategory] = useState("");
  // lists
  const [categories, setCategories] = useState([]);
  // booleans
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [saveBtnIsShown, setSaveBtnIsShown] = useState(true);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(true);
  const [itemIsUpdated, setItemIsUpdated] = useState(false);
  // values
  const [title, setTitle] = useState(defaultCategoryTitle);

  const fetchAdditionalBenefits = async ({ queryKey }) => {
    const [_, workspaceId] = queryKey;
    if (workspaceId) {
      let benefitCategories = [];
      const response = await additionalBenefitsService.getAllByWorkspace(
        workspaceId
      );
      if (response) {
        for (const item of response) {
          if (!benefitCategories.includes(item.benefit_category)) {
            benefitCategories.push(item.benefit_category);
          }
        }
      }
      setCategories(benefitCategories);
      return benefitCategories;
    }
  };

  const additionalBenefitsQuery = useQuery(
    ["additionalBenefitsModalQuery", workspaceId],
    fetchAdditionalBenefits,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
  };

  const failedDuringDeleting = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when deleting.");
  };

  const checkFields = () => {
    const benefitCategoryIsField = checkEmptyField(
      benefitCategory,
      "Benefit Category",
      setError
    );
    const benefitEstValueIsField = checkEmptyField(
      benefitEstValue,
      "Est. Value (p.a.)",
      setError
    );
    const benefitDescriptionIsField = checkEmptyField(
      benefitDescription,
      "Benefit Description",
      setError
    );
    const benefitNameIsField = checkEmptyField(
      benefitName,
      "Benefit Name",
      setError
    );
    const responses = [
      benefitNameIsField,
      benefitDescriptionIsField,
      benefitEstValueIsField,
      benefitCategoryIsField,
    ];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const getAdditionalBenefit = async () => {
    try {
      const item = await additionalBenefitsService.get(activeItemId);
      if (item) {
        setSaveBtnIsShown(false);
        setDeleteBtnIsDisabled(false);
        setBenefitId(item.additional_benefit_id);
        setBenefitName(item.benefit_name);
        setBenefitDescription(item.benefit_description);
        setBenefitCategory(item.benefit_category);
        setTitle(item.benefit_category);
        setBenefitEstValue(item.benefit_value);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const createAdditionalBenefit = async () => {
    try {
      const data = {
        benefitName: benefitName,
        benefitDescription: benefitDescription,
        benefitCategory: benefitCategory,
        benefitValue: benefitEstValue,
        workspaceId: workspaceId,
      };
      return await additionalBenefitsService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateAdditionalBenefit = async () => {
    try {
      const data = {
        benefit_name: benefitName,
        benefit_description: benefitDescription,
        benefit_category: benefitCategory,
        benefit_value: benefitEstValue,
        workspace_id: workspaceId,
      };
      return await additionalBenefitsService.update(activeItemId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      const response = await createAdditionalBenefit();
      if (response) {
        additionalBenefitsQuery.refetch();
        handleHideModal();
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      const response = await updateAdditionalBenefit();
      if (response) {
        setItemIsUpdated(false);
        additionalBenefitsQuery.refetch();
        handleHideModal();
      }
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await additionalBenefitsService.delete(activeItemId);
      additionalBenefitsQuery.refetch();
      handleHideModal();
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (categories.includes(userCategory)) {
      setError("Such category exists!");
      return;
    }
    const updatedCategories = [userCategory, ...categories];
    setCategories(updatedCategories);
    setUserCategory("");
  };

  const handleHideModal = () => {
    onHide(true);
    changeActiveId("");
    setError("");
    setStatus("");
    setBenefitId("");
    setBenefitName("");
    setBenefitDescription("");
    setBenefitEstValue("");
    setBenefitCategory("");
    setItemIsUpdated(false);
    setTitle(defaultCategoryTitle);
    itemHasChanged(true);
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    setSaveBtnIsShown(true);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (activeItemId) {
      if (!itemIsUpdated) {
        getAdditionalBenefit();
      }
    }
  }, [error, activeItemId, categories]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleHideModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {error ? (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        ) : null}
        {status ? (
          <Alert key="danger" variant="danger">
            {status}
          </Alert>
        ) : null}
        <Row>
          <Col md={5} className="mb-3">
            <Form.Group id="benefitId">
              <Form.Label>Benefit ID</Form.Label>
              <Form.Control readOnly type="text" value={benefitId || ""} />
            </Form.Group>
            <Form.Group id="benefitName">
              <Form.Label>Benefit Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={benefitName || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setBenefitName(e.target.value);
                  setItemIsUpdated(true);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={7} className="mb-3">
            <Form.Group id="benefitDescription">
              <Form.Label>Benefit Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                maxLength={255}
                value={benefitDescription || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setBenefitDescription(e.target.value);
                  setItemIsUpdated(true);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="mb-3">
            <Form.Group id="benefitEstValue">
              <Form.Label>Est. Value (p.a.)</Form.Label>
              <Form.Control
                type="number"
                value={benefitEstValue || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setBenefitEstValue(e.target.value);
                  setItemIsUpdated(true);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={7} className="mb-3">
            <Form.Group id="benefitCategory">
              <Form.Label>Benefit Category</Form.Label>
              {categories.length > 0 ? (
                <Dropdown
                  as={ButtonGroup}
                  className="border border-dark rounded pt-2 pb-2 pe-2 ps-2 w-100">
                  <Dropdown.Toggle
                    id="dropdown-custom-components"
                    as={CustomToggle}>
                    {title}
                  </Dropdown.Toggle>
                  {categories.length > 0 ? (
                    <Dropdown.Menu>
                      <Row>
                        <Col md={9}>
                          <Form.Control
                            id="userCategory"
                            type="text"
                            maxLength={20}
                            placeholder="Add own category..."
                            value={userCategory}
                            className="ms-3"
                            onChange={(e) => {
                              setError("");
                              setStatus("");
                              setUserCategory(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Button
                            disabled={userCategory ? false : true}
                            onClick={handleAddCategory}
                            className="text-center pb-2 pt-2 ps-2 pe-2">
                            Add
                          </Button>
                        </Col>
                      </Row>
                      <Dropdown.Divider />
                      <Dropdown.Header>
                        Or choose existed benefit category:
                      </Dropdown.Header>
                      <Dropdown.Divider />
                      {categories.map((category, i) => {
                        return (
                          <Dropdown.Item
                            key={`category-${i}`}
                            eventKey={category}
                            active={benefitCategory === category}
                            onClick={() => {
                              setError("");
                              setStatus("");
                              setTitle(category);
                              setBenefitCategory(category);
                              setItemIsUpdated(true);
                            }}>
                            {category}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  ) : null}
                </Dropdown>
              ) : (
                <Form.Control
                  type="text"
                  value={benefitCategory || ""}
                  onChange={(e) => {
                    setError("");
                    setStatus("");
                    setTitle(e.target.value);
                    setBenefitCategory(e.target.value);
                    setItemIsUpdated(true);
                  }}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          {saveBtnIsShown ? (
            <Col md={6}>
              <Button
                disabled={saveBtnIsDisabled}
                variant="primary"
                type="submit"
                onClick={handleSave}>
                Save
              </Button>
            </Col>
          ) : null}
          {!saveBtnIsShown ? (
            <Col md={6}>
              <Button variant="primary" type="submit" onClick={handleUpdate}>
                Update
              </Button>
            </Col>
          ) : null}
          {activeItemId ? (
            <Col md={6}>
              <Button
                disabled={deleteBtnIsDisabled}
                variant="primary"
                onClick={handleDelete}>
                Delete
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
