import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class GroupService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for getting all group items from database by workspace id.
    * @param workspaceId
    * @returns list of groups or rises Error
    */
    async getAllByWorkspace(workspaceId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/groups/all/filter_by?&workspace_id=${workspaceId}`,
                }
            );
            return data;
        } catch (e) {
            throw new Error(e.message);
        }
    }

    /**
     * Method is using for creating group item in database.
     * @param params
     * @returns group id or rises Error
     */
        async create(params) {
        try {
            const urlWithParams = '/groups/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.group_id;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for getting group item from database.
     * @param groupId
     * @returns group db item or rises Error
     */
        async get(groupId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/groups/${groupId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for deleting group item from database.
     * @param groupId
     * @returns "OK" message or rises Error
     */
         async delete(groupId) {
            try {
                await this.BASE_SERVICE.delete(
                    {
                        url: `/groups/${groupId}`,
                    }
                );
                return "OK";
            } catch (e) {
                throw Error(e);
            }
    };
    
    /**
    * Method is using for updating group item in database.
    * @param groupId
    * @param data
    * @returns "OK" message or rises Error
    */
    async update(groupId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/groups/${groupId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };
};
