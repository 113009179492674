import ActionTypes from "./ActionTypes";

export const setTechnologiesTabAction = () => ({
    type: ActionTypes.TECHNOLOGIES_TAB,
});

export const setTechnologyConfigTabAction = () => ({
    type: ActionTypes.TECHNOLOGY_CONFIG_TAB,
});

export const setTechnologyNewTabAction = () => ({
    type: ActionTypes.TECHNOLOGY_NEW_TAB,
});

export const resetTechnologyTabsAction = () => ({
    type: ActionTypes.RESET_TECHNOLOGY_TABS,
});
