import { BaseAPIService } from "./base";

export class TransactionService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for getting all transaction items from database by workspace id.
   * @param workspaceId
   * @returns list of transaction items or rises Error
   */
  async getAllByWorkspace(workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/transaction/all/filter_by?&workspace_id=${workspaceId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }
  /**
   * Method is using for getting transaction items from database by workspace id and pagination settings.
   * @param workspaceId
   * @param page
   * @param perPage
   * @returns pagination data with transaction items or rises Error
   */
  async paginateByWorkspace(workspaceId, page, perPage) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/transaction/filter_with_pagination?&workspace_id=${workspaceId}&page=${page}&per_page=${perPage}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting number of transaction items from database by queue id and status.
   * @param queueId
   * @param status
   * @returns number of transaction items or rises Error
   */
  async countByQueueAndStatus(queueId, status) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/transaction/count/by?&queue_id=${queueId}&status=${status}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for creating many transaction items in database.
   * @param params
   * @returns message response or rises Error
   */
  async createMany(workspaceId, formData) {
    try {
      const response = await this.BASE_SERVICE.post({
        url: `/transaction/add_many/${workspaceId}`,
        type: "form",
        formData: formData,
        body: {},
      });
      return response;
    } catch (e) {
      throw Error(e);
    }
  }
}
