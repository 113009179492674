import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query"; 
import { useTypedSelector } from "hooks";
import {
  BusinessUnitService,
  UserService,
  WorkspaceAssignmentService,
} from "services";
import { checkEmptyField, handleError, isValidEmail } from "utils";

const businessUnitService = new BusinessUnitService();
const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

const organisationRoleOptions = [
  "",
  "Contractor",
  "Staff",
  "Manager",
  "Director",
  "CXO",
  "Business Owner",
];

const fetchBusinessUnits = async ({ queryKey }) => {
  const [_, activeWorkspaceId] = queryKey;
  if (activeWorkspaceId) {
    return await businessUnitService.getAllByWorkspace(activeWorkspaceId);
  }
};

export const AddUserModal = (props) => {
  const { show, onHide, refetch } = props;
  const history = useHistory();
  // general
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [organizationRole, setOrganizationRole] = useState("");
  const [businessUnit, setBusinessUnit] = useState("");
  // ids
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const defaultWorkspaceId = useTypedSelector(
    (state) => state.workspace.defaultWorkspaceId
  );
  // booleans
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [itemIsSaved, setItemIsSaved] = useState(false);
  const activeWorkspaceIsDefault =
    Number(defaultWorkspaceId) === Number(activeWorkspaceId);
  // style
  const statusColor = isSaveTypeSuccessful ? "success" : "danger";
  const closeButtonText = itemIsSaved ? "Close" : "Cancel";

  const businessUnitsQuery = useQuery(
    ["addUserBusinessUnits", activeWorkspaceId],
    fetchBusinessUnits,
    { onError: (e) => handleError(e, history) }
  );
  const businessUnits = businessUnitsQuery.data ? businessUnitsQuery.data : [];

  const isSavedSuccessfully = () => {
    setStatus("User added successfully!");
    setIsStatusTypeSuccessful(true);
    setItemIsSaved(true);
  };

  const failedDuringSaving = (error) => {
    const errorMsg = error
      ? error
      : "Failure occurred when saving. Please, try again.";
    setStatus(errorMsg);
    setIsStatusTypeSuccessful(false);
  };

  const checkUserFields = () => {
    const firstNameIsField = checkEmptyField(firstName, "First Name", setError);
    const lastNameIsField = checkEmptyField(lastName, "Last Name", setError);
    const emailField = checkEmptyField(email, "Email", setError);
    const organizationRoleField = checkEmptyField(
      organizationRole,
      "Organization Role",
      setError,
      "select"
    );
    const responses = [
      firstNameIsField,
      lastNameIsField,
      emailField,
      organizationRoleField,
    ];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const createUserWithoutPassword = async () => {
    try {
      const data = {
        firstName: firstName,
        lastName: lastName,
        profilePhotoUrl: photoUrl,
        phoneNumber: phone,
        email: email,
        workspaceId: defaultWorkspaceId,
        activeWorkspaceId: defaultWorkspaceId,
      };
      return await userService.createWithoutPassword(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createWorkspaceAssignment = async (data) => {
    try {
      return await workspaceAssignmentService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const fetchDefaultWA = async (userId) => {
    try {
      const response =
        await workspaceAssignmentService.getAllByWorkspaceAndUser(
          defaultWorkspaceId,
          userId
        );
      if (response.length > 0) return response[0];
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleUserExistsForCurrentWorkspace = async (userId) => {
    try {
      const workspaceAssignments =
        await workspaceAssignmentService.getAllByWorkspaceAndUser(
          activeWorkspaceId,
          userId
        );
      if (workspaceAssignments.length > 0) return true;
      return false;
    } catch (e) {
      handleError(e, history);
    }
  };

  const findUserByEmail = async () => {
    try {
      const users = await userService.getAllByEmail(email);
      if (users.length > 0) return users[0].user_id;
      return false;
    } catch (e) {
      handleError(e, history);
    }
  };

  const sendInviteToNewWorkspace = async (userId, workspaceId) => {
    try {
      const data = {
        userId: userId,
        activeWorkspaceId: workspaceId,
      };
      return await userService.sendInviteToWorkspace(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createNewUser = async () => {
    const userId = await createUserWithoutPassword();
    if (userId) {
      // create workspace assignment with default workspace
      const defaultData = {
        isPending: true,
        userId: userId,
        organizationRole: organizationRole,
        businessUnitId: activeWorkspaceIsDefault ? businessUnit : null,
        workspaceId: defaultWorkspaceId,
      };
      const defaultWorkspaceAssignmentId = await createWorkspaceAssignment(
        defaultData
      );
      if (defaultWorkspaceAssignmentId) {
        // create workspace assignment with active workspace which is not default
        if (!activeWorkspaceIsDefault) {
          const data = {
            isPending: true,
            organizationRole: organizationRole,
            businessUnitId: businessUnit,
            userId: userId,
            workspaceId: activeWorkspaceId,
          };
          const response = await createWorkspaceAssignment(data);
          if (response) {
            isSavedSuccessfully();
            refetch();
            return;
          }
        }
        isSavedSuccessfully();
        refetch();
      }
    }
  };

  const addUserToCurrentWorkspace = async (userId) => {
    // check if user exists for current workspace
    const userExists = await handleUserExistsForCurrentWorkspace(userId);
    if (userExists) {
      setError(
        "You have already added user with such email to the current workspace!"
      );
      setSaveBtnIsDisabled(false);
      return;
    }
    // check is user has verified workspace assignmnent with default workspace
    const defaultWA = await fetchDefaultWA(userId);
    if (defaultWA) {
      // create workspace assignment with active workspace which is not default
      const data = {
        isPending: defaultWA.is_verified === true ? false : true,
        isVerified: defaultWA.is_verified === true ? true : false,
        organizationRole: organizationRole,
        businessUnitId: businessUnit,
        userId: userId,
        workspaceId: activeWorkspaceId,
      };
      const activeWorkspaceAssignmentId = await createWorkspaceAssignment(data);
      if (activeWorkspaceAssignmentId) {
        // send email to user with invite to new workspace
        const invitedUserId = await sendInviteToNewWorkspace(
          userId,
          activeWorkspaceId
        );
        if (invitedUserId) {
          isSavedSuccessfully();
          refetch();
          return;
        }
      }
    }
    failedDuringSaving();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setError("");
    setStatus("");
    setSaveBtnIsDisabled(true);
    const allUserFieldsAreFilled = checkUserFields();
    if (allUserFieldsAreFilled) {
      const userExistsId = await findUserByEmail(); // check if user with such email exists
      if (userExistsId) {
        await addUserToCurrentWorkspace(userExistsId);
      }
      await createNewUser();
    }
    setSaveBtnIsDisabled(false);
  };

  const handleHideModal = () => {
    onHide(true);
    setItemIsSaved(false);
    setSaveBtnIsDisabled(false);
    setError("");
    setStatus("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setOrganizationRole("");
    setBusinessUnit("");
    setPhotoUrl("");
  };

  useEffect(() => {
    if (email && !isValidEmail(email)) {
      setError("Email is not valid!");
    }
  }, [email]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ marginLeft: "40%" }}>New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        ) : null}
        {saveStatus ? (
          <Alert key={statusColor} variant={statusColor}>
            {saveStatus}
          </Alert>
        ) : null}
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={firstName || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setFirstName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={lastName || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setLastName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                value={phone || ""}
                maxLength={12}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setPhone(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="organizationRole">
              <Form.Label>Organization Role</Form.Label>
              <Form.Control
                as="select"
                value={organizationRole || ""}
                className="mb-0"
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setOrganizationRole(e.target.value);
                }}>
                {organisationRoleOptions.map((role, i) => {
                  return (
                    <option key={`organizationRole-${i}`} value={role}>
                      {role}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="businessUnit">
              <Form.Label>Business Unit</Form.Label>
              <Form.Control
                as="select"
                value={businessUnit || ""}
                className="mb-0"
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setBusinessUnit(e.target.value);
                }}>
                <option value=""></option>
                {businessUnits.length > 0
                  ? businessUnits.map((businessUnitItem, i) => {
                      return (
                        <option
                          key={`businessUnit-${i}`}
                          value={businessUnitItem.business_unit_id}>
                          {businessUnitItem.business_unit_name}
                        </option>
                      );
                    })
                  : null}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group id="profilePhotoUrl">
              <Form.Label>Profile Photo Url</Form.Label>
              <Form.Control
                type="text"
                value={photoUrl || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setPhotoUrl(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Col md={itemIsSaved ? 12 : 6} xs={itemIsSaved ? 12 : 6}>
            <Button variant="secondary" onClick={handleHideModal}>
              {closeButtonText}
            </Button>
          </Col>
          {!itemIsSaved ? (
            <Col md={6} xs={6}>
              <Button
                disabled={saveBtnIsDisabled}
                variant="primary"
                type="submit"
                onClick={handleSave}>
                Save
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
