import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useAction } from "hooks";
import { SolutionRelatedTable } from "../tables";
import { QueueBenefitModal } from "../QueueBenefitModal";
import { CustomToggle } from "pages/components";
import {
  AdditionalBenefitsService,
  LicenseService,
  QueueService,
  QueueBenefitService,
  QueueHealthService,
  SolutionService,
  WorkspaceService,
} from "services";
import { checkEmptyField, handleError } from "utils";

const additionalBenefitsService = new AdditionalBenefitsService();
const licenseService = new LicenseService();
const solutionService = new SolutionService();
const queueService = new QueueService();
const queueBenefitService = new QueueBenefitService();
const queueHealthService = new QueueHealthService();
const workspaceService = new WorkspaceService();

const frequencyOptions = [
  "Everyday",
  "Working days",
  "Weekly",
  "Monthly",
  "Quarterly",
  "Bi-annually",
  "Annually",
];
const costTypeOptions = ["Hourly", "Annual Salary"];
const queueHealthOptions = ["Stable", "Improving", "Unstable", "Stopped"];
const defaultHealthChangeReason = "Initial deployment";
const defaultQueueHealth = queueHealthOptions[0];
const defaultCostType = costTypeOptions[0];
const defaultFrequency = frequencyOptions[2];
const defaultEffectiveWorkingHoursPA = 1200;

const fetchLicensesByWorkspace = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await licenseService.getAllByWorkspace(workspaceId);
  }
};

const fetchSolutionData = async ({ queryKey }) => {
  const [_, solutionId] = queryKey;
  if (solutionId) {
    return await solutionService.get(solutionId);
  }
};

const fetchQueuesByWorkspace = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await queueService.getAllByWorkspaceId(workspaceId);
  }
};

const fetchQueueBenefits = async ({ queryKey }) => {
  const [_, activeQueueId] = queryKey;
  if (activeQueueId) {
    return await queueBenefitService.getAllByQueueId(activeQueueId);
  }
};

const fetchAdditionalBenefits = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await additionalBenefitsService.getAllByWorkspace(workspaceId);
  }
};

export const QueuesTab = (props) => {
  const { type, solutionId, workspaceId, queueId } = props;
  const history = useHistory();
  const { queueChangesAction } = useAction();
  const [behaveType, setBehaveType] = useState(type);
  // notifiers
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields to edit
  const [queueName, setQueueName] = useState("");
  const [queueKey, setQueueKey] = useState("");
  const [queueDescription, setQueueDescription] = useState("");
  const [frequency, setFrequency] = useState(defaultFrequency);
  const [estVolume, setEstVolume] = useState("");
  const [timePerTrans, setTimePerTrans] = useState("");
  const [targetCompletionRate, setTargetCompletionRate] = useState("");
  const [costType, setCostType] = useState(defaultCostType);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [annualSalary, setAnnualSalary] = useState(0);
  const [queueHealth, setQueueHealth] = useState(defaultQueueHealth);
  const [healthChangeReason, setHealthChangeReason] = useState(
    defaultHealthChangeReason
  );
  const [relatedTechLicense, setRelatedTechLicense] = useState("");
  const [userQueueName, setUserQueueName] = useState("");
  // booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [isDisabledHourlyRate, setIsDisableHourlyRate] = useState(false);
  const [isDisabledAnnualSalary, setIsDisableAnnualSalary] = useState(true);
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
  const [isRequiredHealthChangeReason, setIsRequiredHealthChangeReason] =
    useState(false);
  const [updateQueueHealthItem, setUpdateQueueHealthItem] = useState(false);
  const [queueBenefitModalShow, setQueueBenefitModalShow] = useState(false);
  // ids
  const [activeQueueId, setActiveQueueId] = useState(queueId);
  const [activeQueueBenefitId, setActiveQueueBenefitId] = useState();
  const [activeQueueHealthId, setActiveQueueHealthId] = useState("");
  // constants
  const [effectiveWorkingHoursPA, setEffectiveWorkingHoursPA] = useState(
    defaultEffectiveWorkingHoursPA
  );
  const statusColor = isSaveTypeSuccessful ? "green" : "red";

  const licensesQuery = useQuery(
    ["queueLicenses", workspaceId],
    fetchLicensesByWorkspace,
    { onError: (e) => handleError(e, history) }
  );

  const solutionQuery = useQuery(
    ["queueSolution", solutionId],
    fetchSolutionData,
    { onError: (e) => handleError(e, history) }
  );

  const queuesQuery = useQuery(
    ["queuesByWorkspace", workspaceId],
    fetchQueuesByWorkspace,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const queues = queuesQuery.data
    ? queuesQuery.data.filter(
        (queue) => queue.solution_id === Number(solutionId)
      )
    : [];

  const queuesWithoutSolution = queuesQuery.data
    ? queuesQuery.data.filter((queue) => queue.solution_id === null)
    : [];

  const queueBenefitsQuery = useQuery(
    ["queueBenefits", activeQueueId],
    fetchQueueBenefits,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const additionalBenefitsQuery = useQuery(
    ["additionalBenefitsQuery", workspaceId],
    fetchAdditionalBenefits,
    {
      onError: (e) => handleError(e, history),
    }
  );
  const additionalBenefits = additionalBenefitsQuery.data
    ? additionalBenefitsQuery.data
    : [];

  const isSavedSuccessfully = () => {
    setStatus("Changes saved successfully.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
    setIsStatusTypeSuccessful(false);
  };

  const failedDuringDeleting = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when deleting");
    setIsStatusTypeSuccessful(false);
  };

  const createQueue = async () => {
    try {
      const data = {
        queueName: queueName,
        queueKey: queueKey,
        queueDescription: queueDescription,
        frequency: frequency,
        costType: costType,
        estVolumeFrequency: estVolume,
        timePerTrans: timePerTrans,
        hourlyRate: Number(hourlyRate),
        annualSalary: Number(annualSalary),
        targetCompletionRate: targetCompletionRate,
        solutionId: solutionId,
        licenseId: relatedTechLicense,
        workspaceId: workspaceId,
      };
      return await queueService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateQueue = async () => {
    try {
      if (activeQueueId) {
        const data = {
          queue_name: queueName,
          queue_key: queueKey,
          queue_Description: queueDescription,
          frequency: frequency,
          cost_type: costType,
          est_avg_volume: estVolume,
          min_per_trans: timePerTrans,
          hourly_rate: Number(hourlyRate),
          annual_salary: Number(annualSalary),
          target_completion_rate: targetCompletionRate,
          solution_id: solutionId,
          license_id: relatedTechLicense,
          workspace_id: workspaceId,
        };
        return await queueService.update(activeQueueId, data);
      }
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createQueueHealth = async (queueId) => {
    try {
      const data = {
        healthName: queueHealth,
        healthChangeReason: healthChangeReason,
        queueId: queueId,
        workspaceId: workspaceId,
      };
      return await queueHealthService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateQueueHealth = async () => {
    try {
      const data = {
        health_name: queueHealth,
        change_reason: healthChangeReason,
        queue_id: activeQueueId,
        workspace_id: workspaceId,
      };
      return await queueHealthService.update(activeQueueHealthId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const deleteQueue = async () => {
    try {
      return await queueService.delete(activeQueueId);
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (activeQueueId) {
      const queueResponse = await deleteQueue();
      if (queueResponse) {
        queueChangesAction(true);
        solutionQuery.refetch();
        queuesQuery.refetch();
        setStatus("Deletion successful");
        setIsStatusTypeSuccessful(true);
        handleNewQueue();
      }
    }
  };

  const fetchQueueHealthByQueueId = async (queueId) => {
    try {
      if (queueId) {
        const response = await queueHealthService.getAllByQueueId(queueId);
        const relatedQueueHealth = response[0];
        if (relatedQueueHealth) {
          setActiveQueueHealthId(relatedQueueHealth["queue_health_id"]);
          setQueueHealth(relatedQueueHealth["health_name"]);
          setHealthChangeReason(relatedQueueHealth["change_reason"]);
          return;
        }
        setQueueHealth(defaultQueueHealth);
        setHealthChangeReason(defaultHealthChangeReason);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchWorkspaceById = async () => {
    try {
      if (workspaceId) {
        const response = await workspaceService.get(workspaceId);
        if (response) {
          setEffectiveWorkingHoursPA(response.effective_working_hours);
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const changeActiveQueue = async (queues) => {
    if (activeQueueId) {
      const item = queues.find((x) => x.queue_id === activeQueueId);
      if (item) {
        setBehaveType("config");
        setQueueName(item.queue_name);
        setQueueKey(item.queue_key);
        setQueueDescription(item.queue_description);
        setFrequency(item.frequency);
        setEstVolume(item.est_avg_volume);
        setTimePerTrans(item.min_per_trans);
        setCostType(item.cost_type);
        setHourlyRate(item.hourly_rate);
        setAnnualSalary(item.annual_salary);
        setRelatedTechLicense(item.license_id);
        setTargetCompletionRate(item.target_completion_rate);
        await fetchQueueHealthByQueueId(item.queue_id);
      }
    }
  };

  const handleNewQueue = () => {
    setError("");
    setStatus("");
    setBehaveType("new");
    setActiveQueueId("");
    setActiveQueueHealthId("");
    setActiveQueueBenefitId("");
    setQueueName("");
    setQueueKey("");
    setQueueDescription("");
    setFrequency(defaultFrequency);
    setEstVolume("");
    setTimePerTrans("");
    setCostType(defaultCostType);
    setHourlyRate(0);
    setAnnualSalary(0);
    setQueueHealth(defaultQueueHealth);
    setHealthChangeReason(defaultHealthChangeReason);
    setRelatedTechLicense("");
    setTargetCompletionRate(0);
    setUserQueueName("");
    setEffectiveWorkingHoursPA(defaultEffectiveWorkingHoursPA);
    queueChangesAction(false);
  };

  const checkFields = () => {
    let hourlyRateRes = "OK";
    let annualSalaryRes = "OK";
    if (costType === defaultCostType) {
      hourlyRateRes = checkEmptyField(hourlyRate, "Hourly Rate", setError);
    } else {
      annualSalaryRes = checkEmptyField(
        annualSalary,
        "Annual Salary",
        setError
      );
    }
    const targetCompletionRateRes = checkEmptyField(
      targetCompletionRate,
      "Target Completion Rate",
      setError
    );
    const timePerTransRes = checkEmptyField(
      timePerTrans,
      "Time Per Trans. (mins)",
      setError
    );
    const estVolumeRes = checkEmptyField(estVolume, "Est.Volume", setError);
    const frequencyRes = checkEmptyField(
      frequency,
      "Frequency",
      setError,
      "select"
    );
    const queueDescriptionRes = checkEmptyField(
      queueDescription,
      "Queue Description",
      setError
    );
    const queueKeyRes = checkEmptyField(queueKey, "Queue Key", setError);
    const queueNameRes = checkEmptyField(queueName, "Queue Name", setError);

    const responses = [
      queueNameRes,
      queueKeyRes,
      queueDescriptionRes,
      frequencyRes,
      estVolumeRes,
      timePerTransRes,
      hourlyRateRes,
      annualSalaryRes,
      targetCompletionRateRes,
    ];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const save = async () => {
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      const queueId = await createQueue();
      if (queueId) {
        const queueHealthId = await createQueueHealth(queueId);
        if (queueHealthId) {
          queueChangesAction(true);
          setDeleteBtnIsDisabled(false);
          isSavedSuccessfully();
          handleNewQueue();
        }
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (behaveType === "new") {
      await save();
    }
    if (behaveType === "config" && !activeQueueId) {
      await save();
    }
    if (behaveType === "config" && activeQueueId) {
      await update();
    }
    solutionQuery.refetch();
    queuesQuery.refetch();
  };

  const update = async () => {
    const allFieldsAreFilled = checkFields();
    if (allFieldsAreFilled) {
      let queueHealthResponse = "";
      const queueResponse = await updateQueue();
      if (activeQueueHealthId) {
        queueHealthResponse = await updateQueueHealth();
      }
      if (!activeQueueHealthId) {
        queueHealthResponse = await createQueueHealth(activeQueueId);
        setActiveQueueHealthId(queueHealthResponse);
      }
      setUpdateQueueHealthItem(false);
      if (queueResponse && queueHealthResponse) {
        queueChangesAction(true);
        isSavedSuccessfully();
      }
    }
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    setDeleteBtnIsDisabled(true);
    setIsRequiredHealthChangeReason(false);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (queueHealth !== defaultQueueHealth) {
      setIsRequiredHealthChangeReason(true);
    }
    if (activeQueueId) {
      setDeleteBtnIsDisabled(false);
    }
    fetchWorkspaceById();
    if (!updateQueueHealthItem) {
      changeActiveQueue(queues);
    }
  }, [
    error,
    queueHealth,
    activeQueueId,
    activeQueueHealthId,
    activeQueueBenefitId,
    queueId,
  ]);

  return (
    <>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      <QueueBenefitModal
        show={queueBenefitModalShow}
        onHide={() => setQueueBenefitModalShow(false)}
        queueId={activeQueueId}
        queueName={queueName}
        workspaceId={workspaceId}
        solutionId={solutionId}
        activeQueueBenefitId={activeQueueBenefitId}
        changeActiveQueueBenefitId={setActiveQueueBenefitId}
        refetch={() => {
          queueBenefitsQuery.refetch();
          solutionQuery.refetch();
          queueChangesAction(true);
        }}
      />
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="queueName">
                      <Form.Label>Queue Name</Form.Label>

                      {queuesWithoutSolution.length > 0 ? (
                        <Dropdown
                          as={ButtonGroup}
                          className="border border-dark rounded pt-2 pb-2 pe-2 ps-2 w-100">
                          <Dropdown.Toggle
                            id="dropdown-custom-components"
                            as={CustomToggle}>
                            {queueName
                              ? `${queueName.substring(0, 12)}...`
                              : ""}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Row>
                              <Col md={9}>
                                <Form.Control
                                  id="userQueueName"
                                  type="text"
                                  placeholder="Enter new queue name..."
                                  value={userQueueName || ""}
                                  className="ms-3"
                                  onChange={(e) => {
                                    setError("");
                                    setStatus("");
                                    setUserQueueName(e.target.value);
                                    queueChangesAction(false);
                                  }}
                                />
                              </Col>
                              <Col md={3}>
                                <Button
                                  disabled={userQueueName ? false : true}
                                  onClick={() => {
                                    handleNewQueue();
                                    setQueueName(userQueueName);
                                    setUserQueueName("");
                                  }}
                                  className="text-center pb-2 pt-2 ps-2 pe-2">
                                  Add
                                </Button>
                              </Col>
                            </Row>
                            <Dropdown.Divider />
                            <Dropdown.Header>
                              Or update existed solution component item:
                            </Dropdown.Header>
                            <Dropdown.Divider />
                            {queuesWithoutSolution.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  key={item.queue_id}
                                  active={queueName === item.queue_name}
                                  onClick={() => {
                                    setError("");
                                    setStatus("");
                                    queueChangesAction(false);
                                    setActiveQueueId(item.queue_id);
                                    changeActiveQueue(queuesWithoutSolution);
                                  }}>
                                  {item.queue_name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <Form.Control
                          type="text"
                          maxLength={50}
                          value={queueName || ""}
                          onChange={(e) => {
                            setError("");
                            setStatus("");
                            setQueueName(e.target.value);
                            queueChangesAction(false);
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group id="queueKey">
                      <Form.Label>Queue Key</Form.Label>
                      <Form.Control
                        type="text"
                        value={queueKey || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setQueueKey(e.target.value);
                          queueChangesAction(false);
                        }}></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={queueDescription || ""}
                        rows={4}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setQueueDescription(e.target.value);
                          queueChangesAction(false);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="frequency">
                      <Form.Label>Frequency</Form.Label>
                      <Form.Control
                        as="select"
                        value={frequency || defaultFrequency}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setFrequency(e.target.value);
                          queueChangesAction(false);
                        }}>
                        {frequencyOptions.map((item, i) => {
                          return (
                            <option key={`frequency-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="estVolume">
                      <Form.Label>Est.Volume({frequency})</Form.Label>
                      <Form.Control
                        type="number"
                        value={estVolume || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setEstVolume(e.target.value);
                          queueChangesAction(false);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="timePerTrans">
                      <Form.Label>Time Per Trans(mins)</Form.Label>
                      <Form.Control
                        type="number"
                        value={timePerTrans || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setTimePerTrans(e.target.value);
                          queueChangesAction(false);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="costType">
                      <Form.Label>Cost Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={costType || defaultCostType}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setCostType(e.target.value);
                          setHourlyRate(0);
                          setAnnualSalary(0);
                          queueChangesAction(false);
                          if (e.target.value === "Hourly") {
                            setIsDisableHourlyRate(false);
                            setIsDisableAnnualSalary(true);
                          }
                          if (e.target.value === "Annual Salary") {
                            setIsDisableHourlyRate(true);
                            setIsDisableAnnualSalary(false);
                          }
                        }}>
                        {costTypeOptions.map((item, i) => {
                          return (
                            <option key={`costType-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="hourlyRate">
                      <Form.Label>Hourly Rate</Form.Label>
                      <Form.Control
                        disabled={isDisabledHourlyRate}
                        value={`$${hourlyRate}`}
                        type="text"
                        placeholder="$ XXX,XXX.XX"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setHourlyRate(e.target.value.replace("$", ""));
                          queueChangesAction(false);
                          setAnnualSalary(
                            (
                              Number(e.target.value.replace("$", "")) *
                              effectiveWorkingHoursPA
                            ).toFixed(2)
                          ); // Annual Salary = Hourly Rate * Effective Working hours p.a.
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="annualSalary">
                      <Form.Label>Annual Salary</Form.Label>
                      <Form.Control
                        disabled={isDisabledAnnualSalary}
                        value={`$${annualSalary}`}
                        type="text"
                        placeholder="$ XXX,XXX.XX"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setAnnualSalary(e.target.value.replace("$", ""));
                          setHourlyRate(
                            (
                              Number(e.target.value.replace("$", "")) /
                              effectiveWorkingHoursPA
                            ).toFixed(2)
                          ); // Hourly Rate = Annual Salary / Effective Working hours p.a.
                          queueChangesAction(false);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="queueHealth">
                      <Form.Label>Queue Health</Form.Label>
                      <Form.Control
                        as="select"
                        value={queueHealth || defaultQueueHealth}
                        className="mb-0"
                        onChange={(e) => {
                          setHealthChangeReason("");
                          setError("");
                          setStatus("");
                          setQueueHealth(e.target.value);
                          setIsRequiredHealthChangeReason(true);
                          setUpdateQueueHealthItem(true);
                          queueChangesAction(false);
                        }}>
                        {queueHealthOptions.map((item, i) => {
                          return (
                            <option key={`queueHealth-${i}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="healthChangeReason">
                      <Form.Label>Health Change Reason</Form.Label>
                      <Form.Control
                        required={isRequiredHealthChangeReason}
                        type="text"
                        value={healthChangeReason || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setHealthChangeReason(e.target.value);
                          queueChangesAction(false);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="targetCompletionRate">
                      <Form.Label>Target Completion Rate</Form.Label>
                      <Form.Control
                        type="number"
                        value={targetCompletionRate || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setTargetCompletionRate(e.target.value);
                          queueChangesAction(false);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="relatedTechLicense">
                      <Form.Label>Related Tech License</Form.Label>
                      <Form.Control
                        as="select"
                        value={relatedTechLicense || ""}
                        className="mb-0"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setRelatedTechLicense(e.target.value);
                          queueChangesAction(false);
                        }}>
                        <option value=""></option>
                        {licensesQuery.data
                          ? licensesQuery.data.map((license, i) => {
                              return (
                                <option
                                  key={`relatedTechLicense-${i}`}
                                  value={license.license_id}>
                                  {license.license_name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <SolutionRelatedTable
                  type="queue"
                  queues={queues}
                  activeId={activeQueueId}
                  changeActiveId={(e) => setActiveQueueId(e)}
                  createNew={handleNewQueue}
                />
                {activeQueueId && additionalBenefits.length > 0 ? (
                  <SolutionRelatedTable
                    type="queueBenefit"
                    queueBenefits={queueBenefitsQuery.data ?? []}
                    activeId={activeQueueBenefitId}
                    changeActiveId={(e) => setActiveQueueBenefitId(e)}
                    createNew={() => setQueueBenefitModalShow(true)}
                    onModalHide={() => setQueueBenefitModalShow(true)}
                  />
                ) : null}
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="totalEstBenefit">
                      <Form.Label>Total Est. Benefit</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        placeholder="$ XXX,XXX.XX"
                        value={
                          solutionQuery.data?.est_annual_benefit
                            ? `$${solutionQuery.data?.est_annual_benefit}`
                            : "$0.00"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="totalActBenefit">
                      <Form.Label>Total Act. Benefit</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        placeholder="$ XXX,XXX.XX"
                        value={
                          solutionQuery.data?.actual_total_benefit
                            ? `$${solutionQuery.data?.actual_total_benefit}`
                            : "$0.00"
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button
                  disabled={saveBtnIsDisabled}
                  variant="primary"
                  type="submit"
                  onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col md={3}>
                <h6 style={{ color: statusColor }}>{saveStatus}</h6>
              </Col>
              <Col md={2}>
                <Button
                  disabled={deleteBtnIsDisabled}
                  variant="primary"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
