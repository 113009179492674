
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

import Profile1 from "assets/img/team/profile-picture-1.jpg";
import Profile2 from "assets/img/team/profile-picture-2.jpg";
import Profile3 from "assets/img/team/profile-picture-3.jpg";
import Profile4 from "assets/img/team/profile-picture-4.jpg";
import CALLogoMockup from "assets/img/themesberg-mockup.jpg";

export const Members = [
    { "id": uuidv4(), "name": "Jonathan Parsons ", "image": Profile1 },
    { "id": uuidv4(), "name": "Alexander Smith", "image": Profile2 },
    { "id": uuidv4(), "name": "Bonnie Green", "image": Profile3 },
    { "id": uuidv4(), "name": "Scott Anderson", "image": Profile4 },
];

export const Labels = [
    { "id": uuidv4(), "name": "Help wanted", "color": "primary" },
    { "id": uuidv4(), "name": "Feature", "color": "tertiary" },
    { "id": uuidv4(), "name": "Feature request", "color": "secondary" },
    { "id": uuidv4(), "name": "Urgent", "color": "danger" },
    { "id": uuidv4(), "name": "High priority", "color": "warning" },
    { "id": uuidv4(), "name": "Low priority", "color": "yellow-200" },
    { "id": uuidv4(), "name": "Needs investigation", "color": "indigo" },
    { "id": uuidv4(), "name": "Discussion", "color": "purple" },
];

export const createList = (props = {}) => ({
    "title": "",
    "cards": [],
    ...props,
    "id": uuidv4(),
    "dateCreated": moment().format("DD MMM YYYY"),
});

export const createCard = (props = {}) => ({
    "title": "",
    "description": "",
    "author": Members[0],
    "members": [Members[0]],
    "labels": [Labels[0]],
    "comments": [],
    ...props,
    "id": uuidv4(),
    "dateCreated": moment().format("DD MMM YYYY"),
});

export default [
    {
        "id": uuidv4(),
        "title": "In Backlog",
        "dateCreated": moment().format("DD MMM YYYY"),
        "cards": [
            {
                "id": uuidv4(),
                "title": "P1 - Send Reminders to Academics",
                "description": "This Process sends reminders to academics that have not provided the required responses to students",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                    Members[2],
                ],
                "labels": [Labels[0]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Thanks Team I have updated as discussed."
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Modified as suggested"
                    },
                ],
                "dateCreated": moment().format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "Finance Reconcilliation",
                "description": "Automates Quarterly General Ledger reconcillations for Finance Busines Unit ",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                    Members[2],
                ],
                "labels": [Labels[0]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Enhancement complete"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Unable to perform feature update"
                    },
                ],
                "dateCreated": moment().format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "Termination Process",
                "description": "This Process read data from a CSV file generated by Argos system, about students who are eligible to be terminated, the bot then login to banner, withdrew the students enrolled not graded units, apply termination code to student status, apply termination code to degree status, update the student comments about the operaional details and send process report to academic progression team",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                    Members[2],
                ],
                "labels": [Labels[0]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Updated to version 2"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Enhancement complete"
                    },
                ],
                "dateCreated": moment().format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "ApplyingFinancialHolds",
                "description": "This process downloads student fee's report from Banner (student system at ACU), filter the students met the business rules about applying a hold. The bot then go to banner, apply fee hold and send communication emails to the students. ",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                ],
                "labels": [Labels[1]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Done"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Done"
                    },
                ],
                "dateCreated": moment().subtract(2, "days").format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "Unsatisfactory progress - at risk",
                "description": "This process downloads student fee's report from Banner, filter the students met the business rules about applying a hold. The bot then goes to banner, apply fee hold and send communication emails to the students",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                ],
                "labels": [Labels[2]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Enhancement Complete"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Enhancement complete."
                    },
                ],
                "dateCreated": moment().subtract(3, "days").format("DD MMM YYYY"),
            },
        ]
    },
    {
        "id": uuidv4(),
        "title": "In Development",
        "dateCreated": moment().format("DD MMM YYYY"),
        "cards": [
            {
                "id": uuidv4(),
                "title": "CreateInstructors",
                "description": " This Process read staff work request about assign primary or secondary instructor role in banner (student connect) for the staff from service central ticket. The bot process then assign the roles for the staff in banner, update the service central ticket, if exception happens, re-assign the ticket back to student system operations team. write back to service central ticket about operational details",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                    Members[2],
                ],
                "labels": [Labels[0]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Enhancement complete"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Enhancement Complete"
                    },
                ],
                "dateCreated": moment().format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "Automated Testing 1",
                "description": "Provides Automated Testing of Application systems in IT department",
                "author": Members[2],
                "members": [
                    Members[2],
                    Members[3],
                ],
                "labels": [Labels[1]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Inable to identify selector"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Unable to complete enhacnement due to version control challenged"
                    },
                ],
                "dateCreated": moment().subtract(2, "days").format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "Automated Testing 2",
                "description": "Provides Automated Testing of Application systems in IT department",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                ],
                "labels": [Labels[1]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Done."
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Done"
                    },
                ],
                "dateCreated": moment().subtract(2, "days").format("DD MMM YYYY"),
            },
        ]
    },
    {
        "id": uuidv4(),
        "title": "In UAT",
        "dateCreated": moment().format("DD MMM YYYY"),
        "cards": [
            {
                "id": uuidv4(),
                "title": "Automated Testing 3",
                "description": "Provides Automated Testing of Application systems in IT department",
                "author": Members[0],
                "members": [
                    Members[0],
                    Members[1],
                    Members[2],
                ],
                "labels": [Labels[0]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Complete"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Complete"
                    },
                ],
                "dateCreated": moment().format("DD MMM YYYY"),
            },
            {
                "id": uuidv4(),
                "title": "Debt Reminders",
                "description": "Sends out debt payment reminders for studnents fees outstanding great than 60 days",
                "author": Members[1],
                "members": [
                    Members[1],
                    Members[0],
                ],
                "labels": [Labels[1]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Complete"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Complete"
                    },
                ],
                "dateCreated": moment().subtract(2, "days").format("DD MMM YYYY"),
            },
        ]
    },
    {
        "id": uuidv4(),
        "title": "Deployed to Production",
        "dateCreated": moment().format("DD MMM YYYY"),
        "cards": [
            {
                "id": uuidv4(),
                "title": "SendingFeeReminder",
                "description": "This process downloads student fee's report from Banner, filter the students met the business rules about who has outstanding fee amout and needs to be issued with a reminder letter. The bot then login to banner, upload the student list and bulk send reminder emails to the students.",
                "author": Members[1],
                "members": [
                    Members[0],
                    Members[1],
                    Members[2],
                ],
                "labels": [Labels[1]],
                "comments": [
                    {
                        "id": uuidv4(),
                        "sender": "Roy Fendley",
                        "timeSent": moment().subtract(30, "minutes"),
                        "message": "Complete"
                    },
                    {
                        "id": uuidv4(),
                        "sender": "Ryan Tompson",
                        "timeSent": moment().subtract(1, "hour"),
                        "message": "Complete"
                    },
                ],
                "dateCreated": moment().format("DD MMM YYYY"),
            }
        ]
    },
];