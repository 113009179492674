import React, { useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import { ProfileHeader } from "./components";
import { ProfileTab } from "./tabs";
import { useTypedSelector } from "hooks";

const profileTabName = "profile"; 

export const SettingsProfile = () => {
    const userId = useTypedSelector((state) => state.user.userId);
    const [activeTab, setActiveTab] = useState(profileTabName);

    return (
        <>
            <ProfileHeader userId={userId}/>
            <Tabs
                activeKey={activeTab}
                id="fill-tab-example"
                className="mb-3"
            >
                <Tab eventKey={profileTabName} title="Profile" >
                    <ProfileTab userId={userId} />
                </Tab>
            </Tabs>
        </>
    )
};
