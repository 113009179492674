import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Card, Button, Table } from "react-bootstrap";
import { useAction } from "hooks";
import { TechnologyTableRow } from "./rows";
import { Loading, TablePagination } from "pages/components";
import { TechnologyService } from "services";
import { handleError } from "utils";

const technologyService = new TechnologyService();

const columns = [
  { dateField: "index", text: "#" },
  { dataField: "technologyName", text: "Technology Name" },
  { dataField: "technologyDescription", text: "Description" },
  { dataField: "totalEstCost", text: "Total Est. Cost (p.a.)" },
  { dataField: "configs", text: "..." },
];

const perPage = 10;

const paginateTechnologies = async ({ queryKey }) => {
  const [_, workspaceId, page, perPage] = queryKey;
  if (workspaceId) {
    return await technologyService.paginateByWorkspace(
      workspaceId,
      page,
      perPage
    );
  }
};

export const TechnologiesTable = (props) => {
  const { workspaceId } = props;
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { setTechnologyNewTabAction } = useAction();

  const query = useQuery(
    ["technologies", workspaceId, page, perPage],
    paginateTechnologies,
    { onError: (e) => handleError(e, history) }
  );
  const { data, isRefetching } = query;
  const total = data?.total ?? 0;

  return (
    <div className="">
      <div className="d-flex justify-content-between w-100 flex-wrap">
        <div className="mb-3 ms-4 mb-lg-0">
          <h6>Workspace Technologies</h6>
        </div>
        <Button
          variant="primary"
          size="sm"
          className="mb-1 me-4 pe-3 ps-3"
          onClick={() => setTechnologyNewTabAction()}>
          Add
        </Button>
      </div>
      {isRefetching ? (
        <Loading marginTop="1%" marginLeft="45%" />
      ) : (
        <Card border="0" className="shadow mb-4">
          <Card.Body>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  {columns.map((column, i) => {
                    return (
                      <th key={`column-${i}`} className="border-0">
                        {column.text}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.items
                  ? data?.items.map((row, i) => {
                      return <TechnologyTableRow key={`tech-${i}`} technology={row} />;
                    })
                  : null}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <TablePagination
              page={page}
              perPage={perPage}
              total={total}
              itemsLength={data?.items ? data?.items.length : 0}
              setPage={setPage}
            />
          </Card.Footer>
        </Card>
      )}
    </div>
  );
};
