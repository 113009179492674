import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BusinessUnitService, GroupService } from "services";
import { handleError } from "utils";

const groupService = new GroupService();
const businessUnitService = new BusinessUnitService();

const groupType = "group";
const teamType = "team";

export const AddItemModal = (props) => {
  const {
    type,
    show,
    onHide,
    workspaceId,
    activeItemId,
    changeActiveId,
    itemHasChanged,
  } = props;
  const history = useHistory();
  const subTitle = type === groupType ? "Group" : "Business Unit";
  const [error, setError] = useState("");
  // fields
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  // booleans
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [saveBtnIsShown, setSaveBtnIsShown] = useState(true);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(true);
  const [itemIsUpdated, setItemIsUpdated] = useState(false);

  const failedDuringSaving = (error) => {
    const errorMsg = error ? error : "Failure occurred when saving.";
    setError(errorMsg);
  };

  const failedDuringDeleting = (error) => {
    const errorMsg = error ? error : "Failure occurred when deleting";
    setError(errorMsg);
  };

  const saveGroup = async () => {
    try {
      const data = {
        groupName: itemName,
        groupDescription: itemDescription,
        workspaceId: workspaceId,
      };
      return await groupService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };
  const saveTeam = async () => {
    try {
      const data = {
        businessUnitName: itemName,
        businessUnitDescription: itemDescription,
        workspaceId: workspaceId,
      };
      return await businessUnitService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };
  const updateGroup = async () => {
    try {
      const data = {
        group_name: itemName,
        group_description: itemDescription,
      };
      return await groupService.update(activeItemId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };
  const updateTeam = async () => {
    try {
      const data = {
        business_unit_name: itemName,
        business_unit_description: itemDescription,
      };
      return await businessUnitService.update(activeItemId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const fetchTeamById = async () => {
    try {
      const response = await businessUnitService.get(activeItemId);
      setItemName(response.business_unit_name);
      setItemDescription(response.business_unit_description);
    } catch (e) {
      handleError(e, history);
    }
  };
  const fetchGroupById = async () => {
    try {
      const response = await groupService.get(activeItemId);
      setItemName(response.group_name);
      setItemDescription(response.group_description);
    } catch (e) {
      handleError(e, history);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let id = "";
    if (type === groupType) {
      id = await saveGroup();
    }
    if (type === teamType) {
      id = await saveTeam();
    }
    if (id) {
      itemHasChanged(true);
      handleHideModal();
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (type === groupType) {
      await updateGroup();
    }
    if (type === teamType) {
      await updateTeam();
    }
    itemHasChanged(true);
    setItemIsUpdated(false);
    handleHideModal();
  };

  const deleteGroup = async () => {
    try {
      await groupService.delete(activeItemId);
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const deleteTeam = async () => {
    try {
      await businessUnitService.delete(activeItemId);
    } catch (e) {
      failedDuringDeleting(e.message);
      handleError(e, history);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (type === groupType) {
      await deleteGroup();
    }
    if (type === teamType) {
      await deleteTeam();
    }
    itemHasChanged(true);
    handleHideModal();
  };

  const fetchActiveItemById = async () => {
    if (type === groupType) {
      await fetchGroupById();
    }
    if (type === teamType) {
      await fetchTeamById();
    }
  };

  const handleHideModal = () => {
    onHide(true);
    changeActiveId("");
    setItemName("");
    setItemDescription("");
    setError("");
  };

  const handleChangeItemName = (e) => {
    setError("");
    setItemName(e.target.value);
    if (activeItemId) {
      setItemIsUpdated(true);
    }
  };

  const handleChangeItemDescription = (e) => {
    setError("");
    setItemDescription(e.target.value);
    if (activeItemId) {
      setItemIsUpdated(true);
    }
  };

  useEffect(() => {
    setSaveBtnIsDisabled(true);
    setSaveBtnIsShown(true);
    if (itemName && itemDescription && !error) {
      setSaveBtnIsDisabled(false);
    }
    if (activeItemId) {
      if (!itemIsUpdated) {
        fetchActiveItemById();
      }
      setSaveBtnIsShown(false);
      setDeleteBtnIsDisabled(false);
    }
  }, [error, activeItemId, itemName, itemDescription, saveBtnIsShown]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ marginLeft: "0%" }}>
          {subTitle} Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group id="itemName">
              <Form.Label>{subTitle} Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={itemName || ""}
                onChange={handleChangeItemName}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group id="itemDescription">
              <Form.Label>{subTitle} Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                maxLength={200}
                value={itemDescription || ""}
                onChange={handleChangeItemDescription}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          {saveBtnIsShown ? (
            <Col md={6}>
              <Button
                disabled={saveBtnIsDisabled}
                variant="primary"
                type="submit"
                onClick={handleSave}>
                Save
              </Button>
            </Col>
          ) : null}
          {!saveBtnIsShown ? (
            <Col md={6}>
              <Button variant="primary" type="submit" onClick={handleUpdate}>
                Update
              </Button>
            </Col>
          ) : null}
          {activeItemId ? (
            <Col md={6}>
              <Button
                disabled={deleteBtnIsDisabled}
                variant="primary"
                onClick={handleDelete}>
                Delete
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
