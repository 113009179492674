import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useTypedSelector } from "hooks";
import {
  BusinessUnitService,
  UserService,
  WorkspaceAssignmentService,
} from "services";
import { checkEmptyField, handleError } from "utils";

const businessUnitService = new BusinessUnitService();
const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

const organisationRoleOptions = [
  "",
  "Contractor",
  "Staff",
  "Manager",
  "Director",
  "CXO",
  "Business Owner",
];

const fetchBusinessUnits = async ({ queryKey }) => {
  const [_, activeWorkspaceId] = queryKey;
  if (activeWorkspaceId) {
    return await businessUnitService.getAllByWorkspace(activeWorkspaceId);
  }
};

export const EditUserModal = (props) => {
  const { show, onHide, user, refetch } = props;
  const history = useHistory();
  // general
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phone, setPhone] = useState(user.phone_number);
  const [photoUrl, setPhotoUrl] = useState(user.profile_photo_url);
  const [organizationRole, setOrganizationRole] = useState(user.org_role);
  const [businessUnit, setBusinessUnit] = useState(user.business_unit_id);
  // ids
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const activeWorkspaceAssignmentId = useTypedSelector(
    (state) => state.workspaceAssignment.currentWorkspaceAssignmentId
  );
  // booleans
  const [isAdmin, setIsAdmin] = useState(false);
  const [updateBtnIsDisabled, setUpdateBtnIsDisabled] = useState(false);
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [itemIsSaved, setItemIsSaved] = useState(false);
  // style
  const statusColor = isSaveTypeSuccessful ? "success" : "danger";
  const closeButtonText = itemIsSaved ? "Close" : "Cancel";

  const businessUnitsQuery = useQuery(
    ["editUserBusinessUnits", activeWorkspaceId],
    fetchBusinessUnits,
    { onError: (e) => handleError(e, history) }
  );
  const businessUnits = businessUnitsQuery.data ? businessUnitsQuery.data : [];

  const fetchWorkspaceAssignment = async ({ queryKey }) => {
    const [_, activeWorkspaceAssignmentId] = queryKey;
    if (activeWorkspaceAssignmentId) {
      const response = await workspaceAssignmentService.get(activeWorkspaceAssignmentId);
      if (response) {
        setIsAdmin(response.is_admin);
        return response;
      }
    }
  };

  const workspaceAssignmentQuery = useQuery(
    ["editUserWorkspaceAssignmentQuery", activeWorkspaceAssignmentId],
    fetchWorkspaceAssignment,
    { onError: (e) => handleError(e, history) }
  );

  const isSavedSuccessfully = () => {
    setStatus("User updated successfully!");
    setIsStatusTypeSuccessful(true);
    setItemIsSaved(true);
  };

  const failedDuringSaving = (error) => {
    const errorMsg = error
      ? error
      : "Failure occurred when saving. Please, try again.";
    setStatus(errorMsg);
    setIsStatusTypeSuccessful(false);
  };

  const checkUserFields = () => {
    const firstNameIsField = checkEmptyField(firstName, "First Name", setError);
    const lastNameIsField = checkEmptyField(lastName, "Last Name", setError);
    const organizationRoleField = checkEmptyField(
      organizationRole,
      "Organization Role",
      setError,
      "select"
    );
    const responses = [
      firstNameIsField,
      lastNameIsField,
      organizationRoleField,
    ];
    const allFieldsAreFilled = responses.every((element) => {
      if (element === "OK") {
        return true;
      }
    });
    return allFieldsAreFilled;
  };

  const updateUser = async () => {
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        profile_photo_url: photoUrl,
        phone_number: phone,
        email_address: user.email_address,
      };
      return await userService.update(user.user_id, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateWorkspaceAssignment = async () => {
    try {
      const data = {
        business_unit_id: businessUnit,
        org_role: organizationRole,
      };
      return await workspaceAssignmentService.update(
        user.workspace_assignment_id,
        data
      );
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setStatus("");
    const allUserFieldsAreFilled = checkUserFields();
    if (allUserFieldsAreFilled) {
      const userResponse = await updateUser();
      const workspaceAssignmentResponse = await updateWorkspaceAssignment();
      if (userResponse && workspaceAssignmentResponse) {
        isSavedSuccessfully();
        await new Promise((resolve) => setTimeout(resolve, 3000));
        refetch();
      }
    }
  };

  const handleHideModal = () => {
    onHide(true);
    setItemIsSaved(false);
    setError("");
    setStatus("");
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ marginLeft: "40%" }}>User Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        ) : null}
        {saveStatus ? (
          <Alert key={statusColor} variant={statusColor}>
            {saveStatus}
          </Alert>
        ) : null}
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={firstName || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setFirstName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={lastName || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setLastName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                readOnly
                type="email"
                value={user.email_address || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                value={phone || ""}
                maxLength={12}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setPhone(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="organizationRole">
              <Form.Label>Organization Role</Form.Label>
              <Form.Control
                as="select"
                value={organizationRole || ""}
                className="mb-0"
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setOrganizationRole(e.target.value);
                }}>
                {organisationRoleOptions.map((role, i) => {
                  return (
                    <option key={`organizationRole-${i}`} value={role}>
                      {role}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="businessUnit">
              <Form.Label>Business Unit</Form.Label>
              <Form.Control
                as="select"
                value={businessUnit || ""}
                className="mb-0"
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setBusinessUnit(e.target.value);
                }}>
                <option value=""></option>
                {businessUnits.length > 0
                  ? businessUnits.map((businessUnitItem, i) => {
                      return (
                        <option
                          key={`businessUnit-${i}`}
                          value={businessUnitItem.business_unit_id}>
                          {businessUnitItem.business_unit_name}
                        </option>
                      );
                    })
                  : null}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group id="profilePhotoUrl">
              <Form.Label>Profile Photo Url</Form.Label>
              <Form.Control
                type="text"
                value={photoUrl || ""}
                onChange={(e) => {
                  setError("");
                  setStatus("");
                  setPhotoUrl(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          {" "}
          {isAdmin ? (
            <Col md={itemIsSaved ? 12 : 6} xs={itemIsSaved ? 12 : 6}>
              <Button variant="secondary" onClick={handleHideModal}>
                {closeButtonText}
              </Button>
            </Col>
          ) : null}
          {!isAdmin ? (
            <Col md={12} xs={12}>
              <Button variant="secondary" onClick={handleHideModal}>
                Close
              </Button>
            </Col>
          ) : null}
          {isAdmin ? (
            !itemIsSaved ? (
              <Col md={6} xs={6}>
                <Button
                  disabled={updateBtnIsDisabled}
                  variant="primary"
                  type="submit"
                  onClick={handleUpdate}>
                  Update
                </Button>
              </Col>
            ) : null
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
