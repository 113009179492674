import React from "react";
import {
  EditWorkspace,
  WorkspaceHeader,
} from "pages/settings/workspace/components";

export const CreateWorkspace = () => {
  return (
    <>
      <WorkspaceHeader type="new" />
      <EditWorkspace type="new" />
    </>
  );
};
