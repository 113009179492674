import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { WorkspaceTable } from "../tables";
import { useAction, useTypedSelector } from "hooks";
import { WorkspaceAssignmentService, WorkspaceService } from "services";
import {
  checkEmptyField,
  handleError,
  removeGapAndConvertToLowerCase,
} from "utils";
import { Routes } from "routes";

const currentLocation = window.location.origin;
export const BASE_URL = `${currentLocation}/#`;
const defaultWorkspaceIconLink =
  "https://i.ibb.co/NLNMHx5/CA-Labs-Logo-Favicon.png";
const defaultName = "Cognitive Automation Labs";
const defaultEffectiveWorkingHours = 1200;
const defaultWorkspacePath = `${BASE_URL}cognitive_automation_labs`;

const workspaceAssignmentService = new WorkspaceAssignmentService();
const workspaceService = new WorkspaceService();

export const WorkspaceTab = (props) => {
  const { type, workspaceId } = props;
  const history = useHistory();
  const {
    addWorkspaceAction,
    updateWorkspaceAction,
    deleteWorkspaceThunkAction,
    disableDashboardsAction,
  } = useAction();
  const [behaveType, setBehaveType] = useState(type);
  // notifiers
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields to edit
  const [workspacePath, setWorkspacePath] = useState("");
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceDescription, setWorkspaceDescription] = useState("");
  const [effectiveWorkingHours, setEffectiveWorkingHours] = useState("");
  const [workspaceIcon, setWorkspaceIcon] = useState("");
  const [isDisableDashboards, setIsDisableDashboards] = useState(false);
  // booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
  const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(true);
  const [updateItem, setUpdateItem] = useState(false);
  const [disableWorkspaceNameField, setDisableWorkspaceNameField] =
    useState(false);
  // ids
  const [workspaceAssignmentId, setWorkspaceAssignmentId] = useState("");
  const userId = useTypedSelector((state) => state.user.userId);
  const activeWorkspaceName = useTypedSelector(
    (state) => state.workspace.activeWorkspaceName
  );
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const defaultWorkspaceName = useTypedSelector(
    (state) => state.workspace.defaultWorkspaceName
  );
  const defaultWorkspaceId = useTypedSelector(
    (state) => state.workspace.defaultWorkspaceId
  );
  const userWorkspaces = useTypedSelector(
    (state) => state.workspace.userWorkspaces
  );
  const statusColor = isSaveTypeSuccessful ? "green" : "red";
  const [checkName, setCheckName] = useState(false);

  const isSavedSuccessfully = () => {
    setStatus("Changes saved successfully.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
    setIsStatusTypeSuccessful(false);
  };

  const failedDuringDeleting = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when deleting");
    setIsStatusTypeSuccessful(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      deleteWorkspaceThunkAction(
        workspaceId,
        defaultWorkspaceName,
        defaultWorkspaceId,
        userId,
      );
      history.push(`/${defaultWorkspaceName}${Routes.DashboardSolutions.path}`);
    } catch (e) {
      failedDuringDeleting(e.message);
    }
  };

  const createWorkspace = async () => {
    try {
      const workspaceNameIsField = checkEmptyField(
        workspaceName,
        "Workspace Name",
        setError
      );
      const effectiveWorkingHoursIsField = checkEmptyField(
        effectiveWorkingHours,
        "Effective Employee Working Hours (p.a)",
        setError
      );
      const workspaceIconIsField = checkEmptyField(
        workspaceIcon,
        "Workspace Icon",
        setError
      );
      let name = workspaceName;
      let hours = effectiveWorkingHours;
      let icon = workspaceIcon;
      let path = workspacePath;
      if (workspaceNameIsField === "err") {
        name = defaultName;
        path = defaultWorkspacePath;
      }
      if (effectiveWorkingHoursIsField === "err") {
        hours = defaultEffectiveWorkingHours;
      }
      if (workspaceIconIsField === "err") {
        icon = defaultWorkspaceIconLink;
      }
      setError("");
      const data = {
        workspaceName: name,
        workspaceDescription: workspaceDescription,
        effectiveWorkingHours: hours,
        workspaceImgPath: icon,
        isDisableDashboards: isDisableDashboards,
        workspacePath: path,
      };
      const addedWorkspace = await workspaceService.create(data);
      addWorkspaceAction(addedWorkspace);
      return addedWorkspace.workspace_id;
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const createWorkspaceAssignment = async (workspace) => {
    try {
      const data = {
        isAdmin: behaveType === "new" ? true : false,
        userId: userId,
        workspaceId: workspace,
        isVerified: true,
      };
      return await workspaceAssignmentService.create(data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateWorkspace = async () => {
    try {
      const data = {
        workspace_name: workspaceName,
        workspace_path: workspacePath,
        workspace_img_path: workspaceIcon,
        workspace_description: workspaceDescription,
        disable_dashboards: isDisableDashboards === true ? true : "false",
        effective_working_hours: parseInt(effectiveWorkingHours),
      };
      const itemIsUpdated = await workspaceService.update(workspaceId, data);
      if (itemIsUpdated) {
        updateWorkspaceAction([workspaceId, data]);
      }
      if (activeWorkspaceId === Number(workspaceId)) {
        disableDashboardsAction(isDisableDashboards);
      }
      return "OK";
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const save = async () => {
    setCheckName(false);
    const workspaceDescriptionIsField = checkEmptyField(
      workspaceDescription,
      "Description",
      setError
    );
    if (workspaceDescriptionIsField === "OK") {
      const workspaceRes = await createWorkspace();
      if (workspaceRes) {
        const workspaceAssignment = await createWorkspaceAssignment(
          workspaceRes
        );
        if (workspaceAssignment) {
          setWorkspaceAssignmentId(workspaceAssignment);
          isSavedSuccessfully();
          history.push(
            `/${activeWorkspaceName}/settings/workspace/config/${workspaceRes}`
          );
        }
      }
    }
  };

  const update = async () => {
    const workspaceResponse = await updateWorkspace();
    setUpdateItem(false);
    if (workspaceResponse) {
      isSavedSuccessfully();
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (behaveType === "new") {
      await save();
    }
    if (behaveType === "config") {
      await update();
    }
  };

  const fetchWorkspaceById = async (workspaceId) => {
    try {
      if (workspaceId) {
        const response = await workspaceService.get(workspaceId);
        if (response) {
          setWorkspacePath(response.workspace_path);
          setWorkspaceName(response.workspace_name);
          setWorkspaceDescription(response.workspace_description);
          setEffectiveWorkingHours(response.effective_working_hours);
          setWorkspaceIcon(response.workspace_img_path);
          let disableDashboardsValue = false;
          switch (response.disable_dashboards.toString()) {
            case "false":
              disableDashboardsValue = false;
              break;
            case "true":
              disableDashboardsValue = true;
              break;
            default:
              disableDashboardsValue = true;
              break;
          }
          setIsDisableDashboards(disableDashboardsValue);
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchWorkspaceAssignmentByWorkspaceId = async (workspaceId) => {
    try {
      const response =
        await workspaceAssignmentService.getAllByWorkspaceAndUser(
          workspaceId,
          userId
        );
      if (response) {
        setWorkspaceAssignmentId(response[0].workspace_assignment_id);
        setDeleteBtnIsDisabled(!response[0].is_admin); // delete only if is_admin field is True
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const generateWorkspacePath = (value) => {
    const path = removeGapAndConvertToLowerCase(value);
    const url = `${BASE_URL}${path}`;
    setWorkspacePath(url);
  };

  const checkIfWorkspaceWithSuchNameExists = (workspaceNameToCheck) => {
    for (const userWorkspace of userWorkspaces) {
      const userWorkspaceName = removeGapAndConvertToLowerCase(
        userWorkspace.workspace_name
      );
      const modifiedWorkspaceNameToCheck =
        removeGapAndConvertToLowerCase(workspaceNameToCheck);
      if (modifiedWorkspaceNameToCheck === userWorkspaceName) {
        setError("You have already created a workspace with this name.");
      }
    }
  };

  useEffect(() => {
    setSaveBtnIsDisabled(false);
    if (error) {
      setSaveBtnIsDisabled(true);
    }
    if (workspaceId) {
      setDisableWorkspaceNameField(true);
      if (!updateItem) {
        fetchWorkspaceById(workspaceId);
        fetchWorkspaceAssignmentByWorkspaceId(workspaceId);
      }
      return;
    }
    if (checkName) {
      checkIfWorkspaceWithSuchNameExists(workspaceName);
    }
  }, [
    error,
    activeWorkspaceName,
    workspaceId,
    workspaceAssignmentId,
    workspaceIcon,
    workspaceName,
    effectiveWorkingHours,
    workspaceIcon,
    isDisableDashboards,
    userWorkspaces,
    checkName,
  ]);

  return (
    <>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="workspacePath">
                      <Form.Label>Workspace Path</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        value={workspacePath || ""}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="workspaceName">
                      <Form.Label>Workspace Name</Form.Label>
                      <Form.Control
                        disabled={disableWorkspaceNameField}
                        type="text"
                        maxLength={50}
                        value={workspaceName || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setCheckName(true);
                          checkIfWorkspaceWithSuchNameExists(e.target.value);
                          setWorkspaceName(e.target.value);
                          generateWorkspacePath(e.target.value);
                          setUpdateItem(true);
                        }}
                      />
                    </Form.Group>
                    <Form.Group id="effectiveWorkingHours" className="mt-2">
                      <Form.Label>
                        Effective Employee Working Hours (p.a)
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={effectiveWorkingHours || ""}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          if (!isNaN(Number(e.target.value))) {
                            setEffectiveWorkingHours(Number(e.target.value));
                          }
                          setUpdateItem(true);
                        }}></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={8} className="mb-3">
                    <Form.Group id="workspaceDescription">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        maxLength={255}
                        value={workspaceDescription || ""}
                        rows={6}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setWorkspaceDescription(e.target.value);
                          setUpdateItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="workspaceIcon">
                      <Form.Label>Workspace Icon</Form.Label>
                      <Form.Control
                        type="text"
                        value={workspaceIcon || ""}
                        maxLength={500}
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setWorkspaceIcon(e.target.value);
                          setUpdateItem(true);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="isDisableDashboards">
                      <Form.Check
                        type="switch"
                        defaultChecked={isDisableDashboards}
                        className="mb-4"
                        label="Disable Dashboards"
                        onClick={() => {
                          setError("");
                          setStatus("");
                          setIsDisableDashboards(!isDisableDashboards);
                          setUpdateItem(true);
                        }}></Form.Check>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <WorkspaceTable type="group" workspaceId={workspaceId} />
                <WorkspaceTable type="team" workspaceId={workspaceId} />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button
                  disabled={saveBtnIsDisabled}
                  variant="primary"
                  type="submit"
                  onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col md={3}>
                <h6 style={{ color: statusColor }}>{saveStatus}</h6>
              </Col>
              <Col md={2}>
                <Button
                  disabled={deleteBtnIsDisabled}
                  variant="primary"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
