import { PaginationControl } from "react-bootstrap-pagination-control";

export const TablePagination = (props) => {
  const { page, perPage, total, itemsLength, setPage } = props;

  return (
    <>
      <PaginationControl
        page={page}
        between={4}
        total={total}
        limit={perPage}
        changePage={(page) => setPage(page)}
        ellipsis={1}
      />
      <small className="fw-normal small mt-4 mt-lg-0">
        Showing <b>{itemsLength}</b> out of <b>{total}</b> entries
      </small>
    </>
  );
};
