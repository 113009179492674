import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class SolutionService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for creating solution item in database.
   * @param params
   * @returns solution id or rises Error
   */
  async create(params) {
    try {
      const urlWithParams = "/solutions/new?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data.solution_id;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for deleting solution item from database.
   * @param solutionId
   * @returns "OK" message or rises Error
   */
  async delete(solutionId) {
    try {
      await this.BASE_SERVICE.delete({
        url: `/solutions/${solutionId}`,
      });
      return "OK";
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting solution item from database.
   * @param solutionId
   * @returns solution db item or rises Error
   */
  async get(solutionId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/solutions/${solutionId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all solution items from database by workspace id.
   * @param workspaceId
   * @returns list of solutions or rises Error
   */
  async getAllByWorkspace(workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/solutions/all/filter_by?&workspace_id=${workspaceId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all solution items from database.
   * @returns "OK" message or rises Error
   */
  async getAll() {
    try {
      const data = await this.BASE_SERVICE.get({
        url: "/solutions/all",
      });
      return "OK";
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for updating all solution items in database.
   * @param solutionId
   * @param data
   * @returns "OK" message or rises Error
   */
  async update(solutionId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/solutions/${solutionId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for getting solution items from database by workspace id and pagination settings.
   * @param workspaceId
   * @param page
   * @param perPage
   * @returns pagination data with solution items or rises Error
   */
  async paginateByWorkspace(workspaceId, page, perPage) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/solutions/filter_with_pagination?&workspace_id=${workspaceId}&page=${page}&per_page=${perPage}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

    /**
   * Method is using for updating solution est_annual_benefit_value.
   * @param solutionId
   * @param data
   * @returns "OK" message or rises Error
   */
     async updateEstAnnualBenefit(solutionId, data) {
      try {
        await this.BASE_SERVICE.put({
          url: `/solutions/update_est_annual_benefit/${solutionId}`,
          body: data,
        });
        return "OK";
      } catch (e) {
        throw new Error(e.message);
      }
    }
}
