import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class LicenseService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for getting all license items from database by workspace id.
    * @param workspaceId
    * @returns list of licenses or rises Error
    */
    async getAllByWorkspace(workspaceId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/licenses/all/filter_by?&workspace_id=${workspaceId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
    * Method is using for getting all license items from database by tech id.
    * @param techId
    * @returns list of licenses or rises Error
    */
        async getAllByTechnology(techId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/licenses/all/filter_by?tech_id=${techId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
    * Method is using for creating license item in database.
    * @param params
    * @returns license id or rises Error
    */
    async create(params) {
        try {
            const urlWithParams = '/licenses/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.license_id;
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
     * Method is using for getting license item from database.
     * @param licenseId
     * @returns license db item or rises Error
     */
    async get(licenseId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/licenses/${licenseId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for deleting license item from database.
     * @param licenseId
     * @returns "OK" message or rises Error
     */
    async delete(licenseId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/licenses/${licenseId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        }
    };

    /**
    * Method is using for updating license item in database.
    * @param licenseId
    * @param data
    * @returns "OK" message or rises Error
    */
    async update(licenseId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/licenses/${licenseId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };
};
