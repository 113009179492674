import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class QueueHealthService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for creating solution health item in database.
    * @param params
    * @returns solution health id or rises Error
    */
    async create(params) {
        try {
            const urlWithParams = 'queue_health/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.queue_health_id;
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
    * Method is using for getting all queue health items from database by queue id.
    * @param queueId
    * @returns list of queue health items or rises Error
    */
    async getAllByQueueId(queueId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/queue_health/all/filter_by?queue_id=${queueId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for deleting queue health item from database.
     * @param queueHealthId
     * @returns "OK" message or rises Error
     */
    async delete(queueHealthId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/queue_health/${queueHealthId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        }
    };

    /**
* Method is using for updating all queue health items in database.
* @param queueHealthId
* @param data
* @returns "OK" message or rises Error
*/
    async update(queueHealthId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/queue_health/${queueHealthId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };
};
