import React, { useEffect } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useAction } from "hooks";
import {
  RelatedSolutionComponentsTableRow,
  RelatedSolutionQueueBenefitsTableRow,
  RelatedSolutionQueuesTableRow,
} from "../rows";

const componentsColumns = [
  { dateField: "componentName", text: "Name" },
  { dateField: "componentType", text: "Type" },
  { dateField: "componentHealth", text: "Health" },
];
const queuesColumns = [
  { dateField: "queueName", text: "Queue Name" },
  { dateField: "estBenefit", text: "Est. Benefit" },
  { dateField: "actBenefit", text: "Act. Benefit" },
];
const queueBenefitsColumns = [
  { dateField: "queueBenefitName", text: "Name" },
  { dateField: "queueBenefitCategory", text: "Category" },
  { dateField: "queueBenefitEstBenefit", text: "Est. Value (p.a.)" },
];

export const SolutionRelatedTable = (props) => {
  const {
    type,
    components,
    queues,
    queueBenefits,
    activeId,
    changeActiveId,
    createNew,
    onModalHide,
  } = props;
  const { queueChangesAction } = useAction();

  let tableName = "";
  let columns = [];
  switch (type) {
    case "component":
      columns = componentsColumns;
      tableName = "Related Solution Components";
      break;
    case "queue":
      columns = queuesColumns;
      tableName = "Related Solution Queues";
      break;
    case "queueBenefit":
      columns = queueBenefitsColumns;
      tableName = "Additional Queue Benefits";
      break;
    default:
      columns = [];
      tableName = "";
      break;
  }

  const handleCreateNewItem = () => {
    createNew();
    queueChangesAction(false);
  };

  useEffect(() => {}, [components, queues, queueBenefits, activeId]);

  return (
    <>
      <div className="mb-4">
        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mb-lg-0">
            <h6>{tableName}</h6>
          </div>
        </div>
        <div
          style={{ maxHeight: 210, overflowX: "hidden", overflowY: "scroll" }}>
          <Card border="0" className="shadow mb-3">
            <Card.Body>
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0">
                <thead className="thead-light">
                  <tr>
                    {columns.map((column, i) => {
                      return (
                        <th key={`component-column-${i}`} className="border-0">
                          {column.text}
                        </th>
                      );
                    })}
                    <th>
                      <Button
                        variant="primary"
                        size="sm"
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                        onClick={handleCreateNewItem}>
                        Create
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {type === "component"
                    ? components.map((row, i) => {
                        return (
                          <RelatedSolutionComponentsTableRow
                            key={`solution-component-${i}`}
                            activeId={activeId}
                            component={row}
                            changeActiveId={changeActiveId}
                          />
                        );
                      })
                    : null}
                  {type === "queue"
                    ? queues.map((row, i) => {
                        return (
                          <RelatedSolutionQueuesTableRow
                            key={`queue-${i}`}
                            activeId={activeId}
                            queue={row}
                            changeActiveId={changeActiveId}
                          />
                        );
                      })
                    : null}
                  {type === "queueBenefit"
                    ? queueBenefits.map((row, i) => {
                        return (
                          <RelatedSolutionQueueBenefitsTableRow
                            key={`queueBenefit-${i}`}
                            activeId={activeId}
                            queueBenefit={row}
                            changeActiveId={changeActiveId}
                            onModalHide={onModalHide}
                          />
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};
