import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";
import { Alert, Col, Row, Card, Form, Breadcrumb, Button, Spinner } from 'react-bootstrap';
import Table from "react-bootstrap-table-next";
import Pagination, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { Loading } from "pages";
import { useTypedSelector } from "hooks";
import { SessionService } from "services";
import { formatDate, handleError } from "utils";

const sessionService = new SessionService();

const sessionColumnsList = [
    "session_key",
    "component_key",
    "status",
    "status_stage",
    "tags",
    "start_time",
    "end_time",
    "row_modified",
    "component_id",
    "workspace_id",
    "resource_id",
];

const csvHeaders = [
    { label: "session_id", key: "sessionId"},
    { label: "session_key", key: "sessionKey"},
    { label: "component_key", key: "componentKey"},
    { label: "status", key: "status"},
    { label: "status_stage", key: "statusStage"},
    { label: "tags", key: "tags"},
    { label: "start_time", key: "startTime"},
    { label: "end_time", key: "endTime"},
    { label: "row_modified", key: "rowModified"},
    { label: "component_id", key: "componentId"},
    { label: "workspace_id", key: "workspaceId"},
    { label: "resource_id", key: "resourceId"},
];

export const Sessions = () => {
    const history = useHistory();

    const [sessions, setSessions] = useState([]);
    const [firstRun, setFirstRun] = useState(true);
    const [error, setError] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const activeWorkspaceId = useTypedSelector((state) => state.workspace.activeWorkspaceId);
  
    const columns = [
        { dataField: "session_key", text: "Session Key" },
        { dataField: "component_key", text: "Component Key" },
        { dataField: "status", text: "Status" },
        { dataField: "tags", text: "Tags" },
        { dataField: "start_time", text: "Start Time" },
        { dataField: "end_time", text: "End Time" },
    ];

    const customTotal = (from, to, size) => (
        <div>
          Showing {from} to {to} of {size} entries
        </div>
    );
    
    const customSizePerPage = (props) => {
    const { options, currentSizePerPage, onSizePerPageChange } = props;

    const onPageChange = (e) => {
        const page = e.target.value;
        onSizePerPageChange(page);
    };

    return (
        <Row as="label">
            <Col xs="auto">
            <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
                {options.map(o => (
                <option key={o.page} value={o.page}>
                    {o.text}
                </option>
                ))}
            </Form.Select>
            </Col>
            <Col xs="auto" className="d-flex align-items-center ps-0">
            entries per page
            </Col>
        </Row>
        );
    };

    const fetchSessions = async () => {
        try {
          const sessionResponse = await sessionService.getAllByWorkspace(activeWorkspaceId);
          setSessions(sessionResponse);
        } catch (e) {
            handleError(e, history);
        }
    };

    const postManySessions = async (formData) => {
        try {
          return await sessionService.createMany(activeWorkspaceId, formData);
        } catch (e) {
            handleError(e, history);
        }
    };

    const handleDataToDownload = () => {
        let dataToDownload = [];
        sessions.forEach((item) => {
          const dataRow = {
            sessionId: item.session_id,
            sessionKey: item.session_key,
            componentKey: item.component_key,
            status: item.status,
            statusStage: item.status_stage,
            tags: item.tags,
            startTime: item.start_time,
            endTime: item.end_time,
            rowModified: item.row_modified,
            componentId: item.component_id,
            workspaceId: item.workspace_id,
            resourceId: item.resource_id,
          };
          dataToDownload.push(dataRow);
        });
        return dataToDownload;
    };

    const handleCsvUpload = async (e) => {
        const files = e.target.files;
        if (files) {
          const file = files[0];
          Papa.parse(file, {
            complete: async function(results) {
              const columns = results.data[0];
              const missingColumns = [];
              sessionColumnsList.forEach(async (column) => {
                const columnIsIncluded = columns.includes(column);
                if (!columnIsIncluded) {
                  missingColumns.push(column);
                };
              });
              if (missingColumns.length > 0) {
                setError(`Csv file should contain such columns: ${missingColumns}.`);
                setStatus("");
                return;
              }
              let formData = new FormData();
              formData.append("file", file);
              const result = await postManySessions(formData);
              if (result) {
                setError("");
                setStatus("Sessions added successfully!");
                setFirstRun(true);
                return;
              } 
              setStatus("");
              setError("Failure occurred when adding sessions. Please check your data and try again!");
            }}
          );
        };
    };

    const handleClickUpload = (e) => {
        e.preventDefault();
        setError("");
        setStatus("");
        document.getElementById('getCsvFile').click();
      };
    
    useEffect(() => {
        if (firstRun) {
          setIsLoading(true);
          fetchSessions();
          setFirstRun(false);
          setIsLoading(false);
        };
    }, [error, firstRun, isLoading, status, sessions]); 
    
    return (
        <>
          <div className="py-4">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
              <Breadcrumb.Item>Tables</Breadcrumb.Item>
              <Breadcrumb.Item active>Sessions</Breadcrumb.Item>
            </Breadcrumb>
            <div className="d-flex justify-content-between w-100 flex-wrap">
              <div className="mb-3 ms-4 mb-lg-0">
                <h4>Sessions</h4>
              </div>
              <Col>
                {isLoading ? <Loading marginTop="1%" marginLeft="45%" /> : null}
              </Col>
              <CSVLink className="ms-100 me-2"
                filename={`sessions_${formatDate(new Date())}.csv`}
                data={handleDataToDownload()}
                headers={csvHeaders}
                separator=","
              >
                  <Button style={{paddingTop: "15px", paddingBottom: "15px"}}>
                      Export
                  </Button>
              </CSVLink>
                <Button onClick={handleClickUpload} style={{paddingTop: "10px", paddingBottom: "10px"}}>
                  <input
                    type="file"
                    accept=".csv"
                    id="getCsvFile"
                    onChange={handleCsvUpload}
                    style={{display: "none"}}
                  />
                  Upload
                </Button>
            </div> 
          </div>
          {error ?
            <Alert key='danger' variant='danger'>
                {error}
            </Alert>
          : null}
          {status ?
            <Alert key='success' variant='success'>
                {status}
            </Alert>
          : null}
          { !isLoading ? 
          <ToolkitProvider
            keyField="session_id"
            columns={columns}
            data={sessions}
          >
            {({ baseProps, searchProps }) => (
              <PaginationProvider pagination={
                Pagination({
                  custom: true,
                  showTotal: true,
                  alwaysShowAllBtns: true,
                  totalSize: sessions.length,
                  withFirstAndLast: false,
                  paginationTotalRenderer: customTotal,
                  sizePerPageRenderer: customSizePerPage
                })
              }>
                {({ paginationProps, paginationTableProps }) => (
                  <Card border="0" className="shadow mb-4">
                    <Card.Body >   
                      <Table
                        {...baseProps}
                        {...paginationTableProps}
                        headerWrapperClasses="thead-light"
                        bodyClasses="border-0"
                        rowClasses="border-bottom"
                        classes="table-flush dataTable-table"
                      />
                      <div className="dataTable-bottom">
                        <div className="dataTable-info">
                          <PaginationTotalStandalone {...paginationProps} />
                        </div>
                        <div className="dataTable-pagination">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </PaginationProvider>
            )}
          </ToolkitProvider> : null}
        </>
    );
};
