import React from "react";
import { EditSolution, SolutionHeader } from "./components";

export const CreateDashboardSolution = () => {

    return (
        <>
            <SolutionHeader type="new" />
            <EditSolution type="new" />
        </>
    )
}
