import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useAction } from "hooks";
import { LicenseService } from "services";
import { handleError } from "utils";

const licenseService = new LicenseService();

const fetchLicenses = async ({ queryKey }) => {
  const [_, techId] = queryKey;
  if (techId) {
    return await licenseService.getAllByTechnology(techId);
  }
};

export const TechnologyTableRow = (props) => {
  const { technology } = props;
  const history = useHistory();
  const { updateActiveTechnologyIdAction, setTechnologyConfigTabAction } =
    useAction();

  const licensesQuery = useQuery(
    ["licensesForTechnologyRow", technology.tech_id],
    fetchLicenses,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const licenses = licensesQuery.data ? licensesQuery.data : [];

  const handleChangeActiveId = () => {
    updateActiveTechnologyIdAction(technology.tech_id);
    setTechnologyConfigTabAction();
  };

  const handleCountLicensesTotalCost = () => {
    let total = 0;
    licenses.forEach((item) => {
      total = total + Number(item.total_est_cost);
    });
    return `$${total}`;
  };

  return (
    <tr className="border-bottom">
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {technology.tech_id || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {technology.tech_name || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {technology.tech_description || ""}
        </div>
      </td>
      <td className="fw-bold">
        <div className="d-flex align-items-center">
          {handleCountLicensesTotalCost()}
        </div>
      </td>
      <td>
        <Button
          variant="primary"
          size="sm"
          onClick={handleChangeActiveId}
          style={{
            backgroundColor: "blue",
            color: "white",
            paddingTop: 1,
            paddingBottom: 1,
          }}>
          Config
        </Button>
      </td>
    </tr>
  );
};
