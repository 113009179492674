import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { WorkspaceGroupTableRow, WorkspaceTeamTableRow } from "./rows";
import { AddItemModal } from "../components";
import { BusinessUnitService, GroupService } from "services";
import { handleError } from "utils";

const groupService = new GroupService();
const businessUnitService = new BusinessUnitService();

const groupColumns = [
  { dateField: "groupName", text: "Group Name" },
  { dateField: "description", text: "Description" },
];

const teamColumns = [
  { dateField: "teamName", text: "Business Unit Name" },
  { dateField: "description", text: "Description" },
];

const fetchBusinessUnits = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await businessUnitService.getAllByWorkspace(workspaceId);
  }
};

const fetchGroups = async ({ queryKey }) => {
  const [_, workspaceId] = queryKey;
  if (workspaceId) {
    return await groupService.getAllByWorkspace(workspaceId);
  }
};

export const WorkspaceTable = (props) => {
  const { type, workspaceId } = props;
  const history = useHistory();
  // booleans
  const [modalShow, setModalShow] = useState(false);
  const [itemHasChanged, setItemHasChanged] = useState(true);
  // ids
  const [activeTableItemId, setActiveTableItemId] = useState("");

  const businessUnitsQuery = useQuery(
    ["workspaceBusinessUnitsData", workspaceId],
    fetchBusinessUnits,
    { onError: (e) => handleError(e, history) }
  );

  const businessUnits = businessUnitsQuery.data ? businessUnitsQuery.data : [];

  const groupsQuery = useQuery(["workspaceGroupsData", workspaceId], fetchGroups, {
    onError: (e) => handleError(e, history),
  });

  const groups = groupsQuery.data ? groupsQuery.data : [];

  let tableName = "";
  let columns = [];
  switch (type) {
    case "group":
      columns = groupColumns;
      tableName = "Workspace Groups";
      break;
    case "team":
      columns = teamColumns;
      tableName = "Workspace Business Units";
      break;
    default:
      tableName = "";
      columns = [];
      break;
  }

  const handleChangeActiveId = (e) => {
    setActiveTableItemId(e);
    setModalShow(true);
    setItemHasChanged(false);
  };

  useEffect(() => {
      if (itemHasChanged) {
        businessUnitsQuery.refetch();
        groupsQuery.refetch();
      }
  }, [activeTableItemId, itemHasChanged, type]);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mb-lg-0">
            <h6>{tableName}</h6>
          </div>
        </div>
        <AddItemModal
          type={type}
          show={modalShow}
          onHide={() => setModalShow(false)}
          workspaceId={workspaceId}
          activeItemId={activeTableItemId}
          changeActiveId={(e) => setActiveTableItemId(e)}
          itemHasChanged={(e) => setItemHasChanged(e)}
        />
        <div
          style={{ maxHeight: 210, overflowX: "hidden", overflowY: "scroll" }}>
          <Card border="0" className="shadow mb-3">
            <Card.Body>
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0">
                <thead className="thead-light">
                  <tr>
                    {columns.map((column, i) => {
                      return (
                        <th key={`column-${i}`} className="border-0">
                          {column.text}
                        </th>
                      );
                    })}
                    {workspaceId ? (
                      <th>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            setModalShow(true);
                            setItemHasChanged(false);
                          }}
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            paddingTop: 1,
                            paddingBottom: 1,
                            paddingLeft: 16,
                            paddingRight: 16,
                            marginRight: 20,
                          }}>
                          Add
                        </Button>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {type === "group"
                    ? groups.map((row, i) => {
                      return (
                        <WorkspaceGroupTableRow
                          key={`group-${i}`}
                          activeId={activeTableItemId}
                          group={row}
                          changeActiveId={handleChangeActiveId}
                        />
                      );
                    })
                    : null}
                  {type === "team"
                    ? businessUnits.map((row, i) => {
                      return (
                        <WorkspaceTeamTableRow
                          key={`businessUnit-${i}`}
                          activeId={activeTableItemId}
                          team={row}
                          changeActiveId={handleChangeActiveId}
                        />
                      );
                    })
                    : null}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};
