import React from "react";
import * as XLSX from "xlsx";
import { MultiTiles } from "./TilesInside";

export const Tiles = (props) => {
  const { wb, tilesFilterObject } = props;

  var tilesData = generateTilesDataWithFilters(
    wb,
    tilesFilterObject.filterObject
  );

  function generateTilesDataWithFilters(workbookData, filterObject) {
    var objTilesData = {
      "Estimated Annualised Benefit Q": 0,
      "Actual Benefit Life to Date Q": 0,
      "Expected Transaction Volume Q": 0,
      "Actual Transaction Volume Q": 0,
      Portfolio: 0,
      BusinessUnit: 0,
      "Processes in Production": 0,
      "Other Processes": 0,
    };
    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey !== "" &&
            ele.BusinessUnitKey === filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey !== "" &&
            ele.StageKey === filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        });
      }

      // var bussinessUnitKeys = filteredSheetData.map((ele) => {
      //   return ele.BusinessUnitKey;
      // });

      //Estimate vs Actual annualised benefit
      //Expected vs Actual monthly volume tile data
      var uniqueIdentifiers = filteredSheetData.map((ele) => {
        return ele.UniqueIdentifier;
      });

      var unique_UniqueIdentifiers = uniqueIdentifiers.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      var factQueueSheet = workbookData.Sheets["FactQueue"];
      var FactQueueData = XLSX.utils.sheet_to_json(factQueueSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filterFactQueue = [];
      unique_UniqueIdentifiers.forEach((el) => {
        FactQueueData.forEach((element) => {
          if (element["Unique Identifier"] === el) {
            filterFactQueue.push(element);
          }
        });
      });

      var totalExpAnnualBenefit = 0,
        totalActualBenefit = 0,
        totalExpectedVolume = 0,
        totalActualVolume = 0;

      filterFactQueue.forEach((element) => {
        var expAnnualBenefit =
          element["Expected Annualised Benefit"] === ""
            ? 0
            : element["Expected Annualised Benefit"];
        totalExpAnnualBenefit = totalExpAnnualBenefit + expAnnualBenefit;

        var actualBenefit =
          element["Actual Benefit"] === "" ? 0 : element["Actual Benefit"];
        totalActualBenefit = totalActualBenefit + actualBenefit;

        var expectedVolume =
          element["Expected Volume"] === "" ? 0 : element["Expected Volume"];
        totalExpectedVolume = totalExpectedVolume + expectedVolume;

        var actualVolume =
          element["Actual Volume"] === "" ? 0 : element["Actual Volume"];
        totalActualVolume = totalActualVolume + actualVolume;
      });

      objTilesData["Estimated Annualised Benefit Q"] = totalExpAnnualBenefit;
      objTilesData["Actual Benefit Life to Date Q"] = totalActualBenefit;
      objTilesData["Expected Transaction Volume Q"] = totalExpectedVolume;
      objTilesData["Actual Transaction Volume Q"] = totalActualVolume;
      //Estimate vs Actual annualised benefit end
      //Expected vs Actual monthly volume tile data end

      //Portfolios tile data
      // var dimPortfolioSheet = workbookData.Sheets["DimPortfolio"];
      // var dimPortfolioData = XLSX.utils.sheet_to_json(dimPortfolioSheet, {
      //   raw: true,
      //   defval: "",
      //   blankRows: false,
      // });

      var portfolioKeys = filteredSheetData.map((ele) => {
        return ele.PortfolioKey;
      });

      var uniquePortfolioKeys = portfolioKeys.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      objTilesData["Portfolio"] = uniquePortfolioKeys.length;
      //Portfolios tile data end

      //Business Units tile data
      // var dimBUSheet = workbookData.Sheets["DimBU"];
      // var dimBUData = XLSX.utils.sheet_to_json(dimBUSheet, {
      //   raw: true,
      //   defval: "",
      //   blankRows: false,
      // });

      var businessUnitKeys = filteredSheetData.map((ele) => {
        return ele.BusinessUnitKey;
      });

      var uniqueBusinessUnitKeys = businessUnitKeys.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      objTilesData["BusinessUnit"] = uniqueBusinessUnitKeys.length;
      //Business Units tile data end
      //Processes in production vs other processes tiles data
      var dimStageSheet = workbookData.Sheets["DimStage"];
      var dimStageData = XLSX.utils.sheet_to_json(dimStageSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var stageKeys = filteredSheetData.map((ele) => {
        return ele.StageKey;
      });

      var uniqueStageKeys = stageKeys.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      var mappedStages = [];
      uniqueStageKeys.forEach((el) => {
        dimStageData.forEach((element) => {
          if (element.StageKey === el) {
            mappedStages.push(element);
          }
        });
      });

      var productionStage = mappedStages.filter((element) => {
        return element["Stage of Development"] === "Production";
      });

      var productionProcesses = [];
      var otherProcesses = [];

      //listing processes type
      filteredSheetData.forEach((element) => {
        if (productionStage.length > 0) {
          if (element["StageKey"] === productionStage[0]["StageKey"]) {
            productionProcesses.push(element);
          }
        } else {
          otherProcesses.push(element);
        }
      });

      objTilesData["Processes in Production"] = productionProcesses.length;
      objTilesData["Other Processes"] = otherProcesses.length;
      //Processes in production vs other processes tiles data end
    }
    return objTilesData;
  }

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  function kFormatterForAmount(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <>
      <div
        className="rounded my-1 py-1 mb-5"
        style={{
          background: "#e1e1e1",
          textAlign: "center",
          fontWeight: "bold",
          padding: "1px",
        }}
      >
        <h6 style={{ marginBottom: "0" }}>Overview<a className="info" style={{
                  border: '1px solid #000',
                  borderRadius: '50px',
                  fontSize: '9px',
                  margin: 0,
                  fontWeight: 'bold',
                  padding: '0px 4px',
                  color: 'black',
                  verticalAlign: 'middle',
                  marginLeft: '5px'
                }}>i</a></h6>
      </div>
      <MultiTiles
        color1={"#000c55"}
        color2={"#0059ff"}
        dollarSign="$"
        tile1value={kFormatterForAmount(tilesData["Estimated Annualised Benefit Q"])}
        tile1text="Estimated Annualised Benefit"
        tile2value={kFormatterForAmount(tilesData["Actual Benefit Life to Date Q"])}
        tile2text="Actual Benefit Life to Date"
      />

      <MultiTiles
        color1={"#000c55"}
        color2={"#0059ff"}
        tile1value={kFormatter(tilesData["Expected Transaction Volume Q"])}
        tile1text="Expected Transaction Volume"
        tile2value={kFormatter(tilesData["Actual Transaction Volume Q"])}
        tile2text="Actual Transaction Volume"
      />

      <MultiTiles
        color1={"#0059ff"}
        color2={"#0059ff"}
        tile1value={kFormatter(tilesData["Processes in Production"])}
        tile1text="Processes in Production"
        tile2value={kFormatter(tilesData["Other Processes"])}
        tile2text="Other Processes"
      />

      <MultiTiles
        color1={"#0059ff"}
        color2={"#0059ff"}
        tile1value={kFormatter(tilesData.BusinessUnit)}
        tile1text="Business Unit"
        tile2value={kFormatter(tilesData.Portfolio)}
        tile2text="Portfolio"
      />
    </>
  );
};
