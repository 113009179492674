import React, { useState } from "react";
import { Route } from "react-router-dom";
import { Footer, Sidebar, Topbar } from "components";

export const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const localStorageIsContracted = () => {
    return localStorage.getItem("sidebarContracted") === "false" ? false : true;
  };

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(
    localStorageIsContracted()
  );
  const [showSettings, setShowSettings] = useState(false);

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem("sidebarContracted", !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <>
            <Sidebar
              contracted={contractSidebar}
              onMouseEnter={toggleMouseOver}
              onMouseLeave={toggleMouseOver}
            />

            <main className="content">
              <Topbar toggleContracted={toggleContracted} />
              <Component {...props} />
              <Footer
                toggleSettings={toggleSettings}
                showSettings={showSettings}
              />
            </main>
          </>
        )}
      />
    </>
  );
};
