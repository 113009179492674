import { Spinner } from "react-bootstrap";

export const Loading = (props) => {
  const { marginTop, marginLeft } = props;
  
  return (
    <Spinner
      animation="border"
      variant="primary"
      style={{ marginTop: marginTop, marginLeft: marginLeft }}
    />
  );
};
