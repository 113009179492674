import React, { useState } from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import {
  Alert,
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { UserService, WorkspaceAssignmentService } from "services";
import { handleError } from "utils";

const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();

export const ForgotPassword = () => {
  const history = useHistory();
  // notifiers
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  // fields
  const [email, setEmail] = useState("");
  // booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  const [resetBtnIsDisabled, setResetBtnIsDisabled] = useState(false);
  // style
  const statusColor = isSaveTypeSuccessful ? "success" : "danger";

  const isResetSuccessfully = () => {
    setStatus(
      "Password has been reset successfully! We have sent you a letter to the provided email."
    );
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringReseting = (e) => {
    const errorMsg = e ? e.toString() : "Failure occurred. Please, try again.";
    setStatus(errorMsg);
    setIsStatusTypeSuccessful(false);
  };

  const getUserIdByEmail = async () => {
    try {
      return await userService.getUserIdByEmail(email);
    } catch (e) {
      failedDuringReseting(e.message);
      handleError(e, history);
    }
  };

  const fetchWorkspaceAssignmentsIdsByUser = async (userId) => {
    try {
      return await workspaceAssignmentService.getIdsByUser(userId);
    } catch (e) {
      handleError(e, history);
    }
  };

  const updateUserWorkspaceAssignments = async (ids) => {
    try {
      const data = {
        is_verified: "false",
      };
      if (ids.length > 0) {
        for (const item of ids) {
          const workspaceAssignmentId = item.workspace_assignment_id;
          await workspaceAssignmentService.updateVerifyStatus(
            workspaceAssignmentId,
            data
          );
        }
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const resetUserPassword = async (userId) => {
    try {
      return await userService.resetPassword(userId);
    } catch (e) {
      failedDuringReseting(e.message);
      handleError(e, history);
    }
  };

  const sendResetPasswordEmail = async (userId) => {
    try {
      const data = {
        userId: userId,
      };
      return await userService.sendResetPasswordEmail(data);
    } catch (e) {
      failedDuringReseting(e.message);
      handleError(e, history);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setResetBtnIsDisabled(true);
    setError("");
    setStatus("");
    if (!email) {
      setError("Please, enter your email!");
      setResetBtnIsDisabled(false);
      return;
    }
    const userId = await getUserIdByEmail();
    if (userId) {
      const response = await resetUserPassword(userId);
      if (response) {
        const userWorkspaceAssignmentIds =
          await fetchWorkspaceAssignmentsIdsByUser(userId);
        await updateUserWorkspaceAssignments(userWorkspaceAssignmentIds);
        const emailIsSent = await sendResetPasswordEmail(userId);
        if (emailIsSent) {
          isResetSuccessfully();
        }
      }
    }
    setResetBtnIsDisabled(false);
  };

  return (
    <main>
      <section className="vh-lg-100 mt-5 pt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.SignIn.path}
                className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">
                  Don't fret! Just type in your email and we will send you a
                  code to reset your password!
                </p>
                {error ? (
                  <Alert key="danger" variant="danger">
                    {error}
                  </Alert>
                ) : null}
                {status ? (
                  <Alert key={statusColor} variant={statusColor}>
                    {status}
                  </Alert>
                ) : null}
                <Form>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control
                        value={email}
                        autoFocus
                        type="email"
                        placeholder="john@company.com"
                        onChange={(e) => {
                          setError("");
                          setStatus("");
                          setEmail(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="submit"
                      onClick={onSubmit}
                      disabled={resetBtnIsDisabled}>
                      Reset password
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
