import ActionTypes from "../actions/ActionTypes";

const initialState = {
  addLicense: false,
  activeLicenseId: "",
  configLicense: false,
  fetchAllLicenses: true,
  licenses: [],
};

export const licenseReducer = (
  state = initialState,
  action 
) => {
  switch (action.type) {
    case ActionTypes.ACTIVE_LICENSE_ID:
      return {
        ...state,
        activeLicenseId: action.payload,
      };
    case ActionTypes.FETCH_ALL_LICENSES:
      return {
          ...state,
          fetchAllLicenses: action.payload,
      };
    case ActionTypes.ADD_LICENSE:
      return {
        ...state,
        addLicense: action.payload,
      };
    case ActionTypes.CONFIG_LICENSE:
      return {
        ...state,
        configLicense: action.payload,
      };
    case ActionTypes.LICENSES:
      return {
        ...state,
        licenses: action.payload,
      };
    case ActionTypes.RESET_LICENSES:
      return {
        ...state,
        addLicense: false,
        activeLicenseId: "",
        configLicense: false,
        fetchAllLicenses: true,
        licenses: [],
      };
    default:
      return state;
  }
};
