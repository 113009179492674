const ActionTypes = {
    // auth types
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",

    // license types
    ACTIVE_LICENSE_ID: "ACTIVE_LICENSE_ID",
    ADD_LICENSE: "ADD_LICENSE", 
    CONFIG_LICENSE: "CONFIG_LICENSE",
    FETCH_ALL_LICENSES: "FETCH_ALL_LICENSES",
    LICENSES: "LICENSES",
    RESET_LICENSES: "RESET_LICENSES",

    // queue types
    QUEUE_CHANGES: "QUEUE_CHANGES",

    // tabs types
    TECHNOLOGIES_TAB: "TECHNOLOGIES_TAB",
    TECHNOLOGY_CONFIG_TAB: "TECHNOLOGY_CONFIG_TAB",
    TECHNOLOGY_NEW_TAB: "TECHNOLOGY_NEW_TAB",
    RESET_TECHNOLOGY_TABS: "RESET_TECHNOLOGY_TABS",
    
    // technology types
    ACTIVE_TECHNOLOGY_ID: "ACTIVE_TECHNOLOGY_ID",
    FETCH_ALL_TECHNOLOGIES: "FETCH_ALL_TECHNOLOGIES",
    TECHNOLOGY_TOTAL_EST_COST: "TECHNOLOGY_TOTAL_EST_COST",
    RESET_TECHNOLOGIES: "RESET_TECHNOLOGIES",

    // user types
    USER_ID: "USER_ID",
    USER_DEFAULT_WORKSPACE: "USER_DEFAULT_WORKSPACE",
    ALL_USERS_BY_WORKSPACE: "ALL_USERS_BY_WORKSPACE",

    // workspace types
    ACTIVE_WORKSPACE_ID: "ACTIVE_WORKSPACE_ID",
    ACTIVE_WORKSPACE_NAME: "ACTIVE_WORKSPACE_NAME",
    UPLOAD_WORKSPACES: "UPLOAD_WORKSPACES",
    LOAD_USER_WORKSPACES: "LOAD_USER_WORKSPACES",
    ADD_WORKSPACE: "ADD_WORKSPACE",
    REMOVE_WORKSPACE: "REMOVE_WORKSPACE",
    UPDATE_WORKSPACE: "UPDATE_WORKSPACE",
    DELETE_ACTIVE_WORKSPACE: "DELETE_ACTIVE_WORKSPACE",
    DISABLE_DASHBOARDS: "DISABLE_DASHBOARDS",
    DEFAULT_WORKSPACE_ID: "DEFAULT_WORKSPACE_ID",
    DEFAULT_WORKSPACE_NAME: "DEFAULT_WORKSPACE_NAME",

    // workspace assignment types
    CURRENT_WORKSPACE_ASSIGNMENT: "CURRENT_WORKSPACE_ASSIGNMENT", 
    ADD_ADMIN_WORKSPACE_ASSIGNMENT: "ADD_ADMIN_WORKSPACE_ASSIGNMENT",
    REMOVE_ADMIN_WORKSPACE_ASSIGNMENT: "REMOVE_ADMIN_WORKSPACE_ASSIGNMENT",
    RESET_ADMIN_WORKSPACE_ASSIGNMENTS: "RESET_ADMIN_WORKSPACE_ASSIGNMENTS",
};

export default ActionTypes;
