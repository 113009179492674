import ActionTypes from "../actions/ActionTypes";

const userIsAuthenticated = localStorage.getItem("access_token");

const initialState = {
  loggedIn: userIsAuthenticated ? true : false,
};

export const authReducer = (
  state = initialState,
  action 
) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
      };
    case ActionTypes.LOGOUT_SUCCESS:
      return { 
        ...state, 
        loggedIn: false, 
    };
    case ActionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        loggedIn: true,
      };
    default:
      return state;
  }
};
