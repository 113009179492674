import { isNil, isEmpty } from "lodash";

export const checkEmptyField = (value, errorName, setError, type = null) => {
    if (type === "date" && value === null) {
        setError(`Please set ${errorName} value.`);
        return "err";
    }
    if (type === "select" && value === "") {
        setError(`Please select ${errorName} value.`);
        return "err";
    };
    if (typeof value === "number"){
        if (isNil(value)) {
            setError(`Please set ${errorName} value.`);
            return "err";
        };
    } else { 
        if (isNil(value) || isEmpty(value)) {
            setError(`Please set ${errorName} value.`);
            return "err";
        };
    };    
    return "OK";
};
