// =========================================================
// * Cognitive Automation Labs Dashboard
// =========================================================

// * Product Page: https://cognitiveautomationlabs.com/product/dashboard/volt-pro-react
// * Copyright 2021 Cognitive Automation Labs (https://www.cognitiveautomationlabs.com)
// * License Information (https://cognitiveautomationlabs.com/licensing)

// * Designed and coded by https://cognitiveautomationlabs.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import store from "./redux/index";

// core styles
import "./scss/volt.scss";

// vendor styles
import "leaflet/dist/leaflet.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datetime/css/react-datetime.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ScrollToTop } from "components";
import { LoadApp } from "pages";

const queryClient = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <HashRouter hashType="noslash">
          <ScrollToTop />
          <LoadApp />
        </HashRouter>
      </Provider>
    </QueryClientProvider>
  , document.getElementById("root")
);
