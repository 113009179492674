import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class UserService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for getting user item from database.
   * @param userId
   * @returns user db item or rises Error
   */
  async get(userId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/users/${userId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting user id data by email address from database.
   * @param userEmail
   * @returns user id data or rises Error
   */
  async getUserIdByEmail(userEmail) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/users/by-email?email_address=${userEmail}`,
      });
      return data.user_id;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Method is using for getting user name and email data from database.
   * @param userId
   * @returns user data or rises Error
   */
  async getVerifyUserData(userId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/users/verify-data/${userId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }
  /**
   * Method is using for updating user item in database.
   * @param userId
   * @param data
   * @returns "OK" message or rises Error
   */
  async update(userId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/users/${userId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for deleting user from database.
   * @param userId
   * @returns "OK" message or rises Error
   */
  async delete(userId) {
    try {
      await this.BASE_SERVICE.delete({
        url: `/users/${userId}`,
      });
      return "OK";
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all users from database by workspace id.
   * @param workspaceId
   * @returns list of users or rises Error
   */
  async getAllByWorkspace(workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/users/all/filter_by?&workspace_id=${workspaceId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for getting all users from database by email.
   * @param email
   * @returns list of users or rises Error
   */
  async getAllByEmail(email) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/users/all/filter_by?&email_address=${email}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Method is using for creating user without password data in database.
   * @param params
   * @returns user id or rises Error
   */
  async createWithoutPassword(params) {
    try {
      const urlWithParams = "/users/new/to_verify?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data.user_id;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Method is using for creating user without password data in database.
   * @param params
   * @returns user id or rises Error
   */
  async sendInviteToWorkspace(params) {
    try {
      const urlWithParams = "/users/invite?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data.user_id;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Method is using for updating user password data in database.
   * @param userId
   * @param data
   * @returns "OK" message or rises Error
   */
  async updatePassword(userId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/users/update-password/${userId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for reseting user password.
   * @param userId
   * @returns "OK" message or rises Error
   */
  async resetPassword(userId) {
    try {
      await this.BASE_SERVICE.get({
        url: `/users/reset-password/${userId}`,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for sending an email to user about password reseting.
   * @param params
   * @returns "OK" message or rises Error
   */
  async sendResetPasswordEmail(params) {
    try {
      const urlWithParams = "/users/send-reset-password-email?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      return await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
    } catch (e) {
      throw new Error(e.message);
    }
  }
}
