import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class SolutionHealthService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for creating solution health item in database.
    * @param params
    * @returns solution health id or rises Error
    */
    async create(params) {
        try {
            const urlWithParams = 'solution_health/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.solution_health_id;
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
    * Method is using for updating all solution health items in database.
    * @param solutionHealthId
    * @param data
    * @returns "OK" message or rises Error
    */
    async update(solutionHealthId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/solution_health/${solutionHealthId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
    * Method is using for getting all solution health items from database by component id.
    * @param componentId
    * @returns list of solution health items or rises Error
    */
    async getBySolutionComponentId(componentId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/solution_health/all/filter_by?&component_id=${componentId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for deleting solution health item from database.
     * @param solutionHealthId
     * @returns "OK" message or rises Error
     */
    async delete(solutionHealthId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/solution_health/${solutionHealthId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        }
    };
};
