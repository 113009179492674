import React from "react";
import * as XLSX from "xlsx";
import * as d3 from "d3";
import "./tableComponent.css";

export const BusinessUnitTable = (props) => {
  const { wb, BUFilterObject, tableName } = props;
  var chartWidth = "90px";

  // Dummy Data
  // var data = [
  //   { Portfolio: "Corporate Services", Process: 10, "Actual Volume": 28303 },
  //   { Portfolio: "", Process: 8, "Actual Volume": 111 },
  // ];

  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    var filteredData = getPortfolioGridDataForDetailDashboard(
      wb,
      BUFilterObject.filterObject
    );
    businessUnitGrid(filteredData, chartWidth, "businessUnitGrid");
  }

  function businessUnitGrid(data, chartWidth, containerId) {
    var totalProcess = d3.sum(data, function (d, i) {
      return d.Process;
    });
    // AV actual volume
    var totalAV = d3.sum(data, function (d, i) {
      return d["Actual Volume"];
    });
    var x = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d, i) {
          return Math.abs(d.Process);
        }),
      ])
      .range(["0%", "100%"]);
    d3.select(".classTable1").remove();
    var table = d3
      .select("#" + containerId)
      .append("table")
      .attr("class", "classTable1")
      .attr("width", "100%")
      .attr("height", "100");

    var totalRow = table.append("tr");

    totalRow.append("th").attr("class", "totalDesc").text("Business Unit ");
    totalRow.append("th").attr("class", "totalDesc").text("Solution");
    totalRow.append("th").attr("class", "totalDesc").text("Actual Volume");

    // Create a table with rows and bind a data row to each table row
    var tr = table
      .selectAll("tr.data")
      .data(data)
      .enter()
      .append("tr")
      .attr("class", "datarow");

    // Set the even columns
    table
      .selectAll(".datarow")
      .filter(":nth-child(odd)")
      .attr("class", "datarow odd");

    // Create the name column
    tr.append("td")
      .attr("class", "data name")
      .text(function (d, i) {
        return d.Portfolio;
      });

    // Create a column at the beginning of the table for the chart
    var chart = tr
      .append("td")
      .attr("class", "chart")
      .attr("width", chartWidth);

    // Create the div structure of the chart
    chart
      .append("div")
      .attr("class", "container tdLeftBorder rightBarText divClass")
      .style("float", "center")
      .text(function (d, i) {
        return "$" + d.Process;
      })
      .attr("valign", "center")
      .style("text-align", "right")
      .style("font-size", "12px")
      .append("div")
      .style("height", "100%")
      .attr("class", "positive5 barLeftToRight");

    // Creates the positive div bar
    tr.select("div.positive5")
      .style("width", "0")
      .transition()
      .duration(500)
      .style("width", function (d, i) {
        return x(d.Process);
      });

    tr.append("td")
      .attr("class", "data name")
      .text(function (d, i) {
        return d["Actual Volume"];
      })
      .style("text-align", "right");

    var totalTr = table.append("tr").attr("class", "bordered");
    // Add the Total row
    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("Total");

    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("$" + totalProcess)
      .style("text-align", "right");

    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("$" + totalAV)
      .style("text-align", "right");
  }

  function getPortfolioGridDataForDetailDashboard(workbookData, filterObject) {
    var data = [];

    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit != 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey != "" &&
            ele.BusinessUnitKey == filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio != 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey != "" && ele.PortfolioKey == filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment != 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey != "" &&
            ele.StageKey == filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName != "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name != "" && ele.Name == filterObject.SolutionsName;
        });
      }

      //DimPortfolio Sheet data
      var dimPortfolioSheet = workbookData.Sheets["DimPortfolio"];
      var dimPortfolioData = XLSX.utils.sheet_to_json(dimPortfolioSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredRecordsByPortfolio = {};
      dimPortfolioData.forEach((portfolio) => {
        filteredSheetData.forEach((factSol) => {
          if (factSol.PortfolioKey == portfolio.PortfolioKey) {
            factSol.Portfolio = portfolio.Portfolio;
            filteredRecordsByPortfolio[factSol.UniqueIdentifier] = factSol;
          }
        });
      });

      //FactQueue Sheet data
      var factQueueSheet = workbookData.Sheets["FactQueue"];
      var factQueueData = XLSX.utils.sheet_to_json(factQueueSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredRecords = [];
      factQueueData.forEach((factQ) => {
        if (filteredRecordsByPortfolio[factQ["Unique Identifier"]]) {
          var existedObject =
            filteredRecordsByPortfolio[factQ["Unique Identifier"]];
          var object = {
            Portfolio: existedObject.Portfolio,
            Process: 1,
            "Actual Volume":
              factQ["Actual Volume"] == "" ? 0 : factQ["Actual Volume"],
          };
          filteredRecords.push(object);
        } else {
          var object = {
            Portfolio: "",
            Process: 1,
            "Actual Volume":
              factQ["Actual Volume"] == "" ? 0 : factQ["Actual Volume"],
          };
          filteredRecords.push(object);
        }
      });
      var groupedData = [];
      var lookUp = [];
      var index;
      filteredRecords.forEach((element) => {
        if (lookUp.indexOf(element.Portfolio) > -1) {
          var index = lookUp.indexOf(element.Portfolio);
          groupedData[index].Process += 1;
          groupedData[index]["Actual Volume"] += element["Actual Volume"];
        } else {
          lookUp.push(element.Portfolio);
          groupedData.push(element);
        }
      });
      data = groupedData;
    }
    return data;
  }

  return (
    <>
      <div className="rounded mt-2 mb-3 py-1" style={{ background: "#e1e1e1" }}>
        <h6 style={{ marginBottom: "0", textAlign: "center" }}>{tableName}</h6>
      </div>
      <div id="businessUnitGrid"></div>
    </>
  );
};
