import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class WorkspaceService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for creating workspace item in database.
    * @param params
    * @returns workspace id or rises Error
    */
    async create(params) {
        console.log("PARAMS", params)
        try {
            const urlWithParams = '/workspaces/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data;
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
     * Method is using for getting workspace item from database.
     * @param workspaceId
     * @returns workspace db item or rises Error
     */
    async get(workspaceId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/workspaces/${workspaceId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };
    
    /**
     * Method is using for deleting workspace item from database.
     * @param workspaceId
     * @returns "OK" message or rises Error
     */
        async delete(workspaceId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/workspaces/${workspaceId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        };
    };

    /**
    * Method is using for updating workspace item in database.
    * @param workspaceId
    * @param data
    * @returns "OK" message or rises Error
    */
    async update(workspaceId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/workspaces/${workspaceId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
     * Method is using for getting all workspace items from database.
     * @returns workspace db items or rises Error
     */
    async getAll() {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/workspaces/all`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for getting default workspace data from database.
     * @returns default workspace data or rises Error
     */
        async getDefault() {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/workspaces/default`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };
};
