import ActionTypes from "../actions/ActionTypes";

const initialState = {
  activeTechnologyId: "",
  technologyTotalEstCost: 0,
};

export const technologyReducer = (
  state = initialState,
  action 
) => {
  switch (action.type) {
    case ActionTypes.ACTIVE_TECHNOLOGY_ID:
      return {
        ...state,
        activeTechnologyId: action.payload,
      };
    case ActionTypes.TECHNOLOGY_TOTAL_EST_COST:
      return {
        ...state,
        technologyTotalEstCost: action.payload,
      };
    case ActionTypes.RESET_TECHNOLOGIES:
      return {
        ...state,
        activeTechnologyId: "",
        technologyTotalEstCost: 0,
      };
    default:
      return state;
  }
};
