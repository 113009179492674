import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class BusinessUnitService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
    * Method is using for getting all business unit items from database by workspace id.
    * @param workspaceId
    * @returns list of business units or rises Error
    */
    async getAllByWorkspace(workspaceId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/business_units/all/filter_by?&workspace_id=${workspaceId}`,
                }
            );
            return data;
        } catch (e) {
            throw new Error(e.message);
        }
    }

    /**
     * Method is using for creating business unit item in database.
     * @param params
     * @returns business unit id or rises Error
     */
        async create(params) {
        try {
            const urlWithParams = '/business_units/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.business_unit_id;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for getting business unit item from database.
     * @param businessUnitId
     * @returns business unit db item or rises Error
     */
        async get(businessUnitId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/business_units/${businessUnitId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

    /**
     * Method is using for deleting business unit item from database.
     * @param businessUnitId
     * @returns "OK" message or rises Error
     */
        async delete(businessUnitId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/business_units/${businessUnitId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        }
    };

    /**
    * Method is using for updating business unit item in database.
    * @param businessUnitId
    * @param data
    * @returns "OK" message or rises Error
    */
        async update(businessUnitId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/business_units/${businessUnitId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };
};
