import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class CardService {
    BASE_SERVICE = BaseAPIService.getInstance();

    /**
     * Method is using for getting card item from database.
     * @param cardId
     * @returns card db item or rises Error
     */
    async get(cardId) {
        try {
            const data = await this.BASE_SERVICE.get(
                {
                    url: `/cards/${cardId}`,
                }
            );
            return data;
        } catch (e) {
            throw Error(e);
        }
    };

/**
 * Method is using for creating card item in database.
 * @param params
 * @returns card id or rises Error
 */
    async create(params) {
        try {
            const urlWithParams = '/cards/new?';
            const paramsService = new ParamsService(
                urlWithParams,
                params
            );
            const url = paramsService.urlWithParams;
            const data = await this.BASE_SERVICE.post(
                {
                    url: url,
                    body: {},
                }
            );
            return data.card_id;
        } catch (e) {
            throw new Error(e.message);
        }
    };

    /**
     * Method is using for deleting  card item from database.
     * @param cardId
     * @returns "OK" message or rises Error
     */
    async delete(cardId) {
        try {
            await this.BASE_SERVICE.delete(
                {
                    url: `/cards/${cardId}`,
                }
            );
            return "OK";
        } catch (e) {
            throw Error(e);
        }
    };

    /**
* Method is using for updating card item in database.
* @param cardId
* @param data
* @returns "OK" message or rises Error
*/
    async update(cardId, data) {
        try {
            await this.BASE_SERVICE.put(
                {
                    url: `/cards/${cardId}`,
                    body: data,
                }
            );
            return "OK";
        } catch (e) {
            throw new Error(e.message);
        }
    };
};
