import ActionTypes from "../actions/ActionTypes";

const initialState = {
  queueChanges: true,
};

export const queueReducer = (
  state = initialState,
  action 
) => {
  switch (action.type) {
    case ActionTypes.QUEUE_CHANGES:
      return {
        ...state,
        queueChanges: action.payload,
      };
    default:
      return state;
  }
};
