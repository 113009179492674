import moment from "moment-timezone";
import { BaseAPIService } from "./base";
import { ParamsService } from "./params";

export class CardStatusService {
  BASE_SERVICE = BaseAPIService.getInstance();

  /**
   * Method is using for getting the last created card status object by card id and workspace id.
   * @param cardId
   * @param workspaceId
   * @returns last created card status object or rises Error
   */
  async getLastCreatedItemByCardAndWorkspace(cardId, workspaceId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/card_status/all/filter_by?card_id=${cardId}&workspace_id=${workspaceId}`,
      });
      if (data.length > 0) {
        let lastCreatedItem;
        let lastTimeCreated = new Date(null);
        for (const item of data) {
          const timeCreated = moment(item.time_created).toDate();
          if (timeCreated.getTime() > lastTimeCreated.getTime()) {
            lastTimeCreated = timeCreated;
            lastCreatedItem = item;
          }
        }
        return lastCreatedItem;
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }
  /**
   * Method is using for creating card status item in database.
   * @param params
   * @returns card or rises Error
   */
  async create(params) {
    try {
      const urlWithParams = "/card_status/new?";
      const paramsService = new ParamsService(urlWithParams, params);
      const url = paramsService.urlWithParams;
      const data = await this.BASE_SERVICE.post({
        url: url,
        body: {},
      });
      return data;
    } catch (e) {
        throw new Error(e.message);
    }
  }

  /**
   * Method is using for updating all card status items in database.
   * @param cardStatusId
   * @param data
   * @returns "OK" message or rises Error
   */
  async update(cardStatusId, data) {
    try {
      await this.BASE_SERVICE.put({
        url: `/card_status/${cardStatusId}`,
        body: data,
      });
      return "OK";
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Method is using for deleting card status item from database.
   * @param cardStatusId
   * @returns "OK" message or rises Error
   */
  async delete(cardStatusId) {
    try {
      await this.BASE_SERVICE.delete({
        url: `/card_status/${cardStatusId}`,
      });
      return "OK";
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Method is using for getting all card status items from database by card id.
   * @param cardId
   * @returns list of card status items or rises Error
   */
  async getAllByCardId(cardId) {
    try {
      const data = await this.BASE_SERVICE.get({
        url: `/card_status/all/filter_by?&card_id=${cardId}`,
      });
      return data;
    } catch (e) {
      throw Error(e);
    }
  }
}
