import React from "react";
import * as XLSX from "xlsx";
import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

export const DonutChart = (props) => {
  const { wb, donutChartName, donutsFilterObject } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);

  var data = [
    {
      values: [],
      labels: [],
      marker: {
        colors: [
          'rgb( 0 89 255)',
          'rgb(230, 230, 230)',
          'rgb(160, 209, 255)',
          'rgb(140, 133, 123)',
          'rgb(82, 138, 191)',
          'rgb(215, 11, 11)',
          'rgb(11, 133, 215)',
          'rgb(0, 0, 0)',
          'rgb(0, 0, 0)',
          'rgb(0, 0, 0)'
        ],
				symbol: 'circle'
      },
      domain: { column: 0 },
      name: "",
      hoverinfo: "label+percent+name",
      hole: 0.6,
      type: "pie",
    },
  ];
  var layout = {
    // title: "Global Emissions 1990-2011",
    annotations: [
      {
        showarrow: false,
        text: "",
        x: 0.17,
        y: 0.5,
      },
    ],
    font: {
      size: 12,
      family: '"Inter", sans-serif',
    },
    showlegend: true,
    legend: {
      x: 1,
      y: 0.5,
      title: {
        text: "",
        font: {
          size: 12,
          family: '"Inter", sans-serif',
        }
      },
    },
    margin: { t: 25, b: 25, l: 30, r: 0 },
    grid: { rows: 0, columns: 0 },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    height: 190,
    // width: 285,
  };

  var config = {
    showLink: false,
    displayModeBar: false,
    responsive: true
  };
  if (donutChartName === "Solution Health Status") {
    layout.legend.title.text = "   <b>Health Order</b>";
  } else if (donutChartName === "Solutions by Stage of Development") {
    layout.legend.title.text = "   <b>Stage</b>";
  } else if (donutChartName === "Solutions by Technology") {
    layout.legend.title.text = "   <b>Technology</b>";
  }

  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    var donutData = generateDonutChartDataWithFilters(
      wb,
      donutChartName,
      donutsFilterObject.filterObject
    );
    data[0].values = donutData.values;
    data[0].labels = donutData.labels;
  }

  function generateDonutChartDataWithFilters(
    workbookData,
    chartName,
    filterObject
  ) {
    var data = {
      values: [],
      labels: [],
    };

    var labelSheet;
    var labelSheetData;
    var valueSheet;
    var valueSheetData;

    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey !== "" &&
            ele.BusinessUnitKey === filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey !== "" &&
            ele.StageKey === filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        });
      }
      if (
        filterObject.BusinessUnit === 0 &&
        filterObject.Portfolio === 0 &&
        filterObject.StageOfDevelopment === 0 &&
        filterObject.SolutionsName === ""
      ) {
        filteredSheetData = filteredSheetData;
      }

      if (chartName === "Solution Health Status") {
        labelSheet = workbookData.Sheets["DimHealth"];
        labelSheetData = XLSX.utils.sheet_to_json(labelSheet, {
          raw: true,
          defval: "",
          blankRows: false,
        });

        var filteredRecords = [];
        labelSheetData.forEach((lbl) => {
          filteredSheetData.forEach((value) => {
            if (value.HealthKey === lbl.HealthKey) {
              var obj = {
                HealthKey: lbl.HealthKey,
                "Health Order": lbl["Health Order"],
              };
              filteredRecords.push(obj);
            }
          });
        });
        var result = filteredRecords.reduce(
          (acc, o) => (
            (acc[o["Health Order"]] = (acc[o["Health Order"]] || 0) + 1), acc
          ),
          {}
        );
        data.labels = Object.keys(result);
        data.values = Object.values(result);
      } else if (chartName === "Solutions by Stage of Development") {
        labelSheet = workbookData.Sheets["DimStage"];
        labelSheetData = XLSX.utils.sheet_to_json(labelSheet, {
          raw: true,
          defval: "",
          blankRows: false,
        });

        var filteredRecords = [];
        labelSheetData.forEach((lbl) => {
          filteredSheetData.forEach((value) => {
            if (value.StageKey === lbl.StageKey) {
              var obj = {
                StageKey: lbl.StageKey,
                "Stage of Development": lbl["Stage of Development"],
              };
              filteredRecords.push(obj);
            }
          });
        });

        var result = filteredRecords.reduce(
          (acc, o) => (
            (acc[o["Stage of Development"]] =
              (acc[o["Stage of Development"]] || 0) + 1),
            acc
          ),
          {}
        );
        data.labels = Object.keys(result);
        data.values = Object.values(result);
      } else if (chartName === "Solutions by Technology") {
        valueSheet = workbookData.Sheets["FactQueue"];
        valueSheetData = XLSX.utils.sheet_to_json(valueSheet, {
          raw: true,
          defval: "",
          blankRows: false,
        });

        var uniqueIdentifiers = filteredSheetData.map((ele) => {
          return ele.UniqueIdentifier;
        });

        var unique_UniqueIdentifiers = uniqueIdentifiers.filter(
          (value, index, self) => {
            return self.indexOf(value) === index;
          }
        );

        var filterFactQueue = [];
        unique_UniqueIdentifiers.forEach((el) => {
          valueSheetData.forEach((element) => {
            if (element["Unique Identifier"] === el) {
              filterFactQueue.push(element);
            }
          });
        });

        labelSheet = workbookData.Sheets["DimTechnology"];
        labelSheetData = XLSX.utils.sheet_to_json(labelSheet, {
          raw: true,
          defval: "",
          blankRows: false,
        });

        var filteredRecords = [];
        labelSheetData.forEach((lbl) => {
          filterFactQueue.forEach((value) => {
            if (value.TechnologyKey === lbl.TechnologyKey) {
              var obj = {
                TechnologyKey: lbl.TechnologyKey,
                Technology: lbl["Technology"],
              };
              filteredRecords.push(obj);
            }
          });
        });

        var result = filteredRecords.reduce(
          (acc, o) => (
            (acc[o["Technology"]] = (acc[o["Technology"]] || 0) + 1), acc
          ),
          {}
        );
        data.labels = Object.keys(result);
        data.values = Object.values(result);
      }
    }
    return data;
  }

  return (
    <>
      <div
        className="rounded my-1 py-1"
        style={{ background: "#e1e1e1", textAlign: "center" }}
      >
        <h6 style={{ marginBottom: "0" }}> {donutChartName}<a className="info" style={{
                  border: '1px solid #000',
                  borderRadius: '50px',
                  fontSize: '9px',
                  margin: 0,
                  fontWeight: 'bold',
                  padding: '0px 4px',
                  color: 'black',
                  verticalAlign: 'middle',
                  marginLeft: '5px'
                }}>i</a></h6>
      </div>
      <div
        className="h-30 rounded"
      >
        <PlotlyComponent
          style={{ width: "100%" }}
          data={data}
          layout={layout}
          config={config}
        />
      </div>
    </>
  );
};
