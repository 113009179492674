import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useAction, useTypedSelector } from "hooks";
import {
  AppRoleService,
  BusinessUnitService,
  RoleAssignmentService,
  UserService,
  WorkspaceAssignmentService,
  WorkspaceService,
} from "services";
import { handleError, removeGapAndConvertToLowerCase } from "utils";

const appRoleService = new AppRoleService();
const businessUnitService = new BusinessUnitService();
const roleAssignmentService = new RoleAssignmentService();
const userService = new UserService();
const workspaceAssignmentService = new WorkspaceAssignmentService();
const workspaceService = new WorkspaceService();

const roles = [
  "",
  "Contractor",
  "Staff",
  "Manager",
  "Director",
  "CXO",
  "Business Owner",
];

const fetchBusinessUnits = async ({ queryKey }) => {
  const [_, activeWorkspaceId] = queryKey;
  if (activeWorkspaceId) {
    return businessUnitService.getAllByWorkspace(activeWorkspaceId);
  }
};

export const ProfileTab = (props) => {
  const { userId } = props;
  const history = useHistory();
  const { updateDefaultWorkspaceIdAction, updateDefaultWorkspaceNameAction } =
    useAction();
  // notifiers
  const [error, setError] = useState("");
  const [saveStatus, setStatus] = useState("");
  // fields to edit
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [organizationRole, setOrganizationRole] = useState("");
  const [workspaceRoles, setWorkspaceRoles] = useState("");
  const [businessUnit, setBusinessUnit] = useState("");
  const [workspaceAdmin, setWorkspaceAdmin] = useState("");
  const [defaultWorkspaceId, setDefaultWorkspaceId] = useState("");
  // lists
  const [workspaces, setWorkspaces] = useState([]);
  // booleans
  const [isSaveTypeSuccessful, setIsStatusTypeSuccessful] = useState(false);
  // ids
  const activeWorkspaceId = useTypedSelector(
    (state) => state.workspace.activeWorkspaceId
  );
  const [currentWorkspaceAssignmentId, setCurrentWorkspaceAssignmentId] =
    useState("");
  const statusColor = isSaveTypeSuccessful ? "green" : "red";

  const isSavedSuccessfully = () => {
    setStatus("Changes saved successfully.");
    setIsStatusTypeSuccessful(true);
  };

  const failedDuringSaving = (error) => {
    if (error) setError(error);
    setStatus("Failure occurred when saving.");
    setIsStatusTypeSuccessful(false);
  };

  const businessUnitsQuery = useQuery(
    ["profileBusinessUnits", activeWorkspaceId],
    fetchBusinessUnits,
    {
      onError: (e) => handleError(e, history),
    }
  );

  const businessUnits = businessUnitsQuery.data ? businessUnitsQuery.data : [];

  const updateUser = async () => {
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        profile_photo_url: photoUrl,
        phone_number: phone,
        email_address: email,
        workspace_id: defaultWorkspaceId,
      };
      return await userService.update(userId, data);
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const updateWorkspaceAssignment = async () => {
    try {
      const data = {
        org_role: organizationRole,
        business_unit_id: businessUnit,
      };
      return await workspaceAssignmentService.update(
        currentWorkspaceAssignmentId,
        data
      );
    } catch (e) {
      failedDuringSaving(e.message);
      handleError(e, history);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setStatus("");
    const userResponse = await updateUser();
    const workspaceAssignmentResponse = await updateWorkspaceAssignment();
    if (userResponse && workspaceAssignmentResponse) {
      let defaultWorkspaceName = "";
      workspaces.filter((obj) => {
        if (obj.workspace_id === Number(defaultWorkspaceId)) {
          defaultWorkspaceName = obj.workspace_name;
        }
      });
      updateDefaultWorkspaceIdAction(defaultWorkspaceId);
      updateDefaultWorkspaceNameAction(
        removeGapAndConvertToLowerCase(defaultWorkspaceName)
      );
      isSavedSuccessfully();
    }
  };

  const fetchUser = async () => {
    try {
      const response = await userService.get(userId);
      if (response) {
        setFirstName(response.first_name);
        setLastName(response.last_name);
        setEmail(response.email_address);
        setPhone(response.phone_number);
        setPhotoUrl(response.profile_photo_url);
        setDefaultWorkspaceId(response.workspace_id);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchWorkspaceAssignmentsData = async () => {
    try {
      const response = await workspaceAssignmentService.getAllByUser(userId);
      if (response) {
        let userWorkspaces = [];
        for (const item of response) {
          const workspace = await fetchWorkspace(item.workspace_id);
          userWorkspaces.push(workspace);
        }
        setWorkspaces(userWorkspaces);
        response.filter((obj) => {
          if (obj.workspace_id === Number(activeWorkspaceId)) {
            setWorkspaceAdmin(obj.is_admin);
            setCurrentWorkspaceAssignmentId(obj.workspace_assignment_id);
            setOrganizationRole(obj.org_role);
            setBusinessUnit(obj.business_unit_id);
          }
        });
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchWorkspace = async (id) => {
    try {
      return await workspaceService.get(id);
    } catch (e) {
      handleError(e, history);
    }
  };

  const fetchWorkspaceRoles = async () => {
    try {
      const response =
        await roleAssignmentService.getAllByUserAndActiveWorkspaceId(
          userId,
          activeWorkspaceId
        );
      if (response.length > 0) {
        let rolesNames = [];
        for (const item of response) {
          const appRoleItem = await appRoleService.get(item.app_role_id);
          rolesNames.push(appRoleItem.role_name);
        }
        if (rolesNames.length > 1) {
          setWorkspaceRoles(rolesNames.join(";"));
          return;
        }
        setWorkspaceRoles(rolesNames[0]);
      }
    } catch (e) {
      handleError(e, history);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchWorkspaceAssignmentsData();
    fetchWorkspaceRoles();
  }, [error]);

  return (
    <>
      {error ? (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      ) : null}
      <Card border="0" className="shadow mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={3} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    value={firstName || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    value={lastName || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="organizationRole">
                  <Form.Label>Organization Role</Form.Label>
                  <Form.Control
                    as="select"
                    value={organizationRole || ""}
                    className="mb-0"
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setOrganizationRole(e.target.value);
                    }}>
                    {roles.map((role, i) => {
                      return (
                        <option key={`organizationRole-${i}`} value={role}>
                          {role}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="workspaceRoles">
                  <Form.Label>Workspace Role(s)</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    maxLength={50}
                    value={workspaceRoles || ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    readOnly
                    type="email"
                    value={email || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="businessUnit">
                  <Form.Label>Business Unit</Form.Label>
                  <Form.Control
                    as="select"
                    value={businessUnit || ""}
                    className="mb-0"
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setBusinessUnit(e.target.value);
                    }}>
                    <option value=" "> </option>
                    {businessUnits.map((businessUnitItem, i) => {
                      return (
                        <option
                          key={`businessUnit-${i}`}
                          value={businessUnitItem.business_unit_id}>
                          {businessUnitItem.business_unit_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="workspaceAdmin">
                  <Form.Label>Workspace Admin</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    value={workspaceAdmin || ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="phone"
                    value={phone || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="defaultWorkspaceId">
                  <Form.Label>Default Workspace</Form.Label>
                  <Form.Control
                    as="select"
                    value={defaultWorkspaceId || ""}
                    className="mb-0"
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setDefaultWorkspaceId(e.target.value);
                    }}>
                    {workspaces.map((workspace, i) => {
                      return (
                        <option key={`workspace-${i}`} value={workspace.workspace_id}>
                          {workspace.workspace_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="profilePhotoUrl">
                  <Form.Label>Profile Photo Url</Form.Label>
                  <Form.Control
                    type="text"
                    value={photoUrl || ""}
                    onChange={(e) => {
                      setError("");
                      setStatus("");
                      setPhotoUrl(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button variant="primary" type="submit" onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col md={3}>
                <h6 style={{ color: statusColor }}>{saveStatus}</h6>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
