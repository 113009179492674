export const Routes = {
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Billing: { path: "/settings/billing" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  BusinessUnits: { path: "/tables/business-units" },
  Buttons: { path: "/components/buttons" },
  DashboardBacklog: { path: "/backlog" },
  DashboardDetailed: { path: "/detailed" },
  DashboardExecutive: { path: "/" },
  DashboardSolutions: { path: "/tables/solutions" },
  DashboardUtilization: { path: "/dashboard/utilization" },
  DataTables: { path: "/tables/datatables" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },
  DocsDownload: { path: "/documentation/download" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsLicense: { path: "/documentation/license" },
  DocsOverview: { path: "/documentation/overview" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  Calendar: { path: "/calendar" },
  CreateDashboardSolution: { path: "/solutions/new" },
  CreateWorkspace: { path: "/settings/workspace/new" },
  ComingSoon: { path: "/examples/coming-soon" },
  Components: { path: "/tables/components" },
  ConfigDashboardSolution: { path: "/solutions/config/:id" },
  ConfigWorkspace: { path: "/settings/workspace/config/:id" },
  ForgotPassword: { path: "/forgot-password" },
  Forms: { path: "/components/forms" },
  Ideas: { path: "/tables/ideas" },
  Ideate: { path: "/ideate" },
  Invoice: { path: "/invoice" },
  Kanban: { path: "/kanban" },
  Lock: { path: "/lock" },
  Map: { path: "/map" },
  Messages: { path: "/messages" },
  Modals: { path: "/components/modals" },
  Navbars: { path: "/components/navbars" },
  Navs: { path: "/components/navs" },
  NotFound: { path: "/404" },
  PaginationComponent: { path: "/components/pagination" },
  PluginCalendar: { path: "/plugins/calendar" },
  PluginCharts: { path: "/plugins/charts" },
  PluginDatatable: { path: "/plugins/datatable" },
  PluginDropzone: { path: "/plugins/dropzone" },
  PluginMap: { path: "/plugins/map" },
  PluginSweetAlert: { path: "/plugins/sweet-alert" },
  Popovers: { path: "/components/popovers" },
  Pricing: { path: "/settings/pricing" },
  Presentation: { path: "/home" },
  Progress: { path: "/components/progress" },
  Queues: { path: "/tables/queues" },
  ResetPassword: { path: "/reset-password/:userId" },
  Sessions: { path: "/tables/sessions" },
  ServerError: { path: "/500" },
  SettingsPage: { path: "/old-settings-page" },
  SettingsConnectors: { path: "/settings/connectors" },
  SettingsPlatforms: { path: "/settings/platforms" },
  SettingsProfile: { path: "/settings/profile" },
  SettingsQuotes: { path: "/settings/quotes" },
  SignIn: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  SingleMessage: { path: "/message" },
  Solutions: { path: "/solutions" },
  Tables: { path: "/components/tables" },
  TablesTransactions: { path: "/tables/transactions" },
  Tabs: { path: "/components/tabs" },
  Tasks: { path: "/tasks" },
  Toasts: { path: "/components/toasts" },
  Tooltips: { path: "/components/tooltips" },
  Transactions: { path: "/transactions" },
  Users: { path: "/settings/users" },
  VerifyEmail: { path: "/verify/:userId" },
  Widgets: { path: "/widgets" },
  WidgetsComponent: { path: "/components/widgets" },
};
